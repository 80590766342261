import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import waukagen1 from "./Beds and Wardrobes_2.jpg";
import waukagen2 from "./Beds and Wardrobes.jpg";
import waukagen3 from "./Lobby.jpg";
import waukagen4 from "./Waiting Room_2.jpg";
import waukagen5 from "./Waiting Room.jpg";

const imgs = [waukagen1, waukagen2, waukagen3, waukagen4, waukagen5];

const Container = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 4rem 0;
  h2 {
    margin-bottom: 4rem;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
`;

const FolioImg = styled.img`
  margin: 1rem 0;
  width: 20rem;
  height: auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 1rem 1rem;
    width: 25rem;
  }
`;

export default function WaukagenIL() {
  let markup = imgs.map((img) => <FolioImg src={img} alt="test" />);
  return (
    <Container animateIn="fadeIn" animateOnce="true">
      <h2>Waukagen, IL</h2>
      <Content>{markup}</Content>
    </Container>
  );
}
