import React from "react";
import styled from "styled-components";
//eslint-disable-next-line
// import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

// import Button from "../../components/Button/Button";

import accessory from "../../assets/img/pages/home/productCategories/accessories.jpg";
import cube from "../../assets/img/pages/home/productCategories/cube.jpg"; // ;
import customMillwork from "../../assets/img/pages/home/productCategories/custom-millwork.jpg";
// import benching from "../../assets/img/pages/home/productCategories/bench.jpg";
import seating from "../../assets/img/pages/home/productCategories/seating.jpg";
import openConcept from "../../assets/img/pages/home/productCategories/open-concept.jpg";
import privateOffice from "../../assets/img/pages/home/productCategories/private-office.jpg";
import table from "../../assets/img/pages/home/productCategories/table.jpg";
import lockerStorage from "../../assets/img/pages/home/productCategories/storage.jpg";
import outdoor from "../../assets/img/pages/home/productCategories/outdoor-seating.jpg";
import wallFlooring from "../../assets/img/pages/home/productCategories/walls-flooring.jpg";
import ContactUsHome from "../../components/ContactUsHome/ContactUsHome";

import FSPlus from "../../assets/img/pages/home/FSPLUS-LOGO.jpg";

const ProductsContainer = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 10rem;
  padding-top: 15rem;
  h2 {
    margin-bottom: 4rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-flow: column;
  padding-bottom: 10rem;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium});
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
  }
`;

const HoverContent = styled.div`
  width: 23rem;
  height: 23rem;
  background-color: #000;
  transition: 0.5s ease;
  opacity: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    opacity: 0;
    width: 20rem;
    height: 20rem;
  }
`;

const Desc = styled.p`
  position: absolute;
  z-index: 10;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
`;

const ProdImg = styled(ScrollAnimation)`
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    opacity: 0.4;
    display: block;
    transition: 0.5s ease;
    backface-visibility: hidden;
    width: 23rem;
    height: auto;
    margin: 0.5rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    img {
      width: 20rem;
      opacity: 1;
    }
    p {
      font-size: 2rem;
    }

    &:hover img {
      opacity: 0.2;
    }

    &: hover ${HoverContent} {
      opacity: 1;
    }
  }
`;

const ProductsBlurb = styled.div`
  width: 80%;
  height: auto;
  padding-bottom: 10rem;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 60%;
  }
`;

export default function products() {
  return (
    <ProductsContainer animateIn="fadeIn" animateOnce="true">
      <h2>Products</h2>
      <ProductsBlurb>
        Studies have shown that the proper office design and products enhance
        work productivity. At Facility Solutions Plus, we work with leading edge
        manufacturers that offer extensive and unique lines of quality office
        furniture, cubicle, and flooring products so we can work with you to
        provide you the best in office furniture/ flooring solutions. Our goal
        is to create an office environment where the furniture, flooring,
        finishes, wall design, lighting, signage – flow elequently, each area
        fulfills its purpose and each individual is able to be productive and
        fully enjoy their office space.
      </ProductsBlurb>
      <Content>
        <Row>
          <ProdImg animateIn="fadeIn" animateOnce="true">
            <HoverContent>
              <Desc>Seating</Desc>
            </HoverContent>
            <img src={seating} alt="seating" />
          </ProdImg>

          <ProdImg animateIn="fadeIn" animateOnce="true">
            <HoverContent>
              <Desc>Tables</Desc>
            </HoverContent>
            <img src={table} alt="tables" />
          </ProdImg>

          <ProdImg animateIn="fadeIn" animateOnce="true">
            <HoverContent>
              <Desc>Private Office</Desc>
            </HoverContent>
            <img src={privateOffice} alt="private office" />
          </ProdImg>

          <ProdImg animateIn="fadeIn" animateOnce="true">
            <HoverContent>
              <Desc>Cubicles</Desc>
            </HoverContent>
            <img src={cube} alt="cubicle" />
          </ProdImg>
        </Row>
        <Row>
          <ProdImg animateIn="fadeIn" animateOnce="true">
            <HoverContent>
              <Desc>Open Concept</Desc>
            </HoverContent>
            <img src={openConcept} alt="open concept" />
          </ProdImg>

          <ProdImg animateIn="fadeIn" animateOnce="true">
            <HoverContent>
              <Desc>Outdoor seating</Desc>
            </HoverContent>
            <img src={outdoor} alt="outdoor" />
          </ProdImg>

          <ProdImg animateIn="fadeIn" animateOnce="true">
            <HoverContent>
              <Desc>Lockers & Storage</Desc>
            </HoverContent>
            <img src={lockerStorage} alt="locker storage" />
          </ProdImg>

          <ProdImg animateIn="fadeIn" animateOnce="true">
            <HoverContent>
              <Desc>Accessories</Desc>
            </HoverContent>
            <img src={accessory} alt="accessories" />
          </ProdImg>
        </Row>
        <Row>
          <ProdImg animateIn="fadeIn" animateOnce="true">
            <HoverContent>
              <Desc>Walls & Flooring</Desc>
            </HoverContent>
            <img src={wallFlooring} alt="walls and flooring" />
          </ProdImg>

          <ProdImg animateIn="fadeIn" animateOnce="true">
            <HoverContent>
              <Desc>Signs & Wayfinding</Desc>
            </HoverContent>
            <img src={FSPlus} alt="signs and wayfinding" />
          </ProdImg>

          <ProdImg animateIn="fadeIn" animateOnce="true">
            <HoverContent>
              <Desc>Custom Millwork</Desc>
            </HoverContent>
            <img src={customMillwork} alt="custom millwork" />
          </ProdImg>
        </Row>
      </Content>

      <ContactUsHome />
    </ProductsContainer>
  );
}
