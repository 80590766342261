import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Header from "../../../assets/img/pages/resources/blogs/blog01212020/header.png";
import Fengshui from "../../../assets/img/pages/resources/blogs/blog01212020/fengshui.png";
import FengshuiDirections from "../../../assets/img/pages/resources/blogs/blog01212020/fengshuidirections.png";
import Peaceful from "../../../assets/img/pages/resources/blogs/blog01212020/peaceful.png";

import ContactUsHome from "../../../components/ContactUsHome/ContactUsHome";

const MainContainer = styled.div`
    width: 100%;
    padding: 0 30%;
    margin-top: 8rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-top: 8rem;
        padding: 0 2rem;
    }

    a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
    }

    p {
        margin-bottom: 1.5rem;
    }

    h2 {
        font-size: 1.5rem;
        margin-top: 6rem;
        text-transform: uppercase;
    }
`;

const BlogImg = styled.img`
    width: 100%;
    height: auto;
    margin: 4rem 0;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin: 2rem 0;
    }
`;

const Date = styled.p`
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.btnDisabled};
`;

//eslint-disable-next-line
const Bolded = styled.span`
    font-weight: 800;
`;

const Italics = styled.span`
    font-style: italic;
`;

const PostsNavigator = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10rem;
`;

const NewPost = styled.div`
    width: 50%;
    display: flex;
    flex-flow: column;
    padding: 0.5rem;
`;
//eslint-disable-next-line
const OldPost = styled(NewPost)`
    align-items: end;
    text-align: right;
`;

export default function Blog01212020() {
    return (
        <>
            <MainContainer>
                <Date>January 21st, 2020</Date>
                <h1>Feng Shui in the Office</h1>
                <BlogImg src={Header} alt='header' />
                <p>
                    Have you recently found yoruself to be entirely
                    unproductive, unfulfilled, and unhappy whenever you sit down
                    at your office desk? And no matter what you try - be it a
                    new planner or time management technique - you can't seem to
                    shake those negative feelings? Feeling this way can severely
                    hamper your ability to excel in the office space; but not to
                    worry - the ancient art of feng shui may have the solutions
                    that will put you back on top of your game in the office!
                </p>
                <h2>What is feng shui?</h2>
                <BlogImg src={Fengshui} alt='art of feng shui' />
                <p>
                    Feng shui - menaing wind (feng) and water (shui) - is an
                    ancient art and science that seeks to bring about harmony
                    and balance within any environment by carefully considering
                    the placement of objects within space. The practice spand
                    thousands of years, with{" "}
                    <a
                        href='http://fengshuistyle.us/what-is-feng-shui/history/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        some histories
                    </a>{" "}
                    claiming that feng shui began in China almost 6,000 years
                    ago. Its origins root themselves within the philosophical
                    tradition of Taoism, which has given birth to other anicent
                    practices, like Tai Chi and acupuncture. In fact, feng shui
                    expert{" "}
                    <a
                        href='https://www.knowfengshui.com/what-is-feng-shui/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Rodika Tchi
                    </a>{" "}
                    describes it as "acupuncture of the space." This is because
                    a primary goal of feng shui is to open up the "powerful
                    energy channels" of any space in order to "help it get
                    stronger, more harmonious and powerful."
                </p>
                <p>
                    To understand this goal, here's a quote from Tchi diving
                    deeper into the relationship between feng shui and energy:
                    <ul>
                        <li>
                            "Ancient feng shui knew what quantum physics is
                            telling us today - that everything around is us
                            composed of endless energy fields connecting
                            everything you see, feel and touch (as well as
                            millions of things we do not see with our physical
                            eyes). There is really no separation between you and
                            everything that surrounds you, and this, of course,
                            includes your home."
                        </li>
                    </ul>
                </p>
                <p>
                    Feng shui acknowledges that we are intimately connected with
                    our natural and built environments, a relationship that is
                    made up of{" "}
                    <a
                        href='http://fengshuistyle.us/what-is-feng-shui/history/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Qi
                    </a>
                    , or the term feng shui masters use to describe energy. Feng
                    shui seeks to stimulate optimal flow of Qi through a
                    combination of architecture, interior design, garden design,
                    and urban planning. Specifically, it emphasizes that balance
                    and harmony can be brought into space - and your life - by
                    putting intention and focus on the layout of a given space,
                    as well as the materials and colors utilized in a space.
                </p>
                <h2>the basics of feng shui</h2>
                <BlogImg src={FengshuiDirections} alt='graph of feng shui' />
                <p>
                    With that brief introduction to feng shui out of the way,
                    how does it actually work? Answering that question gets
                    complicated, given the extensive history of feng shui. As a
                    matter of fact, there are a multitude of schools within feng
                    shui that advocate for different ways of approaching the
                    ancient science. Regardless of school, here are some of the
                    foundational theories behind feng shui:
                </p>
                <p>
                    <Italics>Elements</Italics>
                </p>
                <p>
                    Within the feng shui tradition, there are{" "}
                    <a
                        href='https://www.feng-shui-and-beyond.com/feng-shui-elements/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        five elements
                    </a>{" "}
                    that serve as archetypes for all of the energy in the world:
                    water, wood, fire, earth, and metal.
                </p>
                <p>
                    <ul>
                        <li>
                            <Bolded>Water:</Bolded> Water represents renewal and
                            replenishment, and is crucial to initiating all
                            life.
                        </li>
                        <li>
                            <Bolded>Wood:</Bolded> Wood is the element of
                            expansiveness, and is related to feelings of growth
                            and creativity.
                        </li>
                        <li>
                            <Bolded>Fire:</Bolded> The most volatile of the
                            elements, fire represents transformation and
                            passion.
                        </li>
                        <li>
                            <Bolded>Earth:</Bolded> The earth element has a
                            grounding, stabilizing effect, and helps bring about
                            feelings of peace.
                        </li>
                        <li>
                            <Bolded>Metal:</Bolded> Metal is related to mental
                            sharpness and clarity; it is also related to
                            intelligence.
                        </li>
                    </ul>
                </p>
                <p>
                    <Italics>Yin and Yang</Italics>
                </p>
                <p>
                    <a
                        href='https://www.feng-shui-institute.org/Feng_Shui/yinyang.html'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Yin and yang
                    </a>{" "}
                    are two forces central to feng shui. According to feng shui
                    masters, yin and yang are the very "essence of nature, where
                    everything is in a perpetual state of change, moving from
                    one extreme to the other to create equilibrium or universal
                    balance."
                </p>
                <p>
                    <ul>
                        <li>
                            <Bolded>Yin:</Bolded> Yin is characterized by
                            feminine energy, and contains the elements of earth
                            and metal. Yin energy is contracting, receptive, and
                            inward. And in the traditional yin and yang symbol,
                            it is the black half.
                        </li>
                        <li>
                            <Bolded>Yang:</Bolded> Yang is characterized by male
                            energy, and holds the elements of wood and fire.
                            Yang energy is expansive, energetic, and outward; it
                            is the white half of the traditional symbol.
                        </li>
                    </ul>
                </p>
                <p>
                    These different types of energies lay at the core of feng
                    shui teachings, and are key to understanding how to
                    introduce concepts of feng shui into the office space.
                </p>
                <h2>Feng shui practices for the office</h2>
                <BlogImg src={Peaceful} alt='peaceful atmosphere' />
                <p>
                    Now that we've outlined some of the basic ideas held within
                    feng shui, we can start exploring how to apply these
                    principles into the office space! To reiterate, feng shui is
                    all about creating an optimal flow of Qi, or energy, between
                    yourself and the environment around you. Here are{" "}
                    <a
                        href='https://www.feng-shui-and-beyond.com/feng-shui-office/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        some ways
                    </a>{" "}
                    to create a balanced flow of energy into your office space:
                </p>
                <p>
                    <ul>
                        <li>
                            <Bolded>Clear office clutter:</Bolded> An organized
                            office that is free of clutter allows energy to move
                            freely throughout the office, promoting mental
                            clarity and productivity, and even reducing stress.
                            After all, the sight of a desk stacked with papers,
                            binders, and folders is sure to demotivate someone
                            from tackling the tasks ahead of them; but a tidy
                            and organized desk quite literally frees up the
                            space for a worker to be focused and productive.
                        </li>
                        <li>
                            <Bolded>
                                Place your desk in an optimal position:
                            </Bolded>{" "}
                            Feng shui teaching describes that there is an
                            optimal place for a desk to be placed in a private
                            office. Called the power position, this is when the
                            desk is placed so that you can see the door when
                            seated. Ideally, you can also see as much of the
                            room as possible.
                        </li>
                        <li>
                            <Bolded>Balance the elements:</Bolded> Maintaining a
                            balance between all of the elemental energies is
                            vital to promoting good feng shui. For example, if
                            your office has a lot of cold, metallic surfaces,
                            then there will be an overabundance of metal energy
                            present.
                        </li>
                        <li>
                            <Bolded>Balance the yin and yang energy:</Bolded>{" "}
                            Similarly, it is important to balance the yin and
                            yang energy of any space. Thinking about angles and
                            textures are a good way to balance yin and yang. For
                            example, an office with plenty of sharp edges and
                            hard surfaces will be full of yang energy;
                            introducing furniture made of softer material with
                            curves would balance this space with yin energy.
                        </li>
                    </ul>
                </p>
                <p>
                    In the end, there is no specific way to cultivate feng shui
                    in your office space. Because the ultimate goal of feng shui
                    is to optimize your relationship with the environment around
                    you, the specific steps will be dependent on your office,
                    the way its organized, and, of course, you! And here at
                    Facility Solutions Plus, we have both the expertise and the
                    range of products to help you make the most out of your
                    office space, and ensure that you feel motivated,
                    productive, and happy every time you step in to begin your
                    work day.
                </p>
                <p>
                    <Italics>
                        Facility Solutions Plus is a client-centered,
                        full-service commercial office furniture dealership and
                        service provider that specializes in meeting needs,
                        solving problems and enhancing the total office
                        environment through customized, cost-effective workspace
                        solutions...and more.
                    </Italics>
                </p>
                <PostsNavigator>
                    <NewPost>
                        <h4>Newer Post</h4>
                        <Link to='/resources/01252020'>
                            Why You Should Take Breaks in the Office
                        </Link>
                    </NewPost>
                    {/* <OldPost>
                        <h4>Older Post</h4>
                        <Link to='/resources/02242020'>
                            Buyer's Guide: Reception Desk
                        </Link>
                    </OldPost> */}
                </PostsNavigator>
            </MainContainer>
            <ContactUsHome />
        </>
    );
}
