import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

import styled from "styled-components";
import whtLogo from "../../../assets/img/logo/logo-wht-orng.png";
import burger from "../../../assets/img/icons/bars.svg";
import close from "../../../assets/img/icons/close.svg";

const MobileNavBarContainer = styled.div`
    width: 100%;
    background-color: #061c38;
    height: auto;
    top: 0;
    position: fixed;
    z-index: 1000;
    a {
        text-decoration: none;
        color: white;
        font-family: "Montserrat", sans-serif;
        text-transform: uppercase;
        margin: 1rem 0;
    }
`;

const MobileNavBarContent = styled.div`
    height: 7rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
`;

const LogoContainer = styled.div`
    width: 30%;
    display: flex;
    align-items: center;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        width: 50%;
    }
`;

const Logo = styled.img`
    width: 100%;
    height: auto;
`;

const BurgerIcon = styled.img`
    width: 2rem;
`;

const LinkContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100vh;
`;

export default function NavBar() {
    const [active, setActive] = useState(false);

    const burgerMenu = active ? (
        <BurgerIcon
            src={close}
            onClick={() => setActive(!active)}
            alt='Burger Menu icon'
        />
    ) : (
        <BurgerIcon
            src={burger}
            onClick={() => setActive(!active)}
            alt='Burger Menu icon'
        />
    );

    const linkDisplay = active ? (
        <LinkContainer>
            <NavLink
                activeStyle={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textUnderlineOffset: "10px",
                }}
                exact
                to='/'
                onClick={() => setActive(!active)}
            >
                Home
            </NavLink>
            <NavLink
                activeStyle={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textUnderlineOffset: "10px",
                }}
                to='/about'
                onClick={() => setActive(!active)}
            >
                About Us
            </NavLink>
            <NavLink
                activeStyle={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textUnderlineOffset: "10px",
                }}
                to='/markets'
                onClick={() => setActive(!active)}
            >
                Markets
            </NavLink>
            <NavLink
                activeStyle={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textUnderlineOffset: "10px",
                }}
                to='/services'
                onClick={() => setActive(!active)}
            >
                Services
            </NavLink>
            <NavLink
                activeStyle={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textUnderlineOffset: "10px",
                }}
                to='/products'
                onClick={() => setActive(!active)}
            >
                Products
            </NavLink>
            <NavLink
                activeStyle={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textUnderlineOffset: "5px",
                }}
                to='/portfolio'
                onClick={() => setActive(!active)}
            >
                Portfolio
            </NavLink>
            <NavLink
                activeStyle={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textUnderlineOffset: "5px",
                }}
                to='/resources'
                onClick={() => setActive(!active)}
            >
                Resources
            </NavLink>
            <NavLink
                activeStyle={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textUnderlineOffset: "10px",
                }}
                to='/contact'
                onClick={() => setActive(!active)}
            >
                Contact Us
            </NavLink>
        </LinkContainer>
    ) : null;

    return (
        <MobileNavBarContainer>
            <MobileNavBarContent>
                <Link exact to='/'>
                    <LogoContainer>
                        <Logo src={whtLogo} alt='Logo' />
                    </LogoContainer>
                </Link>
                {burgerMenu}
            </MobileNavBarContent>
            {linkDisplay}
        </MobileNavBarContainer>
    );
}
