import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import hero from "../../assets/img/pages/markets/hero.jpg";
import corporate from "../../assets/img/pages/markets/corporate.jpeg";
import education from "../../assets/img/pages/markets/education.jpeg";
import government from "../../assets/img/pages/markets/government.jpeg";
import healthcare from "../../assets/img/pages/markets/healthcare.jpeg";
import industrial from "../../assets/img/pages/markets/industrial.jpeg";
import science from "../../assets/img/pages/markets/science.jpeg";
import SimpleContactForm from "../../components/SimpleContactForm/SimpleContactForm";

const MarketContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.tertiary};
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        justify-content: center;
    }
`;

const MarketContent = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    margin-bottom: 8rem;
    align-items: center;
    justify-content: center;
    h2 {
        margin-bottom: 4rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 60%;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        width: 60%;
    }
`;

const HeroImg = styled.div`
    background: url(${hero});
    background-position: 0 40%;
    background-size: cover;
    width: 100%;
    height: 50vh;
    margin-bottom: 8rem;
`;

const WhoWeServe = styled(ScrollAnimation)`
    width: 100%;
    margin-bottom: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 60%;
    }
`;

const Markets = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
`;

const ImgRightContainer = styled(ScrollAnimation)`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    h3 {
        margin-bottom: 1rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 80%;
        flex-flow: row-reverse;
        margin-bottom: 5rem;
    }
`;

const ImgRightContent = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    p {
        margin-bottom: 2rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 0 4rem;
        width: 100%;
    }
`;
const ImgLeftContainer = styled(ScrollAnimation)`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    h3 {
        margin-bottom: 1rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 80%;
        flex-flow: row;
        margin-bottom: 5rem;
    }
`;

const ImgLeftContent = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    p {
        margin-bottom: 2rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 0 6rem;
        width: 100%;
    }
`;

const MarketImg = styled.img`
    width: 100%;
    margin-bottom: 2rem;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 40%;
    }
`;

export default function markets() {
    return (
        <MarketContainer>
            <HeroImg></HeroImg>
            <MarketContent>
                <h2>Markets</h2>
                <WhoWeServe animateIn='fadeIn' animateOnce='true'>
                    <p>
                        From healthcare to education, corporate to industrial,
                        our team can help you get started on your next office
                        furniture project no matter the industry or market.
                    </p>
                </WhoWeServe>
                <Markets>
                    <ImgRightContainer animateIn='fadeIn' animateOnce='true'>
                        <MarketImg src={corporate} alt='corporate' />
                        <ImgRightContent>
                            <h3>Corporate</h3>
                            <p>
                                Whether you’re a Fortune 500 company with
                                several floors to fill or a small start-up
                                company that only needs a few desks,
                                <b>Facility Solutions Plus</b> is here to help
                                you through the office furniture purchasing
                                process and hopefully help you save a little
                                money along the way.
                            </p>
                        </ImgRightContent>
                    </ImgRightContainer>
                    <ImgLeftContainer animateIn='fadeIn' animateOnce='true'>
                        <MarketImg src={education} alt='education' />
                        <ImgLeftContent>
                            <h3>Education</h3>
                            <p>
                                The learning environment of today has evolved
                                into an entirely different model than what many
                                of us remember due to the integration of a vast
                                array of technology. From classrooms to common
                                areas, we offer products that will help
                                transform your classrooms into the best learning
                                environment for your students.
                            </p>
                        </ImgLeftContent>
                    </ImgLeftContainer>
                    <ImgRightContainer animateIn='fadeIn' animateOnce='true'>
                        <MarketImg src={government} alt='government' />
                        <ImgRightContent>
                            <h3>Government</h3>
                            <p>
                                Whether you’re a Federal, State, County or City
                                entity, we’ll find the right purchasing
                                agreement and/or contract to help you furnish
                                your space at a discounted rate.
                            </p>
                        </ImgRightContent>
                    </ImgRightContainer>
                    <ImgLeftContainer animateIn='fadeIn' animateOnce='true'>
                        <MarketImg src={healthcare} alt='healthcare' />
                        <ImgLeftContent>
                            <h3>Healthcare</h3>
                            <p>
                                Regardless of if you’re an academic hospital
                                using state funds or a for-profit private
                                system, we have the experience to guide you
                                through the purchasing process to find products
                                that meet your requirements and satisfies all
                                regulations with affordable, cost-effective
                                options.
                            </p>
                        </ImgLeftContent>
                    </ImgLeftContainer>
                    <ImgRightContainer animateIn='fadeIn' animateOnce='true'>
                        <MarketImg src={industrial} alt='industrial' />
                        <ImgRightContent>
                            <h3>Industrial</h3>
                            <p>
                                At Facility Solutions Plus, we know that
                                everything is time sensitive and that any
                                downtime will only create more problems for
                                purchasing personnel. With our combined 60 years
                                of experience, you can rest easy knowing that
                                we’ll help you overcome the typical challenges
                                of any high-level project management.
                            </p>
                        </ImgRightContent>
                    </ImgRightContainer>
                    <ImgLeftContainer animateIn='fadeIn' animateOnce='true'>
                        <MarketImg src={science} alt='science' />
                        <ImgLeftContent>
                            <h3>Science & Technology</h3>
                            <p>
                                As biotech and pharmaceutical companies seek to
                                appeal to the next generation of scientists, the
                                definition of a high-quality workplace continues
                                to shift with an emphasis on collaborative work
                                environments. We have both the experience and
                                products to transform your office to reflect
                                this change without costing a fortune.
                            </p>
                        </ImgLeftContent>
                    </ImgLeftContainer>
                </Markets>
            </MarketContent>
            <SimpleContactForm />
        </MarketContainer>
    );
}
