import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

// import Button from "../../components/Button/Button";

import accessory from "../../assets/img/pages/home/productCategories/accessories.jpg";
import cube from "../../assets/img/pages/home/productCategories/cube.jpg"; // ;
import customMillwork from "../../assets/img/pages/home/productCategories/custom-millwork.jpg";
// import benching from "../../assets/img/pages/home/productCategories/bench.jpg";
import seating from "../../assets/img/pages/home/productCategories/seating.jpg";
import openConcept from "../../assets/img/pages/home/productCategories/open-concept.jpg";
import privateOffice from "../../assets/img/pages/home/productCategories/private-office.jpg";
import table from "../../assets/img/pages/home/productCategories/table.jpg";
import lockerStorage from "../../assets/img/pages/home/productCategories/storage.jpg";
import outdoor from "../../assets/img/pages/home/productCategories/outdoor-seating.jpg";
import wallFlooring from "../../assets/img/pages/home/productCategories/walls-flooring.jpg";
import FSPlus from "../../assets/img/pages/home/FSPLUS-LOGO.jpg";

const ProductsContainer = styled(ScrollAnimation)`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 10rem;
    h2 {
        margin-bottom: 4rem;
    }
`;

const Content = styled.div`
    display: flex;
    flex-flow: column;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium});
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;

const HoverContent = styled.div`
    width: 23rem;
    height: 23rem;
    background-color: #000;
    transition: 0.5s ease;
    opacity: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        opacity: 0;
        width: 15rem;
        height: 15rem;
    }
`;

const Desc = styled.p`
    position: absolute;
    z-index: 10;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: center;
`;

const ProdImg = styled(ScrollAnimation)`
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        opacity: 0.4;
        display: block;
        transition: 0.5s ease;
        backface-visibility: hidden;
        width: 23rem;
        height: auto;
        margin: 0.5rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        img {
            width: 15rem;
            opacity: 1;
        }
        p {
            font-size: 2rem;
        }

        &:hover img {
            opacity: 0.2;
        }

        &: hover ${HoverContent} {
            opacity: 1;
        }
    }
`;

export default function ProductsHome() {
    return (
        <ProductsContainer animateIn='fadeIn' animateOnce='true'>
            <h2>Products</h2>
            <Content>
                <Row>
                    <Link to='/products'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Seating</Desc>
                            </HoverContent>
                            <img src={seating} alt='seating' />
                        </ProdImg>
                    </Link>
                    <Link to='/products'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Tables</Desc>
                            </HoverContent>
                            <img src={table} alt='tables' />
                        </ProdImg>
                    </Link>
                    <Link to='/products'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Private Office</Desc>
                            </HoverContent>
                            <img src={privateOffice} alt='private office' />
                        </ProdImg>
                    </Link>
                    <Link to='/products'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Cubicles</Desc>
                            </HoverContent>
                            <img src={cube} alt='cubicle' />
                        </ProdImg>
                    </Link>
                    <Link to='/products'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Open Concept</Desc>
                            </HoverContent>
                            <img src={openConcept} alt='open concept' />
                        </ProdImg>
                    </Link>
                    <Link to='/products'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Outdoor seating</Desc>
                            </HoverContent>
                            <img src={outdoor} alt='outdoor' />
                        </ProdImg>
                    </Link>
                    <Link to='/products'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Lockers & Storage</Desc>
                            </HoverContent>
                            <img src={lockerStorage} alt='locker storage' />
                        </ProdImg>
                    </Link>
                    <Link to='/products'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Accessories</Desc>
                            </HoverContent>
                            <img src={accessory} alt='accessories' />
                        </ProdImg>
                    </Link>
                    <Link to='/products'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Walls & Flooring</Desc>
                            </HoverContent>
                            <img src={wallFlooring} alt='walls and flooring' />
                        </ProdImg>
                    </Link>
                    <Link to='/products'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Signs & Wayfinding</Desc>
                            </HoverContent>
                            <img src={FSPlus} alt='signs and wayfinding' />
                        </ProdImg>
                    </Link>
                    <Link to='/products'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Custom Millwork</Desc>
                            </HoverContent>
                            <img src={customMillwork} alt='custom millwork' />
                        </ProdImg>
                    </Link>
                </Row>
            </Content>
        </ProductsContainer>
    );
}
