import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Theme from "./theme/Theme";

import styled from "styled-components";

import useWindowDimensions from "./components/windowDimension/windowDimension";

import GoToTop from "./components/GoToTop";

// ===== COMPONENTS =====
import MobileNavbar from "./components/layout/NavBar/MobileNavbar";
import DesktopNavbar from "./components/layout/NavBar/DesktopNavbar";
import MobileFooter from "./components/layout/AppFooter/Mobile/MobileFooter";
import DesktopFooter from "./components/layout/AppFooter/Desktop/DesktopFooter";
import NotFound from "./components/NotFound/NotFound";

// ===== PAGES =====
import home from "./pages/home/home";
import about from "./pages/about/about";
import markets from "./pages/markets/markets";
import services from "./pages/services/services";
import products from "./pages/products/products";
import contact from "./pages/contact/contact";
import process from "./pages/process/process";
import portfolio from "./pages/portfolio/portfolio";
import resources from "./pages/resources/resources";

import Blog05042020 from "./pages/resources/blogposts/Blog05042020";
import Blog04012020 from "./pages/resources/blogposts/Blog04012020";
import Blog03052020 from "./pages/resources/blogposts/Blog03052020";

import Blog03022020 from "./pages/resources/blogposts/Blog03022020";
import Blog02242020 from "./pages/resources/blogposts/Blog02242020";
import Blog02152020 from "./pages/resources/blogposts/Blog02152020";
import Blog02062020 from "./pages/resources/blogposts/Blog02062020";
import Blog01272020 from "./pages/resources/blogposts/Blog01272020";
import Blog01252020 from "./pages/resources/blogposts/Blog01252020";
import Blog01212020 from "./pages/resources/blogposts/Blog01212020";

// PORTFOLIO SUB PAGES
import TXportfolio from "./components/Portfolio/TXportfolio/TXportfolio";
import GAportfolio from "./components/Portfolio/GAportfolio/GAportfolio";
import ALportfolio from "./components/Portfolio/ALportfolio/ALportfolio";
import ILportfolio from "./components/Portfolio/ILportfolio/ILportfolio";
import LAportfolio from "./components/Portfolio/LAportfolio/LAportfolio";
import WAportfolio from "./components/Portfolio/WAportfolio/WAportfolio";
import FLportfolio from "./components/Portfolio/FLportfolio/FLportfolio";
import careers from "./pages/careers/careers";
import acctManager from "./pages/careers/acctManager";

// VENDOR LIST
import TXVendorList from "./components/VendorList/TXVendorList";
import GAVendorList from "./components/VendorList/GAVendorList";
import PrivacyPolicy from "./pages/privacy/PrivacyPolicy";

const AppContainerDesktop = styled.div`
    width: 100%;
    margin-top: 6rem;
`;

const AppContainerMobile = styled.div`
    width: 100%;
    margin-top 4.3rem;
`;

const App = () => {
    const { width } = useWindowDimensions();

    const isDesktopNavbar = width >= 900 ? <DesktopNavbar /> : <MobileNavbar />;
    const isDesktopFooter = width >= 850 ? <DesktopFooter /> : <MobileFooter />;
    const isDesktopAppContainer =
        width >= 900 ? (
            <AppContainerDesktop>
                <GoToTop>
                    <Switch>
                        <Route exact path='/' component={home} />
                        <Route exact path='/about' component={about} />
                        <Route
                            exact
                            path='/about/process'
                            component={process}
                        />
                        <Route path='/markets' component={markets} />
                        <Route path='/services' component={services} />
                        <Route exact path='/products' component={products} />
                        <Route path='/contact' component={contact} />
                        <Route exact path='/portfolio' component={portfolio} />
                        <Route exact path='/resources' component={resources} />
                        <Route
                            exact
                            path='/portfolio/tx'
                            component={TXportfolio}
                        />
                        <Route
                            exact
                            path='/portfolio/ga'
                            component={GAportfolio}
                        />
                        <Route
                            exact
                            path='/portfolio/al'
                            component={ALportfolio}
                        />
                        <Route
                            exact
                            path='/portfolio/il'
                            component={ILportfolio}
                        />
                        <Route
                            exact
                            path='/portfolio/la'
                            component={LAportfolio}
                        />
                        <Route
                            exact
                            path='/portfolio/wa'
                            component={WAportfolio}
                        />
                        <Route
                            exact
                            path='/portfolio/fl'
                            component={FLportfolio}
                        />
                        <Route exact path='/careers' component={careers} />
                        <Route
                            exact
                            path='/careers/account-manager'
                            component={acctManager}
                        />
                        <Route path='/vendors/texas' component={TXVendorList} />
                        <Route
                            path='/vendors/georgia'
                            component={GAVendorList}
                        />
                        <Route
                            exact
                            path='/resources/05042020'
                            component={Blog05042020}
                        />
                        <Route
                            exact
                            path='/resources/04012020'
                            component={Blog04012020}
                        />
                        <Route
                            exact
                            path='/resources/03052020'
                            component={Blog03052020}
                        />
                        <Route
                            exact
                            path='/resources/03022020'
                            component={Blog03022020}
                        />
                        <Route
                            exact
                            path='/resources/02242020'
                            component={Blog02242020}
                        />
                        <Route
                            exact
                            path='/resources/02152020'
                            component={Blog02152020}
                        />
                        <Route
                            exact
                            path='/resources/02062020'
                            component={Blog02062020}
                        />
                        <Route
                            exact
                            path='/resources/01272020'
                            component={Blog01272020}
                        />
                        <Route
                            exact
                            path='/resources/01252020'
                            component={Blog01252020}
                        />
                        <Route
                            exact
                            path='/resources/01212020'
                            component={Blog01212020}
                        />
                        <Route
                            exact
                            path='/privacy-policy'
                            component={PrivacyPolicy}
                        />
                        <Route component={NotFound} />
                    </Switch>
                </GoToTop>
            </AppContainerDesktop>
        ) : (
            <AppContainerMobile>
                <GoToTop>
                    <Switch>
                        <Route exact path='/' component={home} />
                        <Route exact path='/about' component={about} />
                        <Route path='/about/process' component={process} />
                        <Route path='/markets' component={markets} />
                        <Route path='/services' component={services} />
                        <Route exact path='/products' component={products} />
                        <Route path='/contact' component={contact} />
                        <Route exact path='/portfolio' component={portfolio} />
                        <Route exact path='/resources' component={resources} />
                        <Route
                            exact
                            path='/portfolio/tx'
                            component={TXportfolio}
                        />
                        <Route
                            exact
                            path='/portfolio/ga'
                            component={GAportfolio}
                        />
                        <Route
                            exact
                            path='/portfolio/al'
                            component={ALportfolio}
                        />
                        <Route
                            exact
                            path='/portfolio/il'
                            component={ILportfolio}
                        />
                        <Route
                            exact
                            path='/portfolio/la'
                            component={LAportfolio}
                        />
                        <Route
                            exact
                            path='/portfolio/wa'
                            component={WAportfolio}
                        />
                        <Route
                            exact
                            path='/portfolio/fl'
                            component={FLportfolio}
                        />
                        <Route exact path='/careers' component={careers} />
                        <Route
                            exact
                            path='/careers/account-manager'
                            component={acctManager}
                        />
                        <Route path='/vendors/texas' component={TXVendorList} />
                        <Route
                            path='/vendors/georgia'
                            component={GAVendorList}
                        />
                        <Route
                            exact
                            path='/resources/05042020'
                            component={Blog05042020}
                        />
                        <Route
                            exact
                            path='/resources/04012020'
                            component={Blog04012020}
                        />
                        <Route
                            exact
                            path='/resources/03052020'
                            component={Blog03052020}
                        />
                        <Route
                            exact
                            path='/resources/03022020'
                            component={Blog03022020}
                        />
                        <Route
                            exact
                            path='/resources/02242020'
                            component={Blog02242020}
                        />
                        <Route
                            exact
                            path='/resources/02152020'
                            component={Blog02152020}
                        />
                        <Route
                            exact
                            path='/resources/02062020'
                            component={Blog02062020}
                        />
                        <Route
                            exact
                            path='/resources/01272020'
                            component={Blog01272020}
                        />
                        <Route
                            exact
                            path='/resources/01252020'
                            component={Blog01252020}
                        />
                        <Route
                            exact
                            path='/resources/01212020'
                            component={Blog01212020}
                        />
                        <Route
                            exact
                            path='/privacy-policy'
                            component={PrivacyPolicy}
                        />
                        <Route component={NotFound} />
                    </Switch>
                </GoToTop>
            </AppContainerMobile>
        );

    return (
        <Theme>
            <BrowserRouter>
                {isDesktopNavbar}
                {isDesktopAppContainer}
                {isDesktopFooter}
            </BrowserRouter>
        </Theme>
    );
};

export default App;
