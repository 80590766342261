import styled from "styled-components";

const Button = styled.button`
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.background};
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-size: ${(props) => props.theme.fonts.sizes.t5};
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: none;
    border-radius: 50px;
    padding: 0.8rem 1rem;
    outline: 0;
    -moz-transition: all 0.1s ease-in;
    -o-transition: all 0.1s ease-in;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.2s ease-in;
    &:hover {
        background-color: ${(props) => props.theme.colors.btnHover};
    }
`;

export const FullWidthSquareBtn = styled(Button)`
    width: 100%;
    border-radius: 5px;
    margin-top: 2rem;
`;

export const AltColorBtn = styled(Button)`
    background: ${(props) => props.color};
    &:hover {
        background-color: ${(props) => props.hoverColor};
    }
`;

export default Button;
