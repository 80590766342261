import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import savannah1 from "./pic 1.jpg";
import savannah2 from "./Pic 2.jpg";
import savannah3 from "./Pic 3.jpg";
import savannah4 from "./Pic 4.jpg";
import savannah5 from "./Pic 5.jpg";
import savannah6 from "./Pic 6.jpg";

const imgs = [savannah1, savannah2, savannah3, savannah4, savannah5, savannah6];

const Container = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 4rem 0;
  h2 {
    margin-bottom: 4rem;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
`;

const FolioImg = styled.img`
  margin: 1rem 0;
  width: 20rem;
  height: auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 1rem 1rem;
    width: 25rem;
  }
`;

export default function SavannahGA() {
  let markup = imgs.map((img) => <FolioImg src={img} alt="atlanta" />);
  return (
    <Container animateIn="fadeIn" animateOnce="true">
      <h2>Savannah, GA</h2>
      <Content>{markup}</Content>
    </Container>
  );
}
