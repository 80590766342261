import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Header from "../../../assets/img/pages/resources/blogs/blog03022020/header.jpeg";
import Img1 from "../../../assets/img/pages/resources/blogs/blog03022020/img1.jpeg";
import Img2 from "../../../assets/img/pages/resources/blogs/blog03022020/img2.jpeg";
import Img3 from "../../../assets/img/pages/resources/blogs/blog03022020/img3.jpeg";
import Img4 from "../../../assets/img/pages/resources/blogs/blog03022020/img4.jpeg";

import ContactUsHome from "../../../components/ContactUsHome/ContactUsHome";

const MainContainer = styled.div`
    width: 100%;
    padding: 0 30%;
    margin-top: 8rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-top: 8rem;
        padding: 0 2rem;
    }

    a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
    }

    p {
        margin-bottom: 1.5rem;
    }
    h1 {
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.5rem;
        margin-top: 6rem;
        text-transform: uppercase;
    }
`;

const BlogImg = styled.img`
    width: 100%;
    height: auto;
    margin: 4rem 0;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin: 2rem 0;
    }
`;

const Date = styled.p`
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.btnDisabled};
`;

//eslint-disable-next-line
const Bolded = styled.span`
    font-weight: 800;
`;

const Italics = styled.span`
    font-style: italic;
`;

const PostsNavigator = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10rem;
`;

const NewPost = styled.div`
    width: 50%;
    display: flex;
    flex-flow: column;
`;

const OldPost = styled(NewPost)`
    align-items: end;
    text-align: right;
`;

export default function Blog03022020() {
    return (
        <>
            <MainContainer>
                <Date>March 2nd, 2020</Date>
                <h1>Social Media's Place in the Office</h1>
                <BlogImg src={Header} alt='header' />
                <p>
                    Facebook, Twitter, LinkedIn, Instagram, Pinterest, YouTube,
                    Snapchat, TikTok—these social media platforms have
                    revolutionized modern society. Thanks to these digital
                    platforms, the very nature of human communication and
                    interaction has shifted, now that we have the ability to
                    watch historic events unfold before us on a YouTube
                    livestream, or share an important update with potentially
                    thousands of people in our networks on LinkedIn. But as with
                    any major development in the course of human history, there
                    are pros and cons. How do the two sides of social media play
                    out in the context of the office? Read on to learn more!
                </p>
                <h2>what is social media?</h2>
                <BlogImg src={Img1} alt='social media' />
                <p>
                    Before we can dive into the benefits and disadvantages of
                    social media in the office, it’s worth defining this
                    often-times nebulous term. Internet and marketing scholars
                    Andreas Kaplan and Michael Haenlein{" "}
                    <a
                        href='https://www.researchgate.net/publication/222403703_Users_of_the_World_Unite_The_Challenges_and_Opportunities_of_Social_Media'
                        rel='noreferrer'
                        target='_blank'
                    >
                        define
                    </a>{" "}
                    social media as “a group of Internet-based applications that
                    build on the ideological and technological foundations of
                    Web 2.0, and that allow the creation and exchange of
                    user-generated content.” Here’s a further breakdown of this
                    definition, as explored by Kaplan and Haenlein:
                </p>
                <p>
                    <Italics>Web 2.0:</Italics>
                </p>
                <p>
                    The first iteration of the Internet, Web 1.0, can be
                    understood as a static environment where it was only
                    possible to search for and read information. Think of it
                    like a bulletin board—but one where you could only look at
                    the posters tacked onto it. With the advent of Web 2.0, it
                    became possible for anyone to post on this metaphorical
                    bulletin board, and to interact with any of the postings in
                    new ways. In other words, Web 2.0 can be characterized as
                    the highly-interactive, highly-participatory Internet that
                    we know of today.
                </p>
                <p>
                    <Italics>User-generated content:</Italics>
                </p>
                <p>
                    User-generated content can be thought of as the flyers that
                    anyone can post on the bulletin board of the Internet. It is
                    the content created by the average virtual denizen, posts
                    like a Facebook life update, an Instagram photo of last
                    Sunday’s brunch, a Tik Tok covering the latest dance craze.
                </p>
                <p>
                    With these further definitions in mind, social media can be
                    understood as the digital platforms in which users can
                    create, share, and interact with other user-created content
                    within the virtual plane of the Internet.
                </p>
                <h2>the dangers of social media</h2>
                <BlogImg src={Img2} alt='social media shackles' />
                <p>
                    Given the unprecedented change that social media has brought
                    to modern society, there are certain complications and
                    dangers present in the technologies that have arisen. For
                    example, the debate over whether or not social media
                    negatively impacts mental health by increasing rates of
                    depression and decreasing your sense of self-worth is{" "}
                    <a
                        href='https://www.independent.co.uk/life-style/health-and-families/social-media-mental-health-negative-effects-depression-anxiety-addiction-memory-a8307196.html'
                        rel='noreferrer'
                        target='_blank'
                    >
                        ripe
                    </a>
                    with{" "}
                    <a
                        href='https://www.forbes.com/sites/alicegwalton/2017/06/30/a-run-down-of-social-medias-effects-on-our-mental-health/#4adcb90a2e5a'
                        rel='noreferrer'
                        target='_blank'
                    >
                        arguments
                    </a>{" "}
                    that{" "}
                    <a
                        href='https://www.nbcnews.com/health/mental-health/social-media-linked-rise-mental-health-disorders-teens-survey-finds-n982526'
                        rel='noreferrer'
                        target='_blank'
                    >
                        identify
                    </a>{" "}
                    social media as guilty. Put simply, spending long amounts of
                    time on social media can increase the rate and scale at
                    which we compare ourselves to others, ultimately hurting our
                    overall mental health.
                </p>
                <p>
                    Furthermore, the current design of social media contains
                    more insidious undertones. Tristan Harris, Google’s former
                    Design Ethicist,{" "}
                    <a
                        href='https://medium.com/thrive-global/how-technology-hijacks-peoples-minds-from-a-magician-and-google-s-design-ethicist-56d62ef5edf3'
                        rel='noreferrer'
                        target='_blank'
                    >
                        outlines a number of ways
                    </a>{" "}
                    in which social media is designed to “hijack” our brains,
                    including the endless scroll of our timelines and feeds, and
                    the randomization of our feeds whenever we refresh them
                    (much like the randomization to come from a slot machine).
                    Social media scholar{" "}
                    <a
                        href='http://fuchs.uti.at/books/culture-and-economy-in-the-age-of-social-media/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Christian Fuchs
                    </a>{" "}
                    explains that these seemingly addictive design choices are
                    due to the profit models of social media companies, which
                    are built upon maximizing the time we spend on their digital
                    platforms. This increases the rate at which we are exposed
                    to (and the chances that we click on) the targeted
                    advertisements paid for by the social media companies’
                    advertising clients. In other words, whenever we scroll
                    through Twitter or Instagram, we are not the consumer;
                    instead, we are the products that are being sold by social
                    media companies to their advertising clients.
                </p>
                <h2>How social media can benefit the office</h2>
                <BlogImg src={Img3} alt='social media' />
                <p>
                    And while there are certainly many criticisms to acknowledge
                    when discussing social media, it would be unfair not to
                    discuss the benefits that the technology can hold. Social
                    media is, after all, a tool meant to help us learn, share,
                    and connect with each other! Here are some{" "}
                    <a
                        href='https://theolsongroup.com/5-reasons-social-media-workplace-can-help-employees/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        ways
                    </a>{" "}
                    that social media can benefit the office:
                    <ul>
                        <li>
                            <Bolded>Gives a mental break for employees:</Bolded>{" "}
                            <a
                                href='https://www.facilitysolutionsplus.com/blog/2020/1/27/why-you-should-take-breaks-in-the-office'
                                rel='noreferrer'
                                target='_blank'
                            >
                                Taking breaks
                            </a>{" "}
                            throughout the work day is important, and social
                            media can provide a nice respite from any
                            mentally-vigorous tasks that you may be working on.
                            For instance, watching a few funny cat videos on
                            Facebook can be just what you need before you get
                            back to crunching some important numbers!
                        </li>
                        <li>
                            <Bolded>
                                Facilitates professional networking:
                            </Bolded>{" "}
                            It’s no secret that LinkedIn is one of the premiere
                            social media platforms for the working professional.
                            Through the site, you can expand your network and
                            make some professional connections that could lead
                            to future collaboration and opportunities!
                        </li>
                        <li>
                            <Bolded>Enhances problem solving:</Bolded> Another
                            benefit that social media can provide the office is
                            its ability to enhance problem solving. If you and
                            your team are stumped by a question that no amount
                            of Google searching can resolve, then turning to
                            your digital networks to pose your question may
                            provide the solution that you are seeking.
                        </li>
                        <li>
                            <Bolded>
                                Serves as an effective marketing tool:
                            </Bolded>{" "}
                            One final benefit that social media can yield your
                            office is as a promotional marketing tool. If you
                            have a new product or an upcoming event you want to
                            showcase, turning to LinkedIn, Facebook, and Twitter
                            can be the perfect way to get the word out.
                        </li>
                    </ul>
                </p>
                <h2>in conclusion</h2>
                <BlogImg src={Img4} alt='social media' />
                <p>
                    In the end, social media is a tool that carries with it both
                    good and bad aspects. At its worst, social media can suck
                    you into its seemingly bottomless rabbit hole of comment
                    threads and videos, distracting you from accomplishing
                    anything during the work day; at its best, it’s a channel
                    that brings new depth to your ability to communicate with
                    and learn from the others in your industry, enabling your
                    company to reach greater heights. If you find that your
                    company struggles more with the former half of social media,
                    it may be a good idea to limit prolonged phone use to your
                    office’s break room. And here at Facility Solutions Plus, we
                    can help you design the perfect breakroom in which your
                    employees can scroll through their feeds to decompress,
                    learn something new, and so much more.
                </p>
                <p>
                    <Italics>
                        Facility Solutions Plus is a client-centered,
                        full-service commercial office furniture dealership and
                        service provider that specializes in meeting needs,
                        solving problems and enhancing the total office
                        environment through customized, cost-effective workspace
                        solutions...and more.
                    </Italics>
                </p>
                <PostsNavigator>
                    <NewPost>
                        <h4>Newer Post</h4>
                        <Link to='/resources/03052020'>
                            The Optimum Office Temperature
                        </Link>
                    </NewPost>
                    <OldPost>
                        <h4>Older Post</h4>
                        <Link to='/resources/02242020'>
                            Buyer's Guide: Reception Desk
                        </Link>
                    </OldPost>
                </PostsNavigator>
            </MainContainer>
            <ContactUsHome />
        </>
    );
}
