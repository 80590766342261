import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import waitingRoom from "../../assets/img/pages/services/buzzi/waiting-room.png";
import cube from "../../assets/img/pages/services/buzzi/cube.png";
import green from "../../assets/img/pages/services/buzzi/green.png";
import orgatec from "../../assets/img/pages/services/buzzi/orgatec.png";
import stool from "../../assets/img/pages/services/buzzi/stools.png";
import pod from "../../assets/img/pages/services/buzzi/pod.jpeg";
import picnic from "../../assets/img/pages/services/buzzi/picnic.jpeg";
import ContactUsHome from "../../components/ContactUsHome/ContactUsHome";

const Main = styled.div`
    width: 100%;
    margin-top: 10rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    h1 {
        text-align: center;
    }
`;

const Content = styled.div`
    width: 80%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    h2 {
        text-align: center;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 60%;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        width: 50%;
        padding-right: 5rem;
    }
`;

const ReverseContent = styled(Content)`
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        padding-left: 5rem;
        width: 65%;
    }

    @media screen and (min);
`;

const HeroImg = styled.img`
    margin: 4rem 0;
    width: 100%;
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    h1 {
        text-align: center;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        flex-flow: row;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.XL}) {
        width: 80%;
    }
    @media screen and (min-width: ${({ theme }) =>
            theme.breakpoints.widescreen}) {
        width: 60%;
    }
`;

const ReverseColumnContainer = styled(ColumnContainer)`
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        flex-direction: row-reverse;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.XL}) {
        width: 80%;
    }
    @media screen and (min-width: ${({ theme }) =>
            theme.breakpoints.widescreen}) {
        width: 60%;
    }
`;

const FontItalic = styled.span`
    font-style: italic;
`;

const ServicesImg = styled.img`
    width: 100%;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 20rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        width: 18rem;
    }
`;

const ColumnContent = styled.div`
    margin: 4rem 0;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 100vw;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        flex-flow: column;
        width: 50%;
    }
`;

const FontStyle = styled.span`
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 800;
    color: #061c38;
`;

export default function services() {
    return (
        <Main>
            <h1>Your Office, Your Way</h1>
            <HeroImg src={waitingRoom} alt='buzzi waiting room' />
            <ReverseColumnContainer>
                <Content>
                    <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                        <h2>What to Expect:</h2>
                    </ScrollAnimation>
                    <br />
                    <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                        <p>
                            We provide complete office furniture services from
                            Project Consultation to Space Planning to even
                            Post-Installation Training and Maintenance to keep
                            your furniture looking great in your new and/or
                            refurbished offices.
                        </p>
                    </ScrollAnimation>
                    <br />
                    <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                        <p>
                            At Facility Solutions Plus, we treat everyone like
                            family and will work with you to ensure that your
                            next office furniture project is completed without a
                            problem.
                        </p>
                    </ScrollAnimation>
                    <br />
                    <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                        <p>
                            Our goal is to make the office furniture procurement
                            process as painless as possible. Especially when
                            there are so many choices to choose from in the US
                            furniture market.
                        </p>
                    </ScrollAnimation>
                    <br />
                    <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                        <p>
                            Our company has relationships with some of the best
                            office furniture companies in the US. These
                            relationships let us formulate plans and solutions
                            for budgets both big <FontItalic>and</FontItalic>{" "}
                            small.
                        </p>
                    </ScrollAnimation>
                </Content>
                <ColumnContent>
                    <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                        <ServicesImg src={cube} alt='collab space' />
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                        <ServicesImg src={green} alt='buzzi green' />
                    </ScrollAnimation>
                </ColumnContent>
            </ReverseColumnContainer>
            <ColumnContainer>
                <ReverseContent>
                    <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                        <h2>Our Services:</h2>
                    </ScrollAnimation>
                    <br />
                    <ul>
                        <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                            <li>
                                <FontStyle>Project Consultation:</FontStyle>{" "}
                                We'll meet with you to discuss the specifics of
                                your project and show you the wide range of
                                options available for your chosen budget.
                            </li>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                            <li>
                                <FontStyle>Design & Specification:</FontStyle>{" "}
                                Once we've established your wants and needs,
                                we'll search high and low for office furniture
                                that meets your design aesthetics.
                            </li>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                            <li>
                                <FontStyle>Space Planning:</FontStyle> Our team
                                will work with you through every step of the
                                space planning process to ensure your new (or
                                refurbished) office furniture fits your space in
                                the best and most space efficient way possible.
                            </li>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                            <li>
                                <FontStyle>Project Management:</FontStyle> Once
                                you've finalized your choices, our project
                                management team will ensure that all of your
                                office furniture orders are in sync with your
                                busy schedule and are delivered and installed on
                                a time/date that best suits you.
                            </li>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                            <li>
                                <FontStyle>Delivery & Installation:</FontStyle>{" "}
                                Our team of experienced installers will ensure
                                that every piece of office furniture is
                                delivered intact and in a timely manner at your
                                desired location.
                            </li>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                            <li>
                                <FontStyle>
                                    Post-Install Training & Maintenance:
                                </FontStyle>{" "}
                                We'll show your employees how to use their new
                                office furniture in the most ergonomic way
                                possible to provide maximum comfort as well as
                                provide ongoing maintenance as needed.
                            </li>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                            <li>
                                <FontStyle>
                                    Recycling/Disposal & Buyback:
                                </FontStyle>{" "}
                                Want to get rid of your old furniture but unsure
                                where to start? We'll handle that for you and
                                help find your old office furniture new homes or
                                recycle/dispose of it.
                            </li>
                        </ScrollAnimation>
                    </ul>
                </ReverseContent>
                <ColumnContent>
                    <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                        <ServicesImg src={orgatec} alt='Buzzi orgatec' />
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                        <ServicesImg src={stool} alt='Buzzi stools' />
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                        <ServicesImg src={pod} alt='Buzzi pod' />
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                        <ServicesImg src={picnic} alt='Buzzi picnic' />
                    </ScrollAnimation>
                </ColumnContent>
            </ColumnContainer>
            <ContactUsHome />
        </Main>
    );
}
