/* eslint-disable */
export default {
    colors: {
        primary: "#F56601",
        secondary: "#051C38",
        tertiary: "#202020",
        accent: "#489FB5",
        background: "#FFFFFF",
        btnHover: "#ffab70",
        btnDisabled: "#b6bfc2",
        grayText: "#4e525c",
        toast: {
            success: "#64c173",
            info: "#2F86EB",
            warning: "#FFC021",
            error: "#DC3546",
        },
    },
    fonts: {
        sizes: {
            h1: "64px",
            h2: "55px",
            h3: "48px",
            h4: "36px",
            t1: "24px",
            t2: "20px",
            t3: "18px",
            t4: "16px",
            t5: "14px",
        },
    },
    breakpoints: {
        small: "480px",
        medium: "768px",
        large: "1024px",
        XL: "1200px",
        widescreen: "1440px",
    },
};
