import React from "react";
import styled from "styled-components";

import ContactUsHome from "../../components/ContactUsHome/ContactUsHome";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.tertiary};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    justify-content: center;
  }
`;

const Content = styled.div`
  width: 80%;
  display: flex;
  margin-bottom: 4rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  h1 {
    margin: 4rem 0;
  }

  p {
    margin-bottom: 2rem;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 60%;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
`;

const Italic = styled.p`
  font-style: italic;
`;

const Emphasis = styled.p`
  font-family: "Monterssat", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
`;

const Category = styled.p`
  font-size: 1.2rem;
`;

const Unordered = styled.ul`
  margin-bottom: 2rem;
`;

export default function acctManager() {
  return (
    <Container>
      <Content>
        <h1>Account Manager</h1>
        <Row>
          <Italic>
            The Account Manager on the Strategic Accounts Team is responsible
            for all project and daily work order activities, sales and full
            customer satisfaction for assigned accounts.
          </Italic>
        </Row>
        <Row>
          <Emphasis>Specific Responsibilities Include:</Emphasis>
          <Category>Customer/Account Servicing</Category>
          <Unordered>
            <li>
              Single point-of-contact for coordinating all dealer activities,
              services, orders and personnel for each assigned account
            </li>
            <li>
              Responsible for all client relations and on-going relationships
              with client personnel, and client third parties (contractors, A&D
              firms, etc.)
            </li>
          </Unordered>
        </Row>
        <Row>
          <Category>Project & Work Order Management</Category>
          <Unordered>
            <li>
              Oversees all customer projects and work orders; directs dealer
              team for implementation of customer orders and service requests
            </li>
            <li>
              Provides frequent and regular status reports to customer regarding
              project and work order status
            </li>
          </Unordered>
        </Row>
        <Row>
          <Category>Sales Order Management</Category>
          <Unordered>
            <li>
              Develops plans and product specifications either on own or through
              work with the design department; produces detailed, accurate and
              professional looking quotes through own effort or in conjunction
              with dealership personnel (designers, project managers, etc.) and
              presents these to the customer in a timely manner
            </li>
            <li>
              Works with service departments to develop service contracts to
              present to customer when complex services are sold (design, for
              instance) or for major projects (installation, design, project
              mgmt
            </li>
          </Unordered>
        </Row>
        <Row>
          <Category>Process, Quality and Customer Satisfaction</Category>

          <Unordered>
            <li>
              Ensures standards of performance are met for all customer work
              activities
            </li>
            <li>
              Establishes costs and sell amounts for all account products and
              services, based on contract pricing, gross margin guidelines and
              established service pricing; is responsible for “cost-of-sale”
              against account purchases
            </li>
          </Unordered>
        </Row>
        <Row>
          <Category>Sales & Marketing</Category>
          <Unordered>
            <li>
              Provides frequent and regular follow-up with customer regarding
              information for products and services including all support for
              customer’s requests
            </li>
            <li>
              Presents new products and services to customer, and exposes
              customer to new opportunities to purchase goods and services from
              dealership
            </li>
          </Unordered>
        </Row>
        <Row>
          <Category>Contract Furniture/Design Industry Knowledge</Category>
          <Unordered>
            <li>
              Strong knowledge of interior design field and current practices
            </li>
            <li>
              Understands contract furniture processes, including order
              preparation, project management, order management and
              delivery/installation
            </li>
          </Unordered>
        </Row>
        <Row>
          <Category>Organizational Interfaces</Category>
          <Unordered>
            <li>
              Works collaboratively with client and client’s third-party firms,
              including A&D firms, interior contractors, electrical and
              communication engineers, customer’s IT group, etc.
            </li>
            <li>
              Manages team assignments to ensure work load activities are
              appropriately balanced and supported
            </li>
          </Unordered>
        </Row>
        <Row>
          <Emphasis>Qualifications + Skills:</Emphasis>
          <Unordered>
            <li>
              Bachelor’s degree, or equivalent combination of education and
              experience
            </li>
            <li>Minimum 3 – 5 years Commercial Furniture Sales experience</li>
            <li>Demonstrated space planning and project management ability</li>
            <li>Experience with Haworth products preferred</li>
            <li>Polished communication skills</li>
            <li>Excellent interpersonal skills</li>
          </Unordered>
        </Row>
        <Row>
          <Italic>
            Facility Solutions Plus is an equal opportunity employer.{" "}
          </Italic>
          <Italic>
            If any accommodations are needed for applicants during the selection
            process, please notify the Director of Human Resources during the
            recruitment process.
          </Italic>
          <Italic>
            We appreciate your application, but only those selected for an
            interview will be contacted.
          </Italic>
          <b>Job Type: Full-time, exempt</b>
        </Row>
      </Content>
      <ContactUsHome />
    </Container>
  );
}
