import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";

import resourcesHeader from "../../assets/img/pages/resources/resources-header.jpeg";

import Blog05042020Thumbnail from "../../assets/img/pages/resources/blogs/blog05042020/thumbnail.jpeg";
import Blog04012020Thumbnail from "../../assets/img/pages/resources/blogs/blog04012020/blog04012020thumbnail.jpeg";
import Blog03052020Thumbnail from "../../assets/img/pages/resources/blogs/blog03052020/thumbnail.jpeg";
import Blog03022020Thumbnail from "../../assets/img/pages/resources/blogs/blog03022020/thumbnail.jpeg";
import Blog02242020Thumbnail from "../../assets/img/pages/resources/blogs/blog02242020/thumbnail.jpeg";
import Blog02152020Thumbnail from "../../assets/img/pages/resources/blogs/blog02152020/thumbnail.jpeg";
import Blog02062020Thumbnail from "../../assets/img/pages/resources/blogs/blog02062020/thumbnail.jpeg";
import Blog01252020Thumbnail from "../../assets/img/pages/resources/blogs/blog01272020/thumbnail.jpeg";
import Blog01272020Thumbnail from "../../assets/img/pages/resources/blogs/blog01252020/thumbnail.jpeg";
import Blog01212020Thumbnail from "../../assets/img/pages/resources/blogs/blog01212020/thumbnail.png";

const MainContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    h1 {
        margin-bottom: 4rem;
    }
`;

const HeaderImg = styled.div`
    width: 100vw;
    height: 50vh;
    background: url(${resourcesHeader});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 8rem;
`;

const MainBlogContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        padding: 0 20%;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;

const Blog = styled(ScrollAnimation)`
    display: flex;
    flex-flow: column;
    width: 20rem;
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.colors.tertiary};
    p {
        color: ${({ theme }) => theme.colors.btnDisabled};
    }
`;

const BlogTitle = styled.h3`
    line-height: 1.5rem;
    margin: 1.5rem 0 1rem 0;
`;

const BlogImg = styled.img``;

export default function resources() {
    return (
        <MainContainer>
            <HeaderImg src={resourcesHeader} alt='header' />
            <h1>Resources</h1>
            <MainBlogContainer>
                <Link to='/resources/05042020'>
                    <Blog animateIn='fadeIn' animateOnce='True'>
                        <BlogImg src={Blog05042020Thumbnail} alt='thumbnail' />
                        <BlogTitle>
                            CREATING FLOW WHILE WORKING FROM HOME
                        </BlogTitle>
                        <p>May 4, 2020</p>
                    </Blog>
                </Link>
                <Link to='/resources/04012020'>
                    <Blog animateIn='fadeIn' animateOnce='True'>
                        <BlogImg src={Blog04012020Thumbnail} alt='thumbnail' />
                        <BlogTitle>
                            FOOD, PRODUCTIVITY, AND THE OFFICE
                        </BlogTitle>
                        <p>April 1, 2020</p>
                    </Blog>
                </Link>
                <Link to='/resources/03052020'>
                    <Blog animateIn='fadeIn' animateOnce='True'>
                        <BlogImg src={Blog03052020Thumbnail} alt='thumbnail' />
                        <BlogTitle>The Optimum Office Temperature</BlogTitle>
                        <p>March 5th, 2020</p>
                    </Blog>
                </Link>
                <Link to='/resources/03022020'>
                    <Blog animateIn='fadeIn' animateOnce='True'>
                        <BlogImg src={Blog03022020Thumbnail} alt='thumbnail' />
                        <BlogTitle>
                            Social Media's Place in the Office
                        </BlogTitle>
                        <p>March 2nd, 2020</p>
                    </Blog>
                </Link>
                <Link to='/resources/02242020'>
                    <Blog animateIn='fadeIn' animateOnce='True'>
                        <BlogImg src={Blog02242020Thumbnail} alt='thumbnail' />
                        <BlogTitle>Buyer's Guide: Reception Desk</BlogTitle>
                        <p>February 24th, 2020</p>
                    </Blog>
                </Link>
                <Link to='/resources/02152020'>
                    <Blog animateIn='fadeIn' animateOnce='True'>
                        <BlogImg src={Blog02152020Thumbnail} alt='thumbnail' />
                        <BlogTitle>
                            Strengthening Teamwork in the Office
                        </BlogTitle>
                        <p>February 15th, 2020</p>
                    </Blog>
                </Link>
                <Link to='/resources/02062020'>
                    <Blog animateIn='fadeIn' animateOnce='True'>
                        <BlogImg src={Blog02062020Thumbnail} alt='thumbnail' />
                        <BlogTitle>Cognitive Diversity in the Office</BlogTitle>
                        <p>February 6th, 2020</p>
                    </Blog>
                </Link>
                <Link to='/resources/01272020'>
                    <Blog animateIn='fadeIn' animateOnce='True'>
                        <BlogImg src={Blog01272020Thumbnail} alt='thumbnail' />
                        <BlogTitle>
                            Cultivating Productivity in the Office
                        </BlogTitle>
                        <p>January 27th, 2020</p>
                    </Blog>
                </Link>
                <Link to='/resources/01252020'>
                    <Blog animateIn='fadeIn' animateOnce='True'>
                        <BlogImg src={Blog01252020Thumbnail} alt='thumbnail' />
                        <BlogTitle>
                            Why You Should Take Breaks in the Office
                        </BlogTitle>
                        <p>January 25th, 2020</p>
                    </Blog>
                </Link>
                <Link to='/resources/01212020'>
                    <Blog animateIn='fadeIn' animateOnce='True'>
                        <BlogImg src={Blog01212020Thumbnail} alt='thumbnail' />
                        <BlogTitle>Feng Shui in the Office</BlogTitle>
                        <p>January 21st, 2020</p>
                    </Blog>
                </Link>
            </MainBlogContainer>
        </MainContainer>
    );
}
