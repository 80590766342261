import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import { HashLink } from "react-router-hash-link";

// ===== COMPONENTS =====
import HomeHeroSlider from "../../components/HomeHeroSlider/HomeHeroSlider";
import HomeAboutSection from "../../components/HomeAboutSection/HomeAboutSection";
import ProductsHome from "../../components/ProductsHome/ProductsHome";
import DesktopVendorLogoCarousel from "../../components/DesktopVendorLogoCarousel/DesktopVendorLogoCarousel";
import MarketExpertise from "../../components/MarketExpertise/MarketExpertise";
import OurOfficeHome from "../../components/OurOfficeHome/OurOfficeHome";
import ContactUsHome from "../../components/ContactUsHome/ContactUsHome";

// ===== IMAGES =====
import ProjectCons from "../../assets/img/pages/home/services/consultation.svg";
import SpacePlan from "../../assets/img/pages/home/services/space-planning.svg";
import Delivery from "../../assets/img/pages/home/services/delivery-install.svg";
import Reconfigure from "../../assets/img/pages/home/services/reconfiguration.svg";
import Decommission from "../../assets/img/pages/home/services/decommissioning.svg";
import HomeClientQuote from "../../components/HomeClientQuote/HomeClientQuote";
import HomeMarketRelationships from "../../components/HomeMarketRelationships/HomeMarketRelationships";

// import hero from "../../assets/img/pages/home/animatedSlider/corporate.jpeg";

// ===== STYLED COMPONENTS =====
const HomeContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    color: ${({ theme }) => theme.colors.tertiary};
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        justify-content: center;
    }
`;

const HomeContent = styled.div`
    width: 80%;
`;

const ServicesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 10rem;
    h2 {
        margin-bottom: 4rem;
    }
`;

const ServicesContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
`;

const Service = styled(ScrollAnimation)`
    width: 15rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    h3 {
        color: #000;
    }
`;

const ServicesIcon = styled.img`
    margin: 3rem 0 0 0;
    width: 14rem;
    -moz-transition: transform 250ms;
    -o-transition: transform 250ms;
    -webkit-transition: transform 250ms;
    transition: transform 250ms;
    &:hover {
        transform: translateY(5px);
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        margin: 2rem 2rem 0 2rem;
        width: 10rem;
    }
`;

const ScrollIn = styled(ScrollAnimation)`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export default function home() {
    return (
        <HomeContainer>
            <HomeHeroSlider />
            <HomeAboutSection />
            <HomeClientQuote />
            <HomeContent>
                <ServicesContainer className={`fadeInSection`}>
                    <ScrollIn animateIn='fadeIn' animateOnce='true'>
                        <h2>Services</h2>
                    </ScrollIn>
                    <ServicesContent>
                        <Service animateIn='fadeIn' animateOnce='true'>
                            <HashLink smooth to='/services#consultation'>
                                <ServicesIcon
                                    src={ProjectCons}
                                    alt='Project Consultation Icon'
                                />
                            </HashLink>
                            <h3>Project Consultation</h3>
                        </Service>
                        <Service animateIn='fadeIn' animateOnce='true'>
                            <HashLink smooth to='/services#planning'>
                                <ServicesIcon
                                    src={SpacePlan}
                                    alt='Space Planning'
                                />
                            </HashLink>
                            <h3>Space Planning</h3>
                        </Service>
                        <Service animateIn='fadeIn' animateOnce='true'>
                            <HashLink smooth to='/services#reconfig'>
                                <ServicesIcon
                                    src={Reconfigure}
                                    alt='Reconfigure'
                                />
                            </HashLink>
                            <h3>Reconfiguration</h3>
                        </Service>
                        <Service animateIn='fadeIn' animateOnce='true'>
                            <HashLink smooth to='/services#decomm'>
                                <ServicesIcon
                                    src={Decommission}
                                    alt='Decommission'
                                />
                            </HashLink>
                            <h3>Decommission</h3>
                        </Service>
                        <Service animateIn='fadeIn' animateOnce='true'>
                            <HashLink smooth to='/services#delivery'>
                                <ServicesIcon
                                    src={Delivery}
                                    alt='Delivery & Installation'
                                />
                            </HashLink>
                            <h3>Delivery & Installation</h3>
                        </Service>
                    </ServicesContent>
                </ServicesContainer>

                <ProductsHome />

                <HomeMarketRelationships />

                <ScrollIn animateIn='fadeIn' animateOnce='true'>
                    <DesktopVendorLogoCarousel />
                </ScrollIn>

                <MarketExpertise />

                <ScrollIn animateIn='fadeIn' animatedOnce='true'>
                    <OurOfficeHome />
                </ScrollIn>
                <ContactUsHome />
            </HomeContent>
        </HomeContainer>
    );
}
