import React from "react";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

import hero from "../../../assets/img/pages/portfolio/USmap.jpg";

import longwood1 from "./Horne FL 3.JPEG";
import longwood2 from "./Horne FL 4.JPEG";
import longwood3 from "./Horne FL 5.JPEG";
import longwood4 from "./Horne FL 6.JPEG";
import longwood5 from "./Horne FL 7.JPEG";
import longwood6 from "./Horne FL 8.JPEG";
import longwood7 from "./Horne FL 9.JPEG";
import longwood8 from "./Horne FL 10.JPEG";
import longwood9 from "./Horne FL 11.JPEG";

const imgs = [
  longwood1,
  longwood2,
  longwood3,
  longwood4,
  longwood5,
  longwood6,
  longwood7,
  longwood8,
  longwood9,
];

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.tertiary};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    justify-content: center;
  }
`;

const CityNavbar = styled.div`
  background-color: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #000;
  p {
    list-style-type: none;
    color: #000;
  }
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 2rem;
  padding: 1rem 0;
  position: -webkit-sticky;
  position: sticky;
  top: 6.8rem;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 10px 20%;
    flex-flow: row;
    top: 6rem;
  }
  z-index: 100;
`;

const HeroImg = styled.div`
  background: url(${hero});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f4eee2;
  width: 100%;
  height: 50vh;
  margin-bottom: 8rem;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
`;

const CityLinks = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0.2rem 0;
  width: 100%;
  z-index: 200;
  color: #000;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
  }
`;

const FolioImg = styled.img`
  margin: 1rem 0;
  width: 20rem;
  height: auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 1rem 1rem;
    width: 25rem;
  }
`;

export default function TXportfolio() {
  let markup = imgs.map((img) => <FolioImg src={img} alt="test" />);
  return (
    <Container>
      <HeroImg />
      <h1 id="fl">Florida</h1>
      <span id="Longwood"></span>
      <CityNavbar>
        <CityLinks>
          <HashLink smooth to="/portfolio/fl#longwood">
            <p>Longwood</p>
          </HashLink>
        </CityLinks>
      </CityNavbar>
      <Content>{markup}</Content>
    </Container>
  );
}
