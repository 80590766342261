import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import newlenox1 from "./Sgins and Wayfiding SOBH.jpg";
import newlenox2 from "./Silver Oaks_1.jpg";
import newlenox3 from "./SOBH Lobby.jpg";
import newlenox4 from "./Wardrobe and Beds SOBH.jpg";

const imgs = [newlenox1, newlenox2, newlenox3, newlenox4];

const Container = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 4rem 0;
  h2 {
    margin-bottom: 4rem;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
`;

const FolioImg = styled.img`
  margin: 1rem 0;
  width: 20rem;
  height: auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 1rem 1rem;
    width: 25rem;
  }
`;

export default function NewlenoxIL() {
  let markup = imgs.map((img) => <FolioImg src={img} alt="new lenox" />);
  return (
    <Container animateIn="fadeIn" animateOnce="true">
      <h2>New Lenox, IL</h2>
      <Content>{markup}</Content>
      <span id="waukagen"></span>
    </Container>
  );
}
