import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import angleton1 from "./used-cubicles-teknion-1.jpg";
import angleton2 from "./used-cubicles-teknion-2.jpg";
import angleton3 from "./used-cubicles-teknion-3.jpg";

const Container = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 4rem 0;
  h2 {
    margin-bottom: 4rem;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
  }
`;

const FolioImg = styled.img`
  margin: 1rem 0;
  width: 20rem;
  height: auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 1rem 1rem;
    width: 25rem;
  }
`;

export default function AngletonTX() {
  return (
    <Container animateIn="fadeIn" animateOnce="true">
      <h2>Angleton, TX</h2>
      <Content>
        <FolioImg src={angleton1} alt="angleton" />
        <FolioImg src={angleton2} alt="angleton" />
        <FolioImg src={angleton3} alt="angleton" />
      </Content>
      <span id="houston"></span>
    </Container>
  );
}
