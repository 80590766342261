import React, { Component } from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";

import outdoor from "../../assets/img/pages/about/outdoor.jpg";
import jack from "../../assets/img/pages/about/Jack.jpg";
import cristina from "../../assets/img/pages/about/cristina.jpg";
import chris from "../../assets/img/pages/about/ChrisBalcar.jpeg";
import kennen from "../../assets/img/pages/about/Kennen.jpg";
import FSPlusLogo from "../../assets/img/pages/about/FSPLUS-LOGO.jpg";
import star from "../../assets/img/icons/star.png";
import jackie from "../../assets/img/pages/about/jackie.jpg";
import abigail from "../../assets/img/pages/about/abigailsmith.jpeg";

import Button from "../../components/Button/Button";

const AboutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    color: ${({ theme }) => theme.colors.tertiary};
`;

const AboutContent = styled.div`
    width: 80%;
    h2 {
        margin-bottom: 4rem;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 60%;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
        width: 60%;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.XL}) {
        width: 55%;
    }
`;

const HeroImg = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url(${outdoor});
    background-position: 0 40%;
    width: 100%;
    height: 50vh;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;
    font-family: "Montserrat", sans-serif;
    color: white;
    font-weight: 600;
    font-size: ${({ theme }) => theme.fonts.sizes.h1};
`;

const TeamHistory = styled.div`
    margin-bottom: 8rem;
    p {
        margin-bottom: 1.5rem;
    }
    h2 {
        text-align: center;
    }
`;

const TeamContent = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 8rem;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    }
`;

const Profile = styled(ScrollAnimation)`
    display: flex;
    flex-flow: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 1rem 0 1rem;

    p {
        color: ${({ theme }) => theme.colors.grayText};
        font-family: "Montserrat", sans-serif;
        font-size: 0.83rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        margin: 1rem;
    }
`;

const ProfileImg = styled.img`
    width: 14rem;
    height: auto;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        filter: grayscale(100%);
        -moz-transition: all 0.1s ease-in;
        -o-transition: all 0.1s ease-in;
        -webkit-transition: all 0.1s ease-in;
        transition: all 0.2s ease-in;
        &:hover {
            filter: grayscale(0);
        }
    }
`;

const ScrollIn = styled(ScrollAnimation)`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    p {
        margin-bottom: 2rem;
    }
`;

const OurProcess = styled.div`
    width: 100%;
    margin-bottom: 8rem;
    h2 {
        text-align: center;
    }
`;

const OurValues = styled.div`
    width: 100%;
    margin-bottom: 8rem;
    h2 {
        text-align: center;
    }
    h3 {
        color: ${({ theme }) => theme.colors.grayText};
    }
`;

const ReviewBox = styled(ScrollAnimation)`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    border: 1px solid ${({ theme }) => theme.colors.btnDisabled};
    border-radius: 10px;

    margin-bottom: 1rem;

    padding: 2rem;
    p {
        margin-bottom: 1rem;
    }
`;

const Stars = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
`;

const StarIcon = styled.img`
    width: 1rem;
`;

const OurTeam = styled.div`
    width: 100%;
    h2 {
        text-align: center;
    }
`;

const ReadMore = styled.a`
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
`;
export class about extends Component {
    render() {
        return (
            <AboutContainer>
                <HeroImg />
                <AboutContent>
                    <TeamHistory>
                        <h2>Our History</h2>
                        <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                            <p>
                                <b>Facility Solutions Plus</b> was founded by
                                four individuals that had a dream for what could
                                be accomplished in the office furniture
                                industry.  We were all in agreement that
                                customer satisfaction is the key to the success
                                of our business.  Thus, offering an exemplary
                                service will be the core business of the company
                                instead of sales.
                            </p>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                            <p>
                                We will listen closely and consider your
                                requirements relating to the design, the look,
                                budget and timescale, meeting each of your
                                expectation every possible way.   Whether it is
                                one private office or a complex configurations
                                of cubicles you desire, Facility Solutions Plus
                                will find you the best solution to your office
                                needs.
                            </p>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                            <p>
                                Our service includes design, project
                                consultation, space planning, delivery &
                                installation, reconfiguration, decommissioning,
                                as well as a selection of the widest range of
                                office furniture to meet all designs and
                                budgets.  Our industry professionals will
                                collaborate with your team to oversee all
                                aspects of the project and our fast, efficient,
                                and detail-oriented installation teams will
                                actualize your vision.
                            </p>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
                            <p>
                                We approach every project with a can-do attitude
                                because we believe that there is a creative
                                solution to every problem.  At Facility
                                Solutions Plus, you can rest assured that our
                                combined experience will leave your next office
                                furniture journey in capable hands.
                            </p>
                        </ScrollAnimation>
                    </TeamHistory>
                    <OurTeam>
                        <h2>Our Team</h2>
                        <TeamContent>
                            <Profile animateIn='fadeIn' animateOnce='true'>
                                <ProfileImg src={jack} alt='Jack McIntyre' />
                                <h3>Jack McIntyre</h3>
                                <p>Principal</p>
                                <p>jmcintyre@facilitysolutionsplus.com</p>
                            </Profile>
                            <Profile animateIn='fadeIn' animateOnce='true'>
                                <ProfileImg
                                    src={cristina}
                                    alt='Cristina Legacion'
                                />
                                <h3>Cristina Legacion</h3>
                                <p>Principal</p>
                                <p>cristina@facilitysolutionsplus.com</p>
                            </Profile>
                            <Profile animateIn='fadeIn' animateOnce='true'>
                                <ProfileImg src={chris} alt='Chris Balcar' />
                                <h3>Chris Balcar</h3>
                                <p>Principal</p>
                                <p>cbalcar@facilitysolutionsplus.com</p>
                            </Profile>
                            <Profile animateIn='fadeIn' animateOnce='true'>
                                <ProfileImg src={kennen} alt='Kennen Smith' />
                                <h3>Kennen Smith</h3>
                                <p>Principal</p>
                                <p>ksmith@facilitysolutionsplus.com</p>
                            </Profile>

                            <Profile animateIn='fadeIn' animateOnce='true'>
                                <ProfileImg
                                    src={FSPlusLogo}
                                    alt='Rudolph Rocha'
                                />
                                <h3>Rudolph Rocha</h3>
                                <p>Installation Supervisor</p>
                            </Profile>
                            <Profile animateIn='fadeIn' animateOnce='true'>
                                <ProfileImg
                                    src={FSPlusLogo}
                                    alt='Jose Martinez'
                                />
                                <h3>Jose Martinez</h3>
                                <p>Installer</p>
                            </Profile>
                            <Profile animateIn='fadeIn' animateOnce='true'>
                                <ProfileImg
                                    src={FSPlusLogo}
                                    alt='Larry Jones'
                                />
                                <h3>Larry Jones</h3>
                                <p>Warehouse Supervisor</p>
                            </Profile>
                            <Profile animateIn='fadeIn' animateOnce='true'>
                                <ProfileImg
                                    src={FSPlusLogo}
                                    alt='Makensie Balcar'
                                />
                                <h3>Makensie Balcar</h3>
                                <p>Project Coordinator</p>
                            </Profile>
                            <Profile animateIn='fadeIn' animateOnce='true'>
                                <ProfileImg
                                    src={FSPlusLogo}
                                    alt='Makensie Balcar'
                                />
                                <h3>Ethan Balcar</h3>
                                <p>Quality Control Inspector</p>
                            </Profile>
                            <Profile animateIn='fadeIn' animateOnce='true'>
                                <ProfileImg
                                    src={jackie}
                                    alt='Jackie Legacion'
                                />
                                <h3>Jackie Legacion</h3>
                                <p>Full Stack Developer</p>
                            </Profile>
                            <Profile animateIn='fadeIn' animateOnce='true'>
                                <ProfileImg src={abigail} alt='Abigail Smith' />
                                <h3>Abigail Smith</h3>
                                <p>Design Coordinator</p>
                            </Profile>
                        </TeamContent>
                    </OurTeam>
                    <OurProcess>
                        <h2>Our Process</h2>
                        <ScrollIn animateIn='fadeIn' animateOnce='true'>
                            <p>
                                From project inception to completion, our seven
                                step process will help you choose the best
                                products for your working environment, all while
                                staying within your specified budget.
                            </p>
                            <Link to='/about/process'>
                                <Button>Learn More</Button>
                            </Link>
                        </ScrollIn>
                    </OurProcess>
                    <OurValues>
                        <h2>Our Values</h2>
                        <ScrollIn animateIn='fadeIn' animateOnce='true'>
                            <p>
                                <b>Facility Solutions Plus</b>​ was founded on
                                the concept of family values. We want our
                                clients, employees, partners, manufacturers, and
                                vendors to all be a part of our team. Our pledge
                                to you is honesty, loyalty, and the best service
                                we can provide.
                            </p>
                        </ScrollIn>
                        <ReviewBox animateIn='fadeIn' animateOnce='true'>
                            <h3>Shaireen Momin</h3>
                            <Stars>
                                <StarIcon src={star} alt='star png' />
                                <StarIcon src={star} alt='star png' />
                                <StarIcon src={star} alt='star png' />
                                <StarIcon src={star} alt='star png' />
                                <StarIcon src={star} alt='star png' />
                            </Stars>
                            <p>
                                Very pleased with the cubicles that were
                                installed in our office. Nate was super helpful
                                and responsive throughout the entire process. I
                                was initially skeptical when I reached out to
                                him through Facebook marketplace but it was
                                definitely a great decision. He went over all
                                the options that were available to us and helped
                                us plan our office space on a budget. He made
                                sure everyone got what they wanted and that the
                                end result was magnificent. Nate also responded
                                to my calls while at a BBQ. lol
                            </p>
                            <ReadMore
                                href='https://www.google.com/maps/place/Facility+Solutions+Plus/@29.7865681,-95.6770852,17z/data=!3m1!4b1!4m7!3m6!1s0x0:0x4c971760211ba2fd!8m2!3d29.7865681!4d-95.6748965!9m1!1b1'
                                target='_blank'
                            >
                                Read More
                            </ReadMore>
                        </ReviewBox>
                        <ReviewBox animateIn='fadeIn' animateOnce='true'>
                            <h3>LaNell Pitchell</h3>
                            <Stars>
                                <StarIcon src={star} alt='star png' />
                                <StarIcon src={star} alt='star png' />
                                <StarIcon src={star} alt='star png' />
                                <StarIcon src={star} alt='star png' />
                                <StarIcon src={star} alt='star png' />
                            </Stars>
                            <p>
                                These guys were awesome! Came in got right to
                                work and took everything I wanted them to take
                                loaded up so smooth and quick. Plus on a
                                Saturday! wow these guys are awesome. Highly
                                recommend them!
                            </p>
                            <ReadMore
                                href='https://www.google.com/maps/place/Facility+Solutions+Plus/@29.7865681,-95.6770852,17z/data=!3m1!4b1!4m7!3m6!1s0x0:0x4c971760211ba2fd!8m2!3d29.7865681!4d-95.6748965!9m1!1b1'
                                target='_blank'
                            >
                                Read More
                            </ReadMore>
                        </ReviewBox>
                        <ReviewBox animateIn='fadeIn' animateOnce='true'>
                            <h3>Meg Banahan</h3>
                            <Stars>
                                <StarIcon src={star} alt='star png' />
                                <StarIcon src={star} alt='star png' />
                                <StarIcon src={star} alt='star png' />
                                <StarIcon src={star} alt='star png' />
                                <StarIcon src={star} alt='star png' />
                            </Stars>
                            <p>
                                We just moved into our first office space and
                                Facility Solutions Plus was amazing!! Very
                                honest and reliable company. Jack and everyone
                                else at FSP that we dealt with was very
                                professional and made our move as seamless as
                                possible.
                            </p>
                            <ReadMore
                                href='https://www.google.com/maps/place/Facility+Solutions+Plus/@29.7865681,-95.6770852,17z/data=!3m1!4b1!4m7!3m6!1s0x0:0x4c971760211ba2fd!8m2!3d29.7865681!4d-95.6748965!9m1!1b1'
                                target='_blank'
                            >
                                Read More
                            </ReadMore>
                        </ReviewBox>
                    </OurValues>
                </AboutContent>
            </AboutContainer>
        );
    }
}

export default about;
