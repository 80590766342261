import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import laporte1 from "./DSCN0364.JPG";
import laporte2 from "./DSCN0366.JPG";
import laporte3 from "./DSCN0367.JPG";
import laporte4 from "./DSCN0368.JPG";
import laporte5 from "./DSCN0369.JPG";
import laporte6 from "./DSCN0379.JPG";
import laporte7 from "./DSCN0380.JPG";
import laporte8 from "./DSCN0381.JPG";

const imgs = [
  laporte1,
  laporte2,
  laporte3,
  laporte4,
  laporte5,
  laporte6,
  laporte7,
  laporte8,
];

const Container = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 4rem 0;
  h2 {
    margin-bottom: 4rem;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
`;

const FolioImg = styled.img`
  margin: 1rem 0;
  width: 20rem;
  height: auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 1rem 1rem;
    width: 25rem;
  }
`;

export default function LaporteTX() {
  let markup = imgs.map((img) => <FolioImg src={img} alt="test" />);
  return (
    <Container animateIn="fadeIn" animateOnce="true">
      <h2>La Porte, TX</h2>
      <Content>{markup}</Content>
      <span id="nederland"></span>
    </Container>
  );
}
