import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Header from "../../../assets/img/pages/resources/blogs/blog02242020/header.jpeg";
import Img1 from "../../../assets/img/pages/resources/blogs/blog02242020/img1.jpeg";
import Img2 from "../../../assets/img/pages/resources/blogs/blog02242020/img2.png";
import Img3 from "../../../assets/img/pages/resources/blogs/blog02242020/img3.png";
import Img4 from "../../../assets/img/pages/resources/blogs/blog02242020/img4.png";
import Img5 from "../../../assets/img/pages/resources/blogs/blog02242020/img5.png";

import ContactUsHome from "../../../components/ContactUsHome/ContactUsHome";

const MainContainer = styled.div`
    width: 100%;
    padding: 0 30%;
    margin-top: 8rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-top: 8rem;
        padding: 0 2rem;
    }

    a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
    }

    p {
        margin-bottom: 1.5rem;
    }
    h1 {
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.5rem;
        margin-top: 6rem;
        text-transform: uppercase;
    }
`;

const BlogImg = styled.img`
    width: 100%;
    height: auto;
    margin: 4rem 0;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin: 2rem 0;
    }
`;

const Date = styled.p`
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.btnDisabled};
`;

//eslint-disable-next-line
const Bolded = styled.span`
    font-weight: 800;
`;

const Italics = styled.span`
    font-style: italic;
`;

const PostsNavigator = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10rem;
`;

const NewPost = styled.div`
    width: 50%;
    display: flex;
    flex-flow: column;
`;

const OldPost = styled(NewPost)`
    align-items: end;
    text-align: right;
`;

const SmallImg = styled.img`
    width: 20rem;
`;

export default function Blog02242020() {
    return (
        <>
            <MainContainer>
                <Date>February 24th, 2020</Date>
                <h1>Buyer's Guide: Reception Desk</h1>
                <BlogImg src={Header} alt='header' />
                <p>
                    The reception desk is one of the most important parts of any
                    office. It’s often the first thing a client or partner will
                    see when they enter your office, so it’s important to make
                    sure your reception area makes a good first impression! Here
                    are some tips to help you pick a reception desk that
                    represents your company in its best light.
                </p>
                <h2>what makes a reception desk?</h2>
                <BlogImg src={Img1} alt='reception desk' />
                <p>
                    When choosing a reception desk, it’s useful to understand
                    what exactly makes up a reception desk. Generally, a
                    reception desk is the desk located at the front of an office
                    space where a number of matters are handled, including
                    greeting customers, answering the telephone, and scheduling
                    appointments. Depending on the size and function of your
                    company, the exact make and model of your reception desk
                    will vary. Nonetheless, many reception desks share these
                    foundational components:
                    <ul>
                        <li>
                            <Bolded>Transaction top:</Bolded> The transaction
                            top, usually designed to be above the head height of
                            the receptionist working, serves as the
                            multi-purpose surface that facilitates any number of
                            office-customer interaction, from signing documents
                            to showcasing company pamphlets.
                        </li>
                        <li>
                            <Bolded>Modesty panel:</Bolded> The modesty panel of
                            any reception desk usually extends all the way to
                            the ground, ensuring the privacy of the employee
                            working at the reception desk.
                        </li>
                        <li>
                            <Bolded>Storage:</Bolded> Depending on the specific
                            needs of your office, your reception desk may or may
                            not have storage; the ones that do have storage can
                            have units in a variety of styles, from traditional
                            storage cabinets to lateral file storing.
                        </li>
                        <li>
                            <Bolded>Return:</Bolded> L- and U-shaped reception
                            desks will have a return, either on the left- or
                            right-hand side of the desk. The return functions as
                            an extension of the desk which the receptionist can
                            use in a variety of manners, such as a space to sit
                            face to face with a client or as an additional
                            workspace. The side of the return will depend on
                            personal preference and office layout.
                        </li>
                    </ul>
                    Once again, the exact type of desk you purchase for your
                    company will depend on a variety of factors, from the size
                    of your office to the types of goods and services you offer.
                    Here’s a peek into the wide range of reception desks that
                    exist.
                </p>
                <h2>The spectrum of reception desks</h2>
                <p>
                    <Italics>Small companies:</Italics>
                </p>
                <SmallImg src={Img2} alt='reception desk' />
                <p>
                    If you’re a smaller company, it’s usually best to stick with
                    a less expensive desk. These desks will most likely be made
                    of laminate, and consist of a transaction top, reception
                    shell, one or two lockable storage cabinets, and a right- or
                    left-hand return, with the most economical reception desks
                    having a high return. Less pricier desks are less
                    customizable, but there is room for some changes in design,
                    such as swapping out the laminate transaction top for one
                    made of glass. We can help you find a desk within the price
                    range of $700-1,000, and we’ll have it delivered and
                    installed within two weeks.
                </p>
                <p>
                    <Italics>Med-sized companies:</Italics>
                </p>
                <SmallImg src={Img3} alt='reception desk' />
                <p>
                    Reception desks for mid-sized companies will have all of the
                    workings of a reception desk for a smaller company, with
                    more options when it comes to customization, like laminate
                    options that look like stone or wood. Additionally, they may
                    have other features, like pigeonholes (small compartments
                    used for storage) or a mailbox cubby. The desks we can help
                    you find will range from $1,200-3,000, with a lead time of
                    4-6 weeks.
                </p>
                <p>
                    <Italics>Large companies:</Italics>
                </p>
                <BlogImg src={Img4} alt='reception desk' />
                <p>
                    Larger companies will most likely have both the space and
                    budget to truly tailor their reception desk to their office
                    space. More often than not, the reception desk will be
                    separated from the rest of the office building in the lobby
                    area. With material options such as marble, wood, and stone,
                    the design possibilities are plentiful when it comes to
                    building the reception desk that emanates the energy of your
                    company. These desks will range from $8,000-10,000+, and the
                    lead time will vary, depending on the complexity of the desk
                    build.
                </p>
                <h2>In conclusion</h2>
                <BlogImg src={Img5} alt='reception' />
                <p>
                    Ultimately, finding the reception desk that best suits your
                    company depends on a multitude of factors that may seem
                    daunting to think through. Not to worry, though! Here at
                    Facility Solutions Plus, our team of experts can help you
                    along every part of your reception desk journey, from
                    figuring out the best sized desk given your office floor
                    plan to installing the desk’s finishing touches. After all,
                    the reception desk is the face of your company—we’ll make
                    sure that your reception desk puts your company’s best face
                    forward.
                </p>
                <p>
                    <Italics>
                        Facility Solutions Plus is a client-centered,
                        full-service commercial office furniture dealership and
                        service provider that specializes in meeting needs,
                        solving problems and enhancing the total office
                        environment through customized, cost-effective workspace
                        solutions...and more.
                    </Italics>
                </p>
                <PostsNavigator>
                    <NewPost>
                        <h4>Newer Post</h4>
                        <Link to='/resources/03022020'>
                            Social Media's Place in the Office
                        </Link>
                    </NewPost>
                    <OldPost>
                        <h4>Older Post</h4>
                        <Link to='/resources/02152020'>
                            Strengthening Teamwork in the Office
                        </Link>
                    </OldPost>
                </PostsNavigator>
            </MainContainer>
            <ContactUsHome />
        </>
    );
}
