import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Header from "../../../assets/img/pages/resources/blogs/blog05042020/header.jpeg";
import FlowImg from "../../../assets/img/pages/resources/blogs/blog05042020/flow.jpeg";
import FlowFriendlyHome from "../../../assets/img/pages/resources/blogs/blog05042020/flowfriendlyhome.jpeg";
import Cat from "../../../assets/img/pages/resources/blogs/blog05042020/cat.jpeg";
import ContactUsHome from "../../../components/ContactUsHome/ContactUsHome";

const MainContainer = styled.div`
    width: 100%;
    padding: 0 30%;
    margin-top: 8rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-top: 8rem;
        padding: 0 2rem;
    }

    a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
    }

    p {
        margin-bottom: 1.5rem;
    }
    h1 {
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.5rem;
        margin-top: 6rem;
    }
`;

const BlogImg = styled.img`
    width: 100%;
    height: auto;
    margin: 4rem 0;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin: 2rem 0;
    }
`;

const Date = styled.p`
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.btnDisabled};
`;

const Bolded = styled.span`
    font-weight: 800;
`;

const Italics = styled.span`
    font-style: italic;
`;

const PostsNavigator = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10rem;
`;

const NewPost = styled.div`
    width: 50%;
    display: flex;
    flex-flow: column;
    padding: 0.5rem;
`;

const OldPost = styled(NewPost)`
    align-items: end;
    text-align: right;
`;

export default function Blog05042020() {
    return (
        <>
            <MainContainer>
                <Date>May 4th, 2020</Date>
                <h1>Creating Flow While Working From Home</h1>
                <BlogImg src={Header} alt='header' />
                <p>
                    It's no secret that the modern workforce has been
                    fundamentally transformed by the ongoing outbreak of
                    COVID-19. In an effort to slow the spread of the
                    coronavirus, states across the country have{" "}
                    <a
                        href='https://www.cnn.com/2020/03/23/us/coronavirus-which-states-stay-at-home-order-trnd/index.html'
                        target='_bank'
                    >
                        implemented "stay-at-home' or "shelter-in-place" orders
                    </a>
                    . These mandates have left many people working at businesses
                    like restaurants, gyms, and movie theaters unemployed,
                    causing the real unemployment rate{" "}
                    <a
                        href='https://fortune.com/2020/04/23/us-unemployment-rate-numbers-claims-this-week-total-job-losses-april-23-2020-benefits-claims/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        to soar past 20% as of late April.
                    </a>
                </p>
                <p>
                    If you've been fortunate enough to keep your job amidst
                    these uncertain times, there is a good chance that you or
                    your employer have shifted to "work-from-home" working
                    conditions. In fact,{" "}
                    <a
                        href='https://news.gallup.com/poll/306695/workers-discovering-affinity-remote-work.aspx'
                        rel='noreferrer'
                        target='_blank'
                    >
                        an estimated 62% of employed Americans
                    </a>{" "}
                    have reported that they are currently working from home
                    during the crisis.
                </p>
                <p>
                    Transitioning to working from home can be a tricky process,
                    as the two worlds of your personal and professional lives
                    come crashing together. Cramped spaces, stir-crazy children,
                    and the prevailing uncertainty of today's times can all
                    impede you from getting much work done. Not to worry though
                    - read on for some tips and information that will help you
                    work well, even while working from home!
                </p>
                <h2>AN INTRODUCTION TO FLOW</h2>
                <BlogImg src={FlowImg} alt='blackboard with gears' />
                <p>
                    Beginning to work from home presents the perfect opportunity
                    to introduce some new concepts regarding productivity into
                    your professional life. An increasingly popular idea that's
                    taken the business world by storm is the concept of flow.
                    First introduced by the Hungarian psychologist Mihály
                    Csíkszentmihályi,{" "}
                    <a
                        href='https://positivepsychology.com/what-is-flow/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        flow
                    </a>{" "}
                    can be{" "}
                    <a href='defined' rel='noreferrer' target='_blank'>
                        defined
                    </a>{" "}
                    as "the positive mental state of being completely absorbed,
                    focused, and involved in your activities at a certain point
                    in time," or as an optimal state of consciousness where we
                    feel our best and perform our best." Think of flow as
                    similar to being "in the zone," the state in which "every
                    action, every decision, arises seamlessly from the last."
                </p>
                <p>
                    <a
                        href='https://hbr.org/2014/05/create-a-work-environment-that-fosters-flow'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Neuroscience lets us know
                    </a>{" "}
                    that when we enter flow, a cocktail of five different
                    neurochemicals come together, allowing us to find profound
                    clarity, motivation, and creativity. These five chemicals,
                    norepinephrine, dopamine, endorphins, anandamide, and
                    serotonin, all affect performance - norepinephrine and
                    dopamine hone our focus, endorphins block out pain,
                    anandamide encourages the synthesis of complex thoughts, and
                    serotonin provides a sense of enjoyment and pleasure.
                </p>
                <p>
                    In the realm of business, when these chemicals cascade in
                    our brains, flow allows for sky-high levels of productivity.
                    For example, the{" "}
                    <a
                        href='https://hbr.org/2014/05/create-a-work-environment-that-fosters-flow'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Harvard Business Review
                    </a>{" "}
                    writes that "in a 10-year study conducted by McKinsey, top
                    executives reported being five times more productive in
                    flow." This means that in one day, these executives would be
                    able to get as much done as the rest of their non-flow peers
                    would be able to do in one week.
                </p>
                <h2>BUILDING A FLOW-FRIENDLY HOME OFFICE</h2>
                <BlogImg
                    src={FlowFriendlyHome}
                    alt='flow friendly home office'
                />
                <p>
                    When working from home, a key step to tapping into flow is
                    creating a space that cultivates focus and creativity. Here
                    are some things to keep in mind in order to build the home
                    office perfect for cultivating flow:
                    <ul>
                        <li>
                            <Bolded>Comfortable chair:</Bolded> It's no secret
                            that the modern American workforce spends most of
                            their time sitting And while you may not have access
                            to your high-end office chair decked out with
                            synchro-tilters and ergonomic backs, it's still
                            important to choose a comfortable chair for your
                            home office. After all, nothing is more distracting
                            than being uncomfortable while working
                        </li>
                        <li>
                            <Bolded>Standing Desk:</Bolded> Conversely, in order
                            to combat the{" "}
                            <a
                                href='https://www.mayoclinic.org/healthy-lifestyle/adult-health/expert-answers/sitting/faq-20058005'
                                rel='noreferrer'
                                target='_blank'
                            >
                                health risks
                            </a>{" "}
                            that come with sitting for long periods of time
                            (which includes risks of death similar to the rates
                            of dying from obesity or smoking), a standing desk
                            presents the perfect solution. Some{" "}
                            <a
                                href='https://www.cnet.com/how-to/diy-ways-to-convert-any-desk-into-a-standing-desk/'
                                rel='noreferrer'
                                target='_blank'
                            >
                                DYI methods
                            </a>{" "}
                            for building a standing desk at home include putting
                            a coffee desk or night stand on top of your normal
                            desk or stacking several textbooks on top of each
                            other (though be careful about not knocking down
                            your laptop with the latter method!).
                        </li>
                        <li>
                            <Bolded>Second monitor:</Bolded> If you have an old
                            computer monitor lying around, connecting it to your
                            work computer to get a second monitor{" "}
                            <a
                                href='https://www.techadvisory.org/2016/07/benefits-of-using-dual-monitors/'
                                rel='noreferrer'
                                target='_blank'
                            >
                                can be a major boon for productivity
                            </a>
                            . From easier cutting and pasting to more efficient
                            multitasking, a second monitor could greatly help
                            you get into the groove of a flow state.
                        </li>
                        <li>
                            <Bolded>Green plants:</Bolded>{" "}
                            <a
                                href='https://www.facilitysolutionsplus.com/blog/2019/12/9/indoor-plants-and-a-healthier-office'
                                rel='noreferrer'
                                target='_blank'
                            >
                                Science lets us know
                            </a>{" "}
                            that we feel better after interacting with nature,
                            and plenty of research shows that having plants
                            indoors is great for mental, emotional, and physical
                            health. Consider moving any of your house plants
                            close to your indoor office in order to reap all of
                            the benefits of having a plant close to you while
                            you work.
                        </li>
                        <li>
                            <Bolded>Place with natural light:</Bolded> In the
                            same vein as the last point, placing yoru home
                            office close to windows that let in a lot of natural
                            light can also do wonders for you. In fact,{" "}
                            <a
                                href='http://www.nbbj.com/ideas/2013/3/19/let-there-be-light/'
                                rel='noreferrer'
                                target='_blank'
                            >
                                research has shown
                            </a>{" "}
                            that natural light can increase energy levels and
                            productivity and reduce eyestrain - all benefits
                            that will help you tap into flow!
                        </li>
                    </ul>
                </p>

                <h2>IN CONCLUSION</h2>
                <BlogImg src={Cat} alt='conluding image' />
                <p>
                    In the end, the extraordinary circumstances of today's times
                    have required many of us to work from home. This change in
                    lifestyle presents many challenges to both your personal and
                    professional life. With the blurring between these two
                    worlds, it's important to set clear boundaries between your
                    work from home life and your actual home life. This is why
                    it's especially crucial to carve out a space in your home
                    that you can dedicate to having as a home office - and with
                    the right design choices, it could be a place that helps you
                    tap into that highly coveted state of flow!
                </p>
                <p>
                    Here at Facility Solutions Plus, we have a range of products
                    that could help improve your home office, from ergonomic
                    office chairs to height-adjustable standing desks. After
                    all, it's entirely possible to be just as productive (and
                    maybe eve more so!) when working from home - we can help you
                    along the way.
                </p>
                <p>
                    <Italics>
                        Facility Solutions Plus is a client-centered,
                        full-service commercial office furniture dealership and
                        service provider that specializes in meeting needs,
                        solving problems and enhancing the total office
                        environment through customized, cost-effective workspace
                        solutions...and more.
                    </Italics>
                </p>
                <PostsNavigator>
                    <OldPost>
                        <h4>Older Post</h4>
                        <Link to='/resources/04012020'>
                            FOOD, PRODUCTIVITY, AND THE OFFICE
                        </Link>
                    </OldPost>
                </PostsNavigator>
            </MainContainer>
            <ContactUsHome />
        </>
    );
}
