/* eslint-disable */
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import whtLogo from "../../../../assets/img/logo/logo-wht-orng.png";
import facebookLogo from "../../../../assets/img/icons/facebook.svg";
import instagramLogo from "../../../../assets/img/icons/instagram.svg";
import twitterLogo from "../../../../assets/img/icons/twitter.svg";

const FooterContainer = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.secondary};
    bottom: 0;
    color: white;
    font-family: "Montserrat", sans-serif;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 4rem 0;
    font-weight: 300;
    margin-top: 20rem;
`;

const FooterInner = styled.div`
    width: 80%;
    display: flex;
`;

const LogoContainer = styled.div`
    width: 40%;
    display: flex;
    flex-flow: column;
    padding: 1rem;
`;

const NavLinksContainer = styled.div`
    width: 30%;
    display: flex;
    flex-flow: column;
    padding: 1rem;
`;

const ContactContainer = styled.div`
    width: 30%;
    display: flex;
    flex-flow: column;
    padding: 1rem;
    h4,
    p {
        margin-bottom: 1.5rem;
    }
`;

const NavLink = styled(Link)`
    color: white;
    text-decoration: none;
    margin-bottom: 1.5rem;
`;

const Logo = styled.img`
    width: 15rem;
`;

const SocialsContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 2rem 1rem;
    a {
        margin-right: 3rem;
        -moz-transition: transform 250ms;
        -o-transition: transform 250ms;
        -webkit-transition: transform 250ms;
        transition: transform 250ms;
        &:hover {
            transform: translateY(10px);
        }
    }
`;

const SocialsIcon = styled.img`
    width: 2.5rem;
`;

const Copyright = styled.div`
    width: 80%;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 3rem;
    p {
        font-size: ${({ theme }) => theme.fonts.sizes.t4};
        font-weight: 100;
    }
`;

export default function DesktopFooter() {
    return (
        <FooterContainer>
            <FooterInner>
                <LogoContainer>
                    <Logo src={whtLogo} alt='Logo' />
                    <SocialsContainer>
                        <a
                            href='https://www.facebook.com/FacilitySolutionsPlus/'
                            target='_blank'
                        >
                            <SocialsIcon
                                src={facebookLogo}
                                alt='Facebook Logo'
                            />
                        </a>
                        <a
                            href='https://www.instagram.com/facilitysolutionsplus/?hl=en'
                            target='_blank'
                        >
                            <SocialsIcon
                                src={instagramLogo}
                                alt='Instagram Logo'
                            />
                        </a>
                        <a
                            href='https://twitter.com/plus_facility'
                            target='_blank'
                        >
                            <SocialsIcon src={twitterLogo} alt='Twitter Logo' />
                        </a>
                    </SocialsContainer>
                    {/* HUB LOGO */}
                </LogoContainer>
                <NavLinksContainer>
                    <NavLink to='/careers'>CAREERS</NavLink>
                    <NavLink to='/products'>PRODUCTS</NavLink>
                    <NavLink to='/portfolio'>PORTFOLIO</NavLink>
                    <NavLink to='/vendors/texas'>TX VENDORS</NavLink>
                    <NavLink to='/vendors/georgia'>GA VENDORS</NavLink>
                </NavLinksContainer>
                <ContactContainer>
                    <h4>CONNECT</h4>
                    <p>info@facilitysolutionsplus.com</p>
                    <p>P: 1(713) 497-4495</p>
                </ContactContainer>
            </FooterInner>
            <Copyright>
                <p>&copy; All Rights Reserved 2021</p>
                <NavLink to='/privacy-policy'>Privacy Policy</NavLink>
            </Copyright>
        </FooterContainer>
    );
}
