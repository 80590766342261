import React, { useState, useRef } from "react";
import styles from "./Accordion.module.css";

import Chevron from "../Icons/Chevron/Chevron";

function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}rem`
    );
    setRotateState(
      setActive === "active"
        ? `${styles.accordion__icon}`
        : `${styles.accordion__icon} ${styles.rotate}`
    );
  }

  return (
    <div className={styles.accordion__section}>
      <button
        className={`${styles.accordion} ${setActive}`}
        onClick={toggleAccordion}
      >
        <div className={styles.accordion__header}>
          <p className={styles.accordion__title}>{props.title}</p>
          <p className={styles.accordion__subtitle}>{props.subtitle}</p>
        </div>
        <Chevron className={`${setRotate}`} width={20} fill={props.fill} />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className={styles.accordion__content}
      >
        <img
          className={styles.accordion__image}
          src={props.src}
          alt={props.alt}
        />
        <div
          className={styles.accordion__text}
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </div>
  );
}

export default Accordion;
