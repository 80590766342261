import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";

import AboutBG from "../../assets/img/pages/home/about-bg.jpg";

import Button from "../Button/Button";

const AboutSectionContainer = styled(ScrollAnimation)`
  width: 100%;
  background: url(${AboutBG});
  background-size: fill;
  padding: 10rem 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  h2,
  p {
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    background-size: cover;
  }
`;

const Content = styled.div`
  width: 100%;
  text-align: center;
  p:nth-child(3) {
    margin-bottom: 2rem;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 60%;
  }
`;

const Italic = styled.p`
  font-style: italic;
  margin: 1rem 0;
`;

export default function HomeAboutSection() {
  return (
    <AboutSectionContainer animateIn="fadeIn" animateOnce="true">
      <Content>
        <h2>Facility Solutions Plus</h2>
        <Italic>Where our clients are our number one priority.</Italic>
        <p>
          Facility Solutions Plus is a Houston, Texas-based company that was
          founded on the concept of family values. We want our clients,
          employees, partners, manufacturers, and vendors to all be a part of
          our team. Our pledge to you is honesty, loyalty, and the best service
          we can provide.
        </p>
        <Link to="/about">
          <Button>Learn More</Button>
        </Link>
      </Content>
    </AboutSectionContainer>
  );
}
