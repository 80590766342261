import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Header from "../../../assets/img/pages/resources/blogs/blog01272020/header.jpeg";
import Postiteyes from "../../../assets/img/pages/resources/blogs/blog01272020/postiteyes.jpeg";
import Maxproductivity from "../../../assets/img/pages/resources/blogs/blog01272020/maxproductivity.jpeg";
import Tomatotimer from "../../../assets/img/pages/resources/blogs/blog01272020/tomatotimer.jpeg";
import Guyworking from "../../../assets/img/pages/resources/blogs/blog01272020/guyworking.png";

import ContactUsHome from "../../../components/ContactUsHome/ContactUsHome";

const MainContainer = styled.div`
    width: 100%;
    padding: 0 30%;
    margin-top: 8rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-top: 8rem;
        padding: 0 2rem;
    }

    a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
    }

    p {
        margin-bottom: 1.5rem;
    }

    h1 {
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.5rem;
        margin-top: 6rem;
        text-transform: uppercase;
    }
`;

const BlogImg = styled.img`
    width: 100%;
    height: auto;
    margin: 4rem 0;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin: 2rem 0;
    }
`;

const Date = styled.p`
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.btnDisabled};
`;

//eslint-disable-next-line
const Bolded = styled.span`
    font-weight: 800;
`;

const Italics = styled.span`
    font-style: italic;
`;

const PostsNavigator = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10rem;
`;

const NewPost = styled.div`
    width: 50%;
    display: flex;
    flex-flow: column;
`;

const OldPost = styled(NewPost)`
    align-items: end;
    text-align: right;
`;

export default function Blog01272020() {
    return (
        <>
            <MainContainer>
                <Date>January 27th, 2020</Date>
                <h1>cultivating productivity in the office</h1>
                <BlogImg src={Header} alt='header' />
                <p>
                    Little feels better than starting your workday by going over
                    your to-do list with confidence, excited and ready to tackle
                    the tasks ahead of you. You dive head first into your work,
                    and by the time you resurface from your work flow groove for
                    a quick breather, it’s already lunchtime! And after a
                    replenishing lunch, you jump right back into the swing of
                    things, knocking off tasks on your to-do list left, right,
                    over, and under. Until finally, the workday ends, giving you
                    time to marvel at how much you accomplished today—and how
                    seamless, easy, and enjoyable it all felt! If only every day
                    was filled with this much productivity. Not to worry,
                    though—read on for a deeper dive into productivity in the
                    office, and how to make the most out of every work day!
                </p>
                <h2>the current state of office productivity</h2>
                <BlogImg
                    src={Postiteyes}
                    alt='office employee with post-its on eyes'
                />
                <p>
                    While optimal productivity is a priority in any work
                    environment, workers may not be as productive as they can
                    be.{" "}
                    <a
                        href='https://www.bls.gov/news.release/pdf/atus.pdf'
                        rel='noreferrer'
                        target='_blank'
                    >
                        According to the Bureau of Labor Statistics
                    </a>
                    , American employees work an average of 8.5 hours per day.
                    However, a{" "}
                    <a
                        href='https://www.vouchercloud.com/resources/office-worker-productivity'
                        rel='noreferrer'
                        target='_blank'
                    >
                        study
                    </a>{" "}
                    of almost 2,000 office employees found that people were
                    productive for a little under three hours everyday. That’s
                    less than half of the time they spend in the office! In
                    fact, 79% of the survey participants responded that they do
                    not consider themselves to be productive throughout the
                    entire workday. Rather than working, here’s a look at how
                    these employees spent their time, on average:
                </p>
                <p>
                    <ul>
                        <li>
                            <Bolded>Checking social media:</Bolded> 44 minutes
                        </li>
                        <li>
                            <Bolded>Reading news websites:</Bolded> 1 hour 5
                            minutes
                        </li>
                        <li>
                            <Bolded>
                                Discussing out of work activities with
                                colleagues:
                            </Bolded>{" "}
                            40 minutes
                        </li>
                        <li>
                            <Bolded>Making hot drinks:</Bolded> 17 minutes
                        </li>
                        <li>
                            <Bolded>Text/instant messaging:</Bolded> 14 minutes
                        </li>
                        <li>
                            <Bolded>Eating snacks:</Bolded> 8 minutes
                        </li>
                        <li>
                            <Bolded>Making food in office:</Bolded> 7 minutes
                        </li>
                        <li>
                            <Bolded>Making calls to partner/friends:</Bolded> 18
                            minutes
                        </li>
                        <li>
                            <Bolded>Searching for new jobs:</Bolded> 26 minutes
                        </li>
                    </ul>
                </p>
                <p>
                    <a
                        href='https://hbr.org/2018/12/the-case-for-the-6-hour-workday'
                        rel='noreferrer'
                        target='_blank'
                    >
                        The Harvard Business Review
                    </a>{" "}
                    explains that this lack of productivity can be partly
                    attributed to the expectations placed on employees: “Many of
                    today’s organizations sabotage flow by setting
                    counter-productive expectations on availability,
                    responsiveness, and meeting attendance” leaving employees in
                    a “constant state of distraction and hyper-responsiveness.”
                    From long meetings about inconsequential minutiae to the
                    pressure to respond to emails the second you receive one,
                    employees are perpetually pulled in a plethora of directions
                    that prevent them from actually accomplishing the things
                    they are meant to accomplish.
                </p>
                <p>
                    These distractions impede an employee’s ability to enter a
                    state of “flow,” a term used first by psychologist{" "}
                    <a
                        href='https://www.mckinsey.com/business-functions/organization/our-insights/increasing-the-meaning-quotient-of-work'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Mihàly Csìkszentmihàlyi
                    </a>{" "}
                    to describe the psycho-physiological state in which people
                    were “fully employing their core capabilities to meet a goal
                    or challenge created.” People who were able to enter a state
                    of flow as they were working reported that they “were more
                    productive and derived greater satisfaction from their work
                    than those who didn’t.”
                </p>
                <h2>increasing productivity</h2>
                <BlogImg src={Maxproductivity} alt='turn it up to eleven' />
                <p>
                    Clearly, the ability to access the flow state can play a
                    major part in cultivating productivity; but how can you
                    access this ultra-motivated, ultra-accomplished mode of
                    mind? Here are some tips to help maximize how productive you
                    are in the office:
                </p>
                <p>
                    <ul>
                        <li>
                            <Bolded>Decrease distractions:</Bolded> As discussed
                            earlier, the modern workplace is rife with all sorts
                            of distractions that can slow down your
                            productivity, with social media standing as one of
                            the biggest offenders. Turn off the notifications
                            for any social media applications you may have to
                            reduce any pings that may pull you away from your
                            work.
                        </li>
                        <li>
                            <Bolded>
                                Have the proper tools and equipment:
                            </Bolded>{" "}
                            Little is more frustrating than being ready to take
                            the next big step on a major project only to have
                            your progress halted because you can’t print some
                            necessary paperwork due to a faulty printer. Limit
                            unfortunate circumstances like this by ensuring that
                            all of your office equipment are in top shape.
                        </li>
                        <li>
                            <Bolded>Establish realistic goals:</Bolded> Hiking
                            Mount Everest is a seemingly insurmountable task;
                            but if you break that trek up into smaller hikes
                            from checkpoint to checkpoint, it becomes a lot more
                            feasible of a goal. Apply this same concept to your
                            own major projects—breaking them up into smaller,
                            more manageable goals—and watch your motivation and
                            productivity rise!
                        </li>
                    </ul>
                </p>
                <h2>Enter the pomodoro technique</h2>
                <BlogImg src={Tomatotimer} alt='tomato timer' />
                <p>
                    Amidst all of the productivity hacks, there exists one time
                    management strategy widely hailed as one of the most
                    effective ways to optimize your productivity. Enter: the
                    Pomodoro Technique! The Pomodoro Technique, created by
                    <a
                        href='https://francescocirillo.com/pages/francesco-cirillo'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Francesco Cirillo
                    </a>{" "}
                    is a time management technique built around making the most
                    out a set amount of time. Here are the{" "}
                    <a
                        href='https://francescocirillo.com/pages/pomodoro-technique'
                        rel='noreferrer'
                        target='_blank'
                    >
                        basics
                    </a>{" "}
                    of this technique:
                </p>
                <p>
                    <ol>
                        <li>Choose a task.</li>
                        <li>Set a timer for 25 mintues (or one pomodoro).</li>
                        <li>Work on the task until the timer goes off.</li>
                        <li>Take a short break, usually 5 minutes long.</li>
                        <li>
                            Repeat until you've finished four pomodoros, then
                            take a longer break, usually 15 minutes.
                        </li>
                        <li>Repeat the process as long as desired.</li>
                    </ol>
                </p>
                <p>
                    And that’s it! Stick with the Pomodoro Technique for a day,
                    and watch yourself burn through your to-do list in no time.
                    One of the{" "}
                    <a
                        href='https://www.themuse.com/advice/take-it-from-someone-who-hates-productivity-hacksthe-pomodoro-technique-actually-works'
                        rel='noreferrer'
                        target='_blank'
                    >
                        reasons
                    </a>{" "}
                    why the Pomodoro Technique is so successful is because it
                    instills a sense of urgency and focus within you—you know
                    that you only have 25 minutes to work on a task, encouraging
                    you to give your undivided attention to whatever you’re
                    working on. And then once a pomodoro is up, you have a short
                    break!
                </p>
                <p>
                    This is another benefit of the Pomodoro Technique: it gives
                    you time to take regularly scheduled breaks.{" "}
                    <a
                        href='https://www.facilitysolutionsplus.com/blog/2020/1/27/why-you-should-take-breaks-in-the-office'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Giving yourself a break
                    </a>{" "}
                    throughout your workday is important for your professional
                    and personal well-being, and with the breaks allocated
                    through the Pomodoro Technique, it encourages you to
                    actually pause from what you’re working on to give your mind
                    a rest.
                </p>
                <p>
                    But as with any technique or strategy, the Pomodoro
                    Technique is a tool meant to help you, not hamper your
                    success. For example, if you find that you have truly found
                    a state of flow in a pomodoro, and your timer goes off—but
                    you know that you can finish the task in 10 more
                    minutes—then go ahead and pause your break timer to finish
                    your work strongly.
                </p>
                <h2>in conclusion</h2>
                <BlogImg src={Guyworking} alt='man working' />
                <p>
                    Ultimately, fostering productivity in your office can be
                    accomplished through a variety of ways, from shutting off
                    your social media notifications to committing to a new time
                    management technique. The physical environment of your
                    office space is just as vital to cultivating productivity;
                    and here at Facility Solutions Plus, our experts can help
                    you design the perfect floor plan with just the right pieces
                    of office furniture to ensure that your office is ripe with
                    productivity.
                </p>
                <p>
                    <Italics>
                        Facility Solutions Plus is a client-centered,
                        full-service commercial office furniture dealership and
                        service provider that specializes in meeting needs,
                        solving problems and enhancing the total office
                        environment through customized, cost-effective workspace
                        solutions...and more.
                    </Italics>
                </p>
                <PostsNavigator>
                    <NewPost>
                        <h4>Newer Post</h4>
                        <Link to='/resources/02062020'>
                            Cognitive Diversity in the Office
                        </Link>
                    </NewPost>
                    <OldPost>
                        <h4>Older Post</h4>
                        <Link to='/resources/01252020'>
                            Why You Should Take Breaks in the Office
                        </Link>
                    </OldPost>
                </PostsNavigator>
            </MainContainer>
            <ContactUsHome />
        </>
    );
}
