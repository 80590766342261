import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import atlanta1 from "./us-health-vest-atlanta-1.jpg";
import atlanta2 from "./us-health-vest-atlanta-2.jpg";
import atlanta3 from "./us-health-vest-atlanta-3.jpg";

const imgs = [atlanta1, atlanta2, atlanta3];

const Container = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 4rem 0;
  h2 {
    margin-bottom: 4rem;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
`;

const FolioImg = styled.img`
  margin: 1rem 0;
  width: 20rem;
  height: auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 1rem 1rem;
    width: 25rem;
  }
`;

export default function AtlantaGA() {
  let markup = imgs.map((img) => <FolioImg src={img} alt="atlanta" />);
  return (
    <Container animateIn="fadeIn" animateOnce="true">
      <h2>Atlanta, GA</h2>
      <Content>{markup}</Content>
      <span id="savannah"></span>
    </Container>
  );
}
