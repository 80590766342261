import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import birming1 from "./Conference Room 5.jpg";
import birming2 from "./Conference Room 6.jpg";
import birming3 from "./Installation 4.jpg";
import birming4 from "./Reception 4.jpg";
import birming5 from "./Teknion Private Office.jpg";
import birming6 from "./Training Room 1.jpg";
import birming7 from "./Training Room 3.jpg";

const imgs = [
  birming1,
  birming2,
  birming3,
  birming4,
  birming5,
  birming6,
  birming7,
];

const Container = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 4rem 0;
  h2 {
    margin-bottom: 4rem;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
`;

const FolioImg = styled.img`
  margin: 1rem 0;
  width: 20rem;
  height: auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 1rem 1rem;
    width: 25rem;
  }
`;

export default function BirminghamAL() {
  let markup = imgs.map((img) => <FolioImg src={img} alt="test" />);
  return (
    <Container animateIn="fadeIn" animateOnce="true">
      <h2>Birmingham, AL</h2>
      <Content>{markup}</Content>
      <span id="decatur"></span>
    </Container>
  );
}
