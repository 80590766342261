import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Header from "../../../assets/img/pages/resources/blogs/blog03052020/header.jpeg";
import Thermostat from "../../../assets/img/pages/resources/blogs/blog03052020/thermostat.jpeg";
import Toohot from "../../../assets/img/pages/resources/blogs/blog03052020/toowarm.jpeg";
import Toocold from "../../../assets/img/pages/resources/blogs/blog03052020/toocold.jpeg";
import Fan from "../../../assets/img/pages/resources/blogs/blog03052020/fan.jpeg";
import ColdHot from "../../../assets/img/pages/resources/blogs/blog03052020/coldhot.jpeg";

import ContactUsHome from "../../../components/ContactUsHome/ContactUsHome";

const MainContainer = styled.div`
    width: 100%;
    padding: 0 30%;
    margin-top: 8rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-top: 8rem;
        padding: 0 2rem;
    }

    a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
    }

    p {
        margin-bottom: 1.5rem;
    }
    h1 {
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.5rem;
        margin-top: 6rem;
        text-transform: uppercase;
    }
`;

const BlogImg = styled.img`
    width: 100%;
    height: auto;
    margin: 4rem 0;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin: 2rem 0;
    }
`;

const Date = styled.p`
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.btnDisabled};
`;

//eslint-disable-next-line
const Bolded = styled.span`
    font-weight: 800;
`;

const Italics = styled.span`
    font-style: italic;
`;

const PostsNavigator = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10rem;
`;

const NewPost = styled.div`
    width: 50%;
    display: flex;
    flex-flow: column;
    padding: 0.5rem;
`;

const OldPost = styled(NewPost)`
    align-items: end;
    text-align: right;
`;

export default function Blog03052020() {
    return (
        <>
            <MainContainer>
                <Date>March 5th, 2020</Date>
                <h1>THE OPTIMUM OFFICE TEMPERATURE</h1>
                <BlogImg src={Header} alt='header' />
                <p>
                    Office temperature. How hot or cold an office should be is
                    an issue of perennial debate, as common a discussion topic
                    as the best places to grab lunch near the office. Do you
                    prefer a warmer office environment, and dread the Arctic
                    chill that sweeps your office? Or perhaps you enjoy the
                    cooler temperatures, and constantly find yourself having to
                    fan yourself the entire workday because of the overbearing
                    heat in your office? Regardless of where you fall in this
                    debate, read on to see what science has to say about the
                    optimum office temperature!
                </p>
                <h2>Temperature in the office</h2>
                <BlogImg src={Thermostat} alt='thermostat for ac' />
                <p>
                    What's the current landscape of the Great Temperature
                    Debate? A{" "}
                    <a
                        href='http://press.careerbuilder.com/2018-05-23-Workers-Are-Literally-Fighting-Over-Temperature-in-the-Office-Finds-New-CareerBuilder-Survey'
                        rel='noreferrer'
                        target='_blank'
                    >
                        survey conducted by CareerBuilder in 2018
                    </a>{" "}
                    found that almost half of the participants reported that
                    they find their office either too hot or too cold. Of these
                    employees, 51% said that working in an office that is too
                    cold negatively impacts their productivity, while 67% said
                    the same thing about working in an office that is too warm.
                </p>
                <p>
                    Clearly, these numbers show that people's opinions about
                    what temperature it should be in the office vary greatly. In
                    fact, the survey reports that 15% of the workers surveyed
                    have actually gotten into arguments with a coworker about
                    the office's temperature; and nearly 1 in 5 of the
                    participants admitted that they changed the office
                    temperature to make themselves more comfortable.
                </p>
                <h2>How temperature affects productivity</h2>
                <BlogImg
                    src={Toohot}
                    alt='picture of man fanning himself with stack of papers'
                />
                <p>
                    So why are people so worked up about the temperature in
                    their workplace, to the extent that they even get into
                    arguments with their coworkers and secretly alter the office
                    temperature? Well, temperature can play a huge part in how
                    comfortable you are, which has major implications for your
                    level of focus and productivity. For example,{" "}
                    <a
                        href='https://www.bbc.com/worklife/article/20160617-the-never-ending-battle-over-the-best-office-temperature'
                        rel='noreferrer'
                        target='_blank'
                    >
                        one study
                    </a>{" "}
                    found that the rate of error for a group of office clerks
                    working in a 77º F office was only 10%; but "when the
                    temperature dropped five degrees, they were half as
                    productive, more than doubling the number of mistakes."
                </p>
                <p>
                    In fact,{" "}
                    <a
                        href='https://www.bbc.com/worklife/article/20160617-the-never-ending-battle-over-the-best-office-temperature'
                        rel='noreferrer'
                        target='_blank'
                    >
                        research
                    </a>{" "}
                    suggests that the ambient temperature can even change how
                    you think. In general. it appears that warm workplaces are
                    more conducive for creative thinking. Alternatively, cooler
                    environments are more helpful for people who need to be kept
                    alert in order to complete monotonous and repetitive tasks.
                </p>
                <p>
                    Temperature can even impact our abilities to connect with
                    one another. Another{" "}
                    <a
                        href='https://www.academia.edu/232101/The_thermometer_of_social_relations_Mapping_social_proximity_on_temperature'
                        rel='noreferrer'
                        target='_blank'
                    >
                        study
                    </a>{" "}
                    found that a group of people in warmer rooms are more likely
                    to harbor warmer feelings for each other. In fact, even
                    holding a hot cup of coffee can cause you to view your
                    colleagues in a more favorable light!
                </p>
                <h2>the battle of the thermostat</h2>
                <BlogImg
                    src={Toocold}
                    alt='woman in beanie and sweater too cold'
                />
                <p>
                    Also important to the discussion about the best office
                    temperature are the findings that temperature affects the
                    different biological sexes in contrary ways.{" "}
                    <a
                        href='https://www.businessnewsdaily.com/10964-office-temperature-debate.html'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Research
                    </a>{" "}
                    has found that in general, "women feel colder than men at
                    the same temperature." This may be due to the differences in
                    fat-to-muscle ratios between men and women. Gender norms may
                    also play a role when it comes to women feeling colder,
                    given that the work skirts and blouses traditionally worn by
                    women tend to trap less heat than the slacks and suit
                    blazers historically worn by men.
                </p>
                <p>
                    But{" "}
                    <a
                        href='https://www.latimes.com/health/la-he-office-temperature-women-men-study-20190529-story.html'
                        rel='noreferrer'
                        target='_blank'
                    >
                        studies
                    </a>{" "}
                    have shown that offices have traditionally set their
                    temperatures to fit the comforts of men, coming "at the
                    expense of women's comfort and productivity."{" "}
                    <a
                        href='https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0216362'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Research conducted at the University of Southern
                        California
                    </a>{" "}
                    found that women perform better at math and verbal tasks in
                    warmer temperatures, while the reverse holds to be true for
                    men.
                </p>
                <p>
                    However, the study notes that "the increase in female
                    performance in response to higher temperature is
                    significantly larger and more precisely estimated than the
                    corresponding decrease in male performance," meaning that it
                    may be more beneficial for overall productivity if the
                    office temperature is kept at a higher level than the
                    current standards.
                </p>
                <p>
                    The final verdict on the optimum office temperature? While
                    OSHA{" "}
                    <a
                        href='https://www.osha.gov/laws-regs/standardinterpretations/2003-02-24'
                        rel='noreferrer'
                        target='_blank'
                    >
                        recommends
                    </a>
                    setting the thermostat between 67 - 76ºF,{" "}
                    <a
                        href='https://pressroom.usc.edu/warmer-temperatures-improve-womens-performance/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        science suggests
                    </a>{" "}
                    keeping the thermostat in the mid-70s.
                </p>
                <h2>Keeping your cool; or raising the heat</h2>
                <BlogImg
                    src={Fan}
                    alt='man cooling off with electric fan at desk'
                />
                <p>
                    So what happens if your office does decide to follow science
                    and raise the temperature of your office? Once again,
                    research suggests that this may be entirely too hot for you
                    if you are a man, while it may still be a tad too cold for
                    you if you are a woman! Here are some strategies to cope
                    with any office temperature:
                </p>
                <p>
                    <Italics>If you're too hot:</Italics>
                </p>
                <p>
                    Working in any office requires a certain degree of being
                    able to keep your cool, but this will be especially true if
                    you're working in a warm workplace! Having a professional
                    base layer, like a cotton button-up or blouse, that you can
                    pare down to is a good first strategy. Always having a cool
                    drink on hand to sip on throughout the workday is another!
                    And you can always ask your office manager if it'd be okay
                    to have a small personal fan at your desk.
                </p>
                <p>
                    <Italics>If you're too cold:</Italics>
                </p>
                <p>
                    On the opposite side of the spectrum, staying warm in a
                    frigid office is all about retaining heat. And once again,
                    your choice of clothing should be your first step towards
                    warming up: layers are your best friend in a cold office! If
                    that's not enough, you could consider keeping a blanket at
                    your desk to bundle up with - and a warm beverage will also
                    work wonders.
                </p>
                <h2>in conclusion</h2>
                <BlogImg src={ColdHot} alt='cold hot temperature thermostat' />
                <p>
                    In the end, science suggests that raising the office
                    thermostat to warmer than usual temperatures may boost your
                    office productivity. But no matter the temperature of your
                    office, there are many steps you can take as an individual
                    to keep yourself comfortable. And here at Facility Solutions
                    Plus, we have the expertise to help you design and create
                    the perfect office environment that will have all of your
                    employees thriving, regardless of the temperature.
                </p>
                <p>
                    <Italics>
                        Facility Solutions Plus is a client-centered,
                        full-service commercial office furniture dealership and
                        service provider that specializes in meeting needs,
                        solving problems and enhancing the total office
                        environment through customized, cost-effective workspace
                        solutions...and more.
                    </Italics>
                </p>
                <PostsNavigator>
                    <NewPost>
                        <h4>Newer Post</h4>
                        <Link to='/resources/04012020'>
                            Food, Productivity, and the Office
                        </Link>
                    </NewPost>
                    <OldPost>
                        <h4>Older Post</h4>
                        <Link to='/resources/03022020'>
                            Social Media's Place in the Office
                        </Link>
                    </OldPost>
                </PostsNavigator>
            </MainContainer>
            <ContactUsHome />
        </>
    );
}
