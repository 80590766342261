import React from "react";
import styled from "styled-components";

import ContactImg from "../../assets/img/pages/home/contactUs/contactus.svg";
import SimpleContactForm from "../../components/SimpleContactForm/SimpleContactForm";
import { FullWidthSquareBtn } from "../../components/Button/Button";

import PhoneIcon from "../../assets/img/pages/contact/phone.svg";
import ChatIcon from "../../assets/img/pages/contact/chat.svg";

const Container = styled.div`
    width: 100%;
    padding: 1rem;
`;

const MainContainer = styled(Container)`
    margin-top: 8rem;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 0 10% 0 10%;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 0 20% 0 20%;
    }
`;

const HeroContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column-reverse;
    margin-bottom: 4rem;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        flex-flow: row;
    }
`;

const HeroText = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        display: flex;
        flex-flow: column;
        justify-content: center;
    }

    h1 {
        text-transform: none;
        color: ${({ theme }) => theme.colors.tertiary};
        margin-bottom: 1rem;
    }

    p {
        font-family: montserrat;
        color: ${({ theme }) => theme.colors.tertiary};
    }
`;

const HeroImg = styled.img`
    width: 100%;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 60%;
    }
`;

const ContactContainer = styled(Container)`
    display: flex;
    justify-content: space-around;
`;

const Card = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.btnDisabled};
    border-radius: 10px;

    display: flex;
    flex-flow: column;
    h3 {
        text-transform: none;
        color: ${({ theme }) => theme.colors.tertiary};
        font-weight: 700;
        margin: 1.5rem 0;
    }
`;

const ContactCard = styled(Card)`
    padding: 1.5rem;
    margin-bottom: 4rem;
    align-items: center;
    text-align: center;
    p {
        font-family: montserrat;
        color: ${({ theme }) => theme.colors.tertiary};
        text-align: center;
    }

    a:link,
    a:visited {
        color: ${({ theme }) => theme.colors.primary};
    }
    a:hover {
        text-decoration: underline;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 45%;
    }
`;

const IconImg = styled.img`
    width: 2rem;
`;

const StyledText = styled.h4`
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
    font-size: 1.2rem;
    margin: 1.5rem 0;
`;

const OfficeContainer = styled.div`
    width: 100%;
    margin-top: 4rem;
    h2 {
        text-align: center;
        text-transform: none;
        color: ${({ theme }) => theme.colors.tertiary};
        font-size: 1.5rem;
        margin-bottom: 4rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        display: flex;
        flex-flow: column;
        align-items: center;
    }
`;

const MapCard = styled(Card)`
    width: 100%;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        flex-flow: row;
        width: 80%;
    }
`;

const MapFrame = styled.iframe`
    width: 100%;
    height: 400px;
    border: none;
    border-radius: 10px 10px 0 0;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        border-radius: 10px 0 0 10px;
        width: 70%;
    }
`;

const MapText = styled.div`
    padding: 1.5rem;
    h3 {
        font-size: 1.4rem;
        font-weight: 600;
        text-align: left;
        margin-bottom: 1rem;
    }
    p {
        color: ${({ theme }) => theme.colors.tertiary};
        font-family: montserrat;
        font-weight: 500;
        font-size: 1.1rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        margin-left: 2rem;
    }
`;

export default function contact() {
    return (
        <>
            <MainContainer>
                <HeroContainer>
                    <HeroText>
                        <h1>Get in touch</h1>
                        <p>
                            Want to get in touch? We'd love to hear from you!
                            Here's how to reach us...
                        </p>
                    </HeroText>
                    <HeroImg src={ContactImg} alt='Phone illustration' />
                </HeroContainer>
                <ContactContainer>
                    <ContactCard>
                        <IconImg src={PhoneIcon} alt='phone icon' />
                        <h3>Talk to Sales</h3>
                        <p>
                            Interested in our products? Just pick up the phone
                            to chat with a member of our sales team!
                        </p>
                        <StyledText>
                            <a
                                href='https://www.google.com/search?q=facility+solutions+plsu&oq=facility+solutions+plsu&aqs=chrome..69i57j0i67i433j35i39j69i61l3j69i60l2.2063j0j7&sourceid=chrome&ie=UTF-8#'
                                rel='noreferrer'
                                target='_blank'
                            >
                                1 (713) 497 4495
                            </a>
                        </StyledText>
                    </ContactCard>
                    <ContactCard>
                        <IconImg src={ChatIcon} alt='chat icon' />
                        <h3>Email us your inquiries</h3>
                        <p>
                            Sometimes you don't have time for a phone call. Send
                            us an email instead!
                        </p>
                        <a
                            href='mailto:info@facilitysolutionsplus.com'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <FullWidthSquareBtn>Email Us</FullWidthSquareBtn>
                        </a>
                    </ContactCard>
                </ContactContainer>
                <OfficeContainer>
                    <h2>Connect with our office</h2>

                    <MapCard>
                        <MapFrame src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3462.4714504783474!2d-95.76146058443638!3d29.792921281974593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640d91702c00001%3A0x4c971760211ba2fd!2sFacility%20Solutions%20Plus!5e0!3m2!1sen!2sus!4v1655393663240!5m2!1sen!2sus'></MapFrame>
                        <MapText>
                            <h3>Main office</h3>
                            <p>
                                22370 Merchants Way
                                <br />
                                Suite 180
                                <br />
                                Katy, TX 77449
                            </p>
                            <h3>Phone</h3>
                            <p>1 (713) 497 4495</p>
                        </MapText>
                    </MapCard>
                </OfficeContainer>
            </MainContainer>
            <br />
            <br />
            <SimpleContactForm />
        </>
    );
}
