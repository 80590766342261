/* eslint-disable */
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const OfficeContainer = styled.div`
    width: 80vw;
    display: flex;
    flex-flow: column;
    margin-bottom: 10rem;

    h2 {
        text-align: center;
        margin-bottom: 4rem;
    }
`;

const OfficeContent = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        flex-flow: row;
    }
`;

const TexasContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 2rem;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 40%;
    }
`;

const GeorgiaContainer = styled.div`
    display: flex;
    align-items: center;
    flex-flow: column;
    margin-bottom: 2rem;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 40%;
    }
`;

const Content = styled.div`
    width: 100%;
    align-items: center;
    margin-bottom: 2rem;
    text-align: center;
    p {
        text-align: center;
        font-weight: 300;
        font-size: 1rem;
    }
`;

const StateH3 = styled.h3`
    color: ${({ theme }) => theme.colors.primary};
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 2rem;
`;

// const MapContainer = styled.div`
//     @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
//         display: none;
//     }
//     @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
//         display: box;
//     }
// `;

const VendorList = styled(Link)`
    font-family: "Montserrat", sans-serif;
    color: ${({ theme }) => theme.colors.accent};
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
`;

export default function OurOfficeHome() {
    return (
        <OfficeContainer>
            <h2>Our Office Locations</h2>
            <OfficeContent>
                <TexasContainer>
                    <Content>
                        <StateH3>Texas</StateH3>
                        <VendorList to='/vendors/texas'>Vendor List</VendorList>
                        <p>22370 Merchants Way</p>
                        <p>#180</p>
                        <p>Katy, TX 77449</p>
                        <p>Phone: (713) 497-4495</p>
                    </Content>
                    {/* <MapContainer>
                        <iframe
                            width='360'
                            height='360'
                            style={{ border: 0 }}
                            loading='lazy'
                            allowfullscreen
                            src='https://www.google.com/maps/embed/v1/place?q=22370+Merchant+Way&key=AIzaSyBNbTBm2pSEpqPiH_xkLl1K8hkxdZbYCXo'
                        ></iframe>
                    </MapContainer> */}
                </TexasContainer>
                <GeorgiaContainer>
                    <Content>
                        <StateH3>Georgia</StateH3>
                        <VendorList to='/vendors/georgia'>
                            Vendor List
                        </VendorList>
                        <p>227 Fox Run Place</p>
                        <p>Jasper, GA 30143</p>
                        <p>Phone: (713) 497-4495</p>
                    </Content>
                    {/* <MapContainer>
                        <iframe
                            width='360'
                            height='360'
                            style={{ border: 0 }}
                            loading='lazy'
                            allowfullscreen
                            src='https://www.google.com/maps/embed/v1/place?q=229%20Fox%20Run%20Place%20Unit%20B%2C%20Jasper%2C%20GA%2C%20USA&key=AIzaSyBNbTBm2pSEpqPiH_xkLl1K8hkxdZbYCXo'
                        ></iframe>
                    </MapContainer> */}
                </GeorgiaContainer>
            </OfficeContent>
        </OfficeContainer>
    );
}
