import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import sugarland1 from "./Geico 1.jpg";
import sugarland2 from "./Geico 2.jpg";
import sugarland3 from "./Geico 3.jpg";
import sugarland4 from "./Geico 4.jpg";
import sugarland5 from "./Geico 5.jpg";
import sugarland6 from "./Geico 6.jpg";
import sugarland7 from "./Geico 7.jpg";
import sugarland8 from "./Geico 8.jpg";
import sugarland9 from "./Geico 9.jpg";
import sugarland10 from "./Geico 10.jpg";

const imgs = [
  sugarland1,
  sugarland2,
  sugarland3,
  sugarland4,
  sugarland5,
  sugarland6,
  sugarland7,
  sugarland8,
  sugarland9,
  sugarland10,
];

const Container = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 4rem 0;
  h2 {
    margin-bottom: 4rem;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
`;

const FolioImg = styled.img`
  margin: 1rem 0;
  width: 20rem;
  height: auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 1rem 1rem;
    width: 25rem;
  }
`;

export default function SugarlandTX() {
  let markup = imgs.map((img) => <FolioImg src={img} alt="test" />);
  return (
    <Container animateIn="fadeIn" animateOnce="true">
      <h2>Sugar Land, TX</h2>
      <Content>{markup}</Content>
    </Container>
  );
}
