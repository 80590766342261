import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

import callCenter from "../../assets/img/pages/home/markets/call-center-global.jpg";
import civic from "../../assets/img/pages/home/markets/civic-KI.jpg";
import corporate from "../../assets/img/pages/home/markets/corporate-allur.jpg";
import education from "../../assets/img/pages/home/markets/education-ki.jpg";
import entertainment from "../../assets/img/pages/home/markets/entertainment-hospitality.jpg";
import healthcare from "../../assets/img/pages/home/markets/healthcare-global.jpg";
import industrial from "../../assets/img/pages/home/markets/industrial-safco.jpg";
import science from "../../assets/img/pages/home/markets/science-technology-safco.jpg";

const MarketExpContainer = styled(ScrollAnimation)`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-bottom: 20rem;
    h2 {
        margin-bottom: 4rem;
        text-align: center;
    }
`;

const Content = styled.div`
    display: flex;
    flex-flow: column;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium});
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;

const HoverContent = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    transition: 0.5s ease;
    opacity: 1;
    height: 92%;
    width: 15rem;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
        width: 15rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        opacity: 0;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        width: 22rem;
    }
`;

const Desc = styled.p`
    position: absolute;
    z-index: 10;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: center;
`;

const ProdImg = styled(ScrollAnimation)`
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        opacity: 0.4;
        width: 22rem;
        margin: 0.5rem;
        display: block;
        transition: 0.5s ease;
        backface-visibility: hidden;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
        img {
            width: 15rem;
        }
        p {
            font-size: 1.5rem;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        img {
            width: 15rem;
            opacity: 1;
        }
        p {
            font-size: 1.5rem;
        }

        &:hover img {
            opacity: 0.2;
        }

        &: hover ${HoverContent} {
            opacity: 1;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        img {
            width: 22rem;
        }
        p {
            font-size: 2rem;
        }
    }
`;

export default function MarketExpertise() {
    return (
        <MarketExpContainer animateIn='fadeIn' animateOnce='true'>
            <h2>MARKET EXPERTISE</h2>
            <Content>
                <Row>
                    <Link to='/markets'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Call Center</Desc>
                            </HoverContent>
                            <img src={callCenter} alt='call center' />
                        </ProdImg>
                    </Link>
                    <Link to='/markets'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Civic</Desc>
                            </HoverContent>
                            <img src={civic} alt='civic' />
                        </ProdImg>
                    </Link>
                    <Link to='/markets'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Corporate</Desc>
                            </HoverContent>
                            <img src={corporate} alt='corporate' />
                        </ProdImg>
                    </Link>
                    <Link to='/markets'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Education</Desc>
                            </HoverContent>
                            <img src={education} alt='education' />
                        </ProdImg>
                    </Link>
                    <Link to='/markets'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Entertainment & Hospitality</Desc>
                            </HoverContent>
                            <img
                                src={entertainment}
                                alt='entertainment and hospitality'
                            />
                        </ProdImg>
                    </Link>
                    <Link to='/markets'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Healthcare</Desc>
                            </HoverContent>
                            <img src={healthcare} alt='healthcare' />
                        </ProdImg>
                    </Link>
                    <Link to='/markets'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Industrial</Desc>
                            </HoverContent>
                            <img src={industrial} alt='industrial' />
                        </ProdImg>
                    </Link>
                    <Link to='/markets'>
                        <ProdImg animateIn='fadeIn' animateOnce='true'>
                            <HoverContent>
                                <Desc>Science & Technology</Desc>
                            </HoverContent>
                            <img src={science} alt='science and technology' />
                        </ProdImg>
                    </Link>
                </Row>
            </Content>
        </MarketExpContainer>
    );
}
