/* eslint-disable */
import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";

import "dotenv";

// COMPONENTS
import { FullWidthSquareBtn } from "../Button/Button";

// import contactImg from "../../assets/img/pages/home/contactUs/contactus.svg";

const ContactUsHomeContainer = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-flow: column-reverse;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
  }
`;

const ScrollIn = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const ContactForm = styled.div`
  width: 60%;
  h2 {
    text-align: center;
    margin-bottom: 4rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 50%;

    h2 {
      text-align: left;
    }
  }
`;

// const ContactImage = styled.img`
//     width: 90%;
//     height: auto;
// `;

const InputContainer = styled.form`
  width: 100%;
  h4 {
    font-weight: 700;

    margin-top: 1rem;
  }
`;

const Input = styled.input`
  height: 3rem;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d5dce3;
  font-family: "Montserrat", sans-serif;
  color: #8893a2;
  ::placeholder {
    color: #8893a2;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 8rem;
  padding: 1rem;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d5dce3;
  font-family: "Montserrat", sans-serif;
  color: #8893a2;
  ::placeholder {
    color: #8893a2;
  }
`;

const ErrorSpan = styled.span`
  color: ${({ theme }) => theme.colors.toast.error};
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
`;

export default function ContactUsHome() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let templateParams = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      message: data.message,
    };

    emailjs
      .send(
        `service_q9888qx`,
        `template_i7vurrf`,
        templateParams,
        `user_YKyESq57GITVJ01L57iaE`
      )
      .then(
        (res) => {
          console.log("SUCCESS", res.status, res.text);
          reset();
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <ContactUsHomeContainer animateIn="fadeIn" animateOnce="true">
      <ScrollIn animateIn="fadeIn" animateOnce="true">
        <ContactForm>
          <h2>Drop us a line</h2>
          <InputContainer onSubmit={handleSubmit(onSubmit)}>
            <h4>Name*</h4>
            <Input
              type="text"
              placeholder="What's your name?"
              {...register("name", {
                required: true,
                maxLength: {
                  value: 30,
                  message: "This input must not exceed 3 characters.",
                },
              })}
            />
            {errors.name && (
              <ErrorSpan>
                This field is required. Must not exceed 30 characters.
              </ErrorSpan>
            )}
            <h4>Phone number</h4>
            <Input
              type="text"
              placeholder="Phone number?"
              {...register("phone", {
                pattern:
                  /^s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
              })}
            />
            {errors.phone && (
              <ErrorSpan>Please enter a valid US phone number.</ErrorSpan>
            )}
            <h4>Email address*</h4>
            <Input
              type="text"
              name="email"
              placeholder="Your email address?"
              {...register("email", {
                required: true,
                pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            {errors.email && (
              <ErrorSpan>
                This field is required. Please enter a valid email.
              </ErrorSpan>
            )}
            <h4>Message*</h4>
            <TextArea
              type="text"
              name="message"
              placeholder="Write your message to the team here."
              {...register("message", { required: true })}
            ></TextArea>
            {errors.message && <ErrorSpan>This field is required.</ErrorSpan>}
            <FullWidthSquareBtn type="submit">Submit</FullWidthSquareBtn>
          </InputContainer>
        </ContactForm>
      </ScrollIn>
    </ContactUsHomeContainer>
  );
}
