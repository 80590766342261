import React from "react";
import styled from "styled-components";
import Accordion from "../../components/Accordion/Accordion";

import hero from "../../assets/img/pages/process/ourprocess.png";
import idea from "../../assets/img/pages/process/fsplus-idea.png";
import consultation from "../../assets/img/pages/process/fsplus-project-consultation.png";
import selection from "../../assets/img/pages/process/fsplus-product-selection.png";
import planning from "../../assets/img/pages/process/fsplus-space-planning.png";
import coordination from "../../assets/img/pages/process/fsplus-project-coordination.png";
import delivery from "../../assets/img/pages/process/fsplus-delivery-installation.png";
import ContactUsHome from "../../components/ContactUsHome/ContactUsHome";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  h1 {
    margin-bottom: 4rem;
  }
  color: ${({ theme }) => theme.colors.tertiary};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    justify-content: center;
  }
`;

const HeroImg = styled.div`
  background: url(${hero});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f4eee2;
  width: 100%;
  height: 50vh;
  margin-bottom: 8rem;
`;

const Content = styled.div`
  width: 80%;
  display: flex;
  flex-flow: column;
  margin-bottom: 8rem;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 60%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 60%;
  }
`;

const AccordionContainer = styled.div`
  width: 100%;
  margin-top: 4rem;
`;

export default function process() {
  return (
    <Container>
      <HeroImg />
      <h1>Our Process</h1>
      <Content>
        <p>
          From project inception to completion, our seven step process will help
          you choose the best products for your working environment, all while
          staying within your specified budget.
        </p>
        <AccordionContainer>
          <Accordion
            title="Step 1:"
            subtitle="Client Idea"
            src={idea}
            alt="Client Idea"
            content="All projects start with an idea that you come up with. So congratulations! You’ve completed Step 1 of the Facility Solutions Plus Process!"
            fill="#202020"
          />
          <Accordion
            title="Step 2:"
            subtitle="Project Consultation"
            src={consultation}
            alt="Project Consultation"
            content="After our initial contact, you’ll meet with your very own dedicated Facility Solutions Plus representative who will be with you through every step of the process. Their goal during this step is to learn everything they can of your expectations of your new workspace and help you find the best product and furniture solutions for your unique situation!"
            fill="#202020"
          />
          <Accordion
            title="Step 3:"
            subtitle="Product Selection"
            src={selection}
            alt="Product Selection"
            content="Once we’ve identified your vision for your workspace, your representative will make recommendations and furniture selections based on your budget and project needs. This is often the fun part of the process as you’re welcome to view (and try out) a large range of products at our showroom!"
            fill="#202020"
          />
          <Accordion
            title="Step 4:"
            subtitle="Space Planning"
            src={planning}
            alt="Space Planning"
            content="After we’ve helped you choose your furniture, our design team will work diligently to create a 2D floor plan of your workspace with all of your furniture inserted! This step helps us ensure that we have properly visualized  your office furniture needs while staying compliant to state and federal regulations!"
            fill="#202020"
          />
          <Accordion
            title="Step 5:"
            subtitle="Project Coordination"
            src={coordination}
            alt="Project Coordination"
            content="After we have confirmed all of your product choices, we’ll host a number of pre-installation meetings to work with the necessary departments and other clientele to ensure a smooth delivery and installation date."
            fill="#202020"
          />
          <Accordion
            title="Step 6:"
            subtitle="Delivery & Installation"
            src={delivery}
            alt="Delivery & Installation"
            content="Once the day(s) of delivery and installation arrive, our team of experienced and professional installers will coordinate and oversee all of the necessary logistics to ensure that your product is delivered intact and undamaged to your desired destination."
            fill="#202020"
          />
        </AccordionContainer>
      </Content>
      <ContactUsHome />
    </Container>
  );
}
