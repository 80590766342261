import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Header from "../../../assets/img/pages/resources/blogs/blog02152020/header.jpeg";
import Teamwork from "../../../assets/img/pages/resources/blogs/blog02152020/teamwork.jpeg";
import Officeteam from "../../../assets/img/pages/resources/blogs/blog02152020/officeteam.jpeg";
import Collaborative from "../../../assets/img/pages/resources/blogs/blog02152020/collaborative.jpeg";
import Cheer from "../../../assets/img/pages/resources/blogs/blog02152020/cheer.jpeg";

import ContactUsHome from "../../../components/ContactUsHome/ContactUsHome";

const MainContainer = styled.div`
    width: 100%;
    padding: 0 30%;
    margin-top: 8rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-top: 8rem;
        padding: 0 2rem;
    }

    a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
    }

    p {
        margin-bottom: 1.5rem;
    }
    h1 {
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.5rem;
        margin-top: 6rem;
        text-transform: uppercase;
    }
`;

const BlogImg = styled.img`
    width: 100%;
    height: auto;
    margin: 4rem 0;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin: 2rem 0;
    }
`;

const Date = styled.p`
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.btnDisabled};
`;

//eslint-disable-next-line
const Bolded = styled.span`
    font-weight: 800;
`;

const Italics = styled.span`
    font-style: italic;
`;

const PostsNavigator = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10rem;
`;

const NewPost = styled.div`
    width: 50%;
    display: flex;
    flex-flow: column;
`;

const OldPost = styled(NewPost)`
    align-items: end;
    text-align: right;
`;

export default function Blog02152020() {
    return (
        <>
            <MainContainer>
                <Date>February 15th, 2020</Date>
                <h1>Strengthening Teamwork in the office</h1>
                <BlogImg src={Header} alt='header' />
                <p>
                    Teamwork has{" "}
                    <a
                        href='https://www.scientificamerican.com/article/humans-brain-power-origins/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        played
                    </a>{" "}
                    a crucial role in the development of our species, ever since
                    our days as hunters and gatherers. Thanks to our ability to
                    cooperate with one another in the pursuit of a shared goal,
                    we have been able to adapt to a range of habitats and invent
                    tools that have radically transformed life on Earth, from
                    rudimentary spears to quantum computing. And in our modern
                    lives, teamwork remains a critical part of any group’s
                    success; read on to learn more about teamwork in the office!
                </p>
                <h2>When is teamwork important in the office?</h2>
                <BlogImg src={Teamwork} alt='people working together' />
                <p>
                    We’ve all heard the old adages ad nauseum—there is no “I” in
                    team, teamwork makes the dreamwork, etc.—but why exactly do
                    these statements ring true? To understand why teamwork is
                    important, it is useful to first understand when teamwork is
                    effective.
                </p>
                <p>
                    If you’re the leader in your office, building teamwork{" "}
                    <a
                        href='https://hbr.org/2018/08/when-is-teamwork-really-necessary'
                        rel='noreferrer'
                        target='_blank'
                    >
                        may be necessary
                    </a>{" "}
                    if you’re managing several individuals working on isolated
                    tasks. For example, attempting to cultivate teamwork with
                    your employees may be detrimental to overall productivity if
                    you have one employee focused on proofreading business copy,
                    another employee taking inventory of your products, and
                    still another employee revamping your company’s website. In
                    these instances, each employee is working on a specific
                    task, and their productivity will most likely not improve if
                    you interrupt their workflow to hold a team meeting to
                    discuss things like goal setting.
                </p>
                <p>
                    Alternatively, if you’re managing a group of employees who
                    are working jointly on a single project—say, you and your
                    sales representatives are putting together a big sales
                    proposal—then promoting teamwork and camaraderie amongst
                    your employees would not only be useful, but highly
                    beneficial as well!
                </p>
                <h2>the benefits of teamwork</h2>
                <BlogImg src={Officeteam} alt='people working together' />
                <p>
                    Now that we have a better idea of when strengthening
                    teamwork is important in the office, we can explore the
                    various benefits of teamwork. Here are a{" "}
                    <a
                        href='https://www.forbes.com/sites/quora/2013/01/23/why-and-where-is-teamwork-important/#7e7881f0287a'
                        rel='noreferrer'
                        target='_blank'
                    >
                        number
                    </a>{" "}
                    of{" "}
                    <a
                        href='https://www.potential.com/articles/5-important-reasons-why-teamwork-matters/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        benefits
                    </a>
                    :
                    <ul>
                        <li>
                            <Bolded>
                                Teamwork is effective for creative
                                problem-solving:
                            </Bolded>{" "}
                            If your company is faced with a complex problem,
                            then it would be difficult for one person to figure
                            out the most effective solution. But if you tackle
                            this problem with your colleagues as a team, then
                            you and your team-members would be able to bounce
                            ideas off of each other and brainstorm the optimal
                            course of action.
                        </li>
                        <li>
                            <Bolded>
                                Teamwork brings different perspectives to the
                                table:
                            </Bolded>{" "}
                            In the same vein as the first point, working in a
                            team widens the overall perspective brought to a
                            specific project. For instance, figuring out how to
                            frame your services for a specific demographic may
                            be difficult if you share little with the group in
                            question; but in a team, there may be someone who
                            relates with the target demographic, allowing your
                            entire group to better understand the needs of the
                            people you’re trying to service.
                        </li>
                        <li>
                            <Bolded>
                                Teamwork can improve productivity and
                                efficiency:
                            </Bolded>{" "}
                            By working in a team, you are able to divy up the
                            workload that may appear insurmountable to an
                            individual. Splitting the work up amongst your team
                            members breaks down burdensome tasks into more
                            manageable pieces, allowing your overall goals to be
                            accomplished in a more productive and efficient
                            manner.
                        </li>
                        <li>
                            <Bolded>
                                Teamwork fosters synergy and camararderie:
                            </Bolded>{" "}
                            Bolstering the sense of teamwork in your office can
                            help foster synergy and camaraderie amongst
                            employees. By highlighting the shared goals and
                            values of your entire team, your employees are
                            likely to feel a greater sense of fulfillment,
                            purpose, and accomplishment in the work they’re
                            doing. And an office filled with motivated employees
                            results in increased success for your business
                            overall!
                        </li>
                    </ul>
                    Clearly, a driven, passionate, and effective team can have
                    many benefits for your employees, your business, and your
                    customers. But how can you better cultivate a sense of
                    teamwork in your own office?
                </p>
                <h2>How to strengthen teamwork in the office</h2>
                <BlogImg src={Collaborative} alt='group of coworkers' />
                <p>
                    While the benefits of teamwork in the office are plentiful,
                    this isn’t to say that building a strong team is an easy
                    feat. After all, bringing in vastly different people in
                    pursuit of a shared goal may result in clashing
                    personalities and infighting that can hamper productivity.
                    <a
                        href='https://smallbusiness.chron.com/five-ways-improve-teamwork-workplace-13766.html'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Here
                    </a>{" "}
                    are some{" "}
                    <a
                        href='https://www.coburgbanks.co.uk/blog/staff-retention/ways-to-encourage-teamwork/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        tips
                    </a>{" "}
                    to prevent such situations from happening by strengthening
                    teamwork in your office, ensuring that you and your team
                    operate at your best:
                    <ul>
                        <li>
                            <Bolded>
                                Identify clear, actionable objectives:
                            </Bolded>{" "}
                            Establishing concrete, accomplishable goals for your
                            team gets all of your team-members on the same page
                            regarding your shared objectives. And when you hit
                            your incremental targets, the confidence and
                            momentum of your team will only grow!
                        </li>
                        <li>
                            <Bolded>Outline individual roles:</Bolded>{" "}
                            Clarifying each team-member’s individual duties and
                            responsibilities ensures that your team covers all
                            of its bases when tackling a big project. It also
                            gives your team-members a greater sense of purpose
                            and fulfillment, since they know their unique role
                            in helping the team achieve its grander goals.
                        </li>
                        <li>
                            <Bolded>Celebrate success:</Bolded> When your team
                            clears a major hurdle with great success, it’s
                            important to acknowledge and celebrate your shared
                            accomplishment! Doing so helps build morale within
                            your team, motivating you and your team to keep
                            delivering at a high level.
                        </li>
                        <li>
                            <Bolded>Resolve disputes:</Bolded> Internal
                            disagreements will inevitably arise in a team. When
                            these situations do arise, it’s important to
                            de-escalate the tension, outline the problem that is
                            causing the dispute between the conflicting parties,
                            and come to a solution that takes into account the
                            needs of both parties. After all, cohesion and
                            solidarity is key to a successful team!
                        </li>
                        <li>
                            <Bolded>Encourage social activities:</Bolded> While
                            some people may roll their eyes at the idea of an
                            office Happy Hour after work, social activities
                            allow your team-members to get to know one another
                            more. This builds camaraderie within your team and
                            makes your team-members more comfortable with
                            another, strengthening the sense of teamwork and
                            unity overall.
                        </li>
                    </ul>
                </p>
                <h2>In conclusion</h2>
                <BlogImg src={Cheer} alt='people cheering' />
                <p>
                    Ultimately, strengthening the sense of teamwork in your
                    office is important; it allows your business to accomplish
                    big projects and clear major hurdles. It also makes the
                    office environment a much more enjoyable place to be! And
                    here at Facility Solutions Plus, we have the knowledge,
                    expertise, and products that will bolster your team in any
                    office environment.
                </p>
                <p>
                    <Italics>
                        Facility Solutions Plus is a client-centered,
                        full-service commercial office furniture dealership and
                        service provider that specializes in meeting needs,
                        solving problems and enhancing the total office
                        environment through customized, cost-effective workspace
                        solutions...and more.
                    </Italics>
                </p>
                <PostsNavigator>
                    <NewPost>
                        <h4>Newer Post</h4>
                        <Link to='/resources/02242020'>
                            Buyer's Guide: Reception Desk
                        </Link>
                    </NewPost>
                    <OldPost>
                        <h4>Older Post</h4>
                        <Link to='/resources/02062020'>
                            Cognitive Diversity in the Office
                        </Link>
                    </OldPost>
                </PostsNavigator>
            </MainContainer>
            <ContactUsHome />
        </>
    );
}
