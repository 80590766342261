import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "Lora", serif;
    }
    
    * {
        box-sizing: border-box;
        margin: 0;
    }

    body {
        font-family: "Lora", serif;
        font-weight: 500;
        margin-top: 4.2rem;
        padding: 0;
        line-height: 1.8rem;
    }

    h2 {
        font-size: 2rem;
    }
    
    h1, h2, h3, h4, h5 {
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        
        letter-spacing: 1px;
        line-height: 2.3rem;
    }

    a {
        text-decoration: none;
    }

`;

export default GlobalStyle;
