import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Header from "../../../assets/img/pages/resources/blogs/blog04012020/header.jpeg";
import Foodbrain from "../../../assets/img/pages/resources/blogs/blog04012020/foodbrain.jpeg";
import Foodspread from "../../../assets/img/pages/resources/blogs/blog04012020/foodspread.jpeg";
import Hotdog from "../../../assets/img/pages/resources/blogs/blog04012020/hotdog.jpeg";
import Salad from "../../../assets/img/pages/resources/blogs/blog04012020/salad.jpeg";

import ContactUsHome from "../../../components/ContactUsHome/ContactUsHome";

const MainContainer = styled.div`
    width: 100%;
    padding: 0 30%;
    margin-top: 8rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-top: 8rem;
        padding: 0 2rem;
    }

    a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
    }

    p {
        margin-bottom: 1.5rem;
    }
    h1 {
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.5rem;
        margin-top: 6rem;
    }
`;

const BlogImg = styled.img`
    width: 100%;
    height: auto;
    margin: 4rem 0;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin: 2rem 0;
    }
`;

const Date = styled.p`
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.btnDisabled};
`;

const Bolded = styled.span`
    font-weight: 800;
`;

const Italics = styled.span`
    font-style: italic;
`;

const PostsNavigator = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10rem;
`;

const NewPost = styled.div`
    width: 50%;
    display: flex;
    flex-flow: column;
    padding: 0.5rem;
`;

const OldPost = styled(NewPost)`
    align-items: end;
    text-align: right;
`;

export default function Blog05042020() {
    return (
        <>
            <MainContainer>
                <Date>April 1st, 2020</Date>
                <h1>FOOD, PRODUCTIVITY, AND THE OFFICE</h1>
                <BlogImg src={Header} alt='header' />
                <p>
                    One of life's greatest pleasures is sitting down to a hearty
                    meal of your favorite recipe. Whether it's a grass-fed steak
                    with a generous side of mashed potatoes, or a colorful
                    quinoa bowl packed with tons of superfood, eating something
                    we enjoy can always improve our mood. But food can also
                    improve our productivity! Read on to learn mroe about how
                    food can increase your productivity, especially in the
                    context of the office.
                </p>
                <h2>FOOD AND COGNITIVE PERFORMANCE</h2>
                <BlogImg src={Foodbrain} alt='brain made up of veggies' />
                <p>
                    We've all heard the phrase "brain food" before - but how
                    does food actually influence our mental performance? It all
                    starts with how our body actually breaks down food.{" "}
                    <a href='Science' rel='noreferrer' target='_blank'>
                        Science
                    </a>{" "}
                    lets us know that "just about everything we eat is converted
                    by our body into glucose, which provides the energy our
                    brains need to stay alert." In other words, glucose is the
                    blood sugar - the fuel - that keeps our bodies running.
                </p>
                <p>
                    And when it comes to the amount of glucose entering your
                    bloodstream, it's important to maintain your glucose levels
                    at a consistent level throughout the day.{" "}
                    <a
                        href='https://thriveglobal.com/stories/your-productivity-levels-depend-on-what-you-eat/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        After all,
                    </a>{" "}
                    "too much or too little glucose will leave you unfocused,
                    with poor energy levels, and low brain performance.
                </p>
                <p>
                    But{" "}
                    <a
                        href='https://thriveglobal.com/stories/your-productivity-levels-depend-on-what-you-eat/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        science also lets us know
                    </a>{" "}
                    that our bodies do not break down all food into glucose the
                    same way. For example, our bodies break down sugary treats
                    like a brownie or muffin into glucose very quickly, causing
                    us to feel a spike of energy - the infamous sugar rush, if
                    you will. But after about a half hour, our bodies will have
                    extracted all the glucose it can from these treats, causing
                    our blood sugar levels to drop; our energy levels - and
                    subsequently productivity levels - would also plummet.
                </p>
                <p>
                    <a
                        href='https://hbr.org/2014/10/what-you-eat-affects-your-productivity'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Alternatively,
                    </a>{" "}
                    certain foods with high fat content, like a steak or a
                    cheeseburger, "provide more sustained energy, but require
                    our digestive system to work harder, reducing oxygen levels
                    in the brain and making us groggy." While these foods may
                    give us more overall energy, the increased work required
                    from our digestive system comes at the cost of the
                    performance of our mental capabilities.
                </p>
                <h2>THE BEST FOOD FOR PRODUCTIVITY</h2>
                <BlogImg src={Foodspread} alt='an assortment of veggies' />
                <p>
                    So what foods should we be eating if we wanted to improve
                    our productivity levels in the office? It may be no surprise
                    to hear, but{" "}
                    <a
                        href='https://hbr.org/2014/10/what-you-eat-affects-your-productivity'
                        rel='noreferrer'
                        target='_blank'
                    >
                        fruit and vegetables
                    </a>{" "}
                    are some of the best things to munch on in the office if you
                    want to be working at your best.{" "}
                    <a
                        href='https://thriveglobal.com/stories/your-productivity-levels-depend-on-what-you-eat/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        These foods
                    </a>{" "}
                    release glucose at a steadier pace and are light on our
                    digestive system, giving us an ample supply of energy
                    throughout the day without coming at the cost of our
                    cognitive abilities.
                </p>
                <p>
                    <a
                        href='https://hbr.org/2014/10/what-you-eat-affects-your-productivity'
                        rel='noreferrer'
                        target='_blank'
                    >
                        On top of that,
                    </a>{" "}
                    fruit and vegetables "contain vital nutrients that foster
                    the production of dopamine, a neurotransmitter that plays a
                    key role in the experience of curiosity, motivation, and
                    engagement." Furthermore, "they also provide antioxidants
                    that minimize bodily inflammation, improve memory, and
                    enhance mood." It's no wonder then that a{" "}
                    <a
                        href='https://www.ncbi.nlm.nih.gov/pubmed/25080035'
                        rel='noreferrer'
                        target='_blank'
                    >
                        study
                    </a>{" "}
                    found that people who eat a lot of fruit and vegetables tend
                    to have a greater sense of "well-being, more intense
                    feelings of curiosity, and greater creativity!"
                </p>
                <p>
                    <a
                        href='https://www.eatthis.com/foods-increase-productivity/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Nuts and seeds
                    </a>{" "}
                    are also great choices for office snacks. They're chock full
                    of healthy fats that improve overall health, which also
                    "slow the body's digestion of sugar and carbs - keeping your
                    blood sugar levels nice and steady, your focus in line, and
                    your willpower up throughout the day."
                </p>
                <h2>FOOD ETIQUETTE IN THE OFFICE</h2>
                <BlogImg src={Hotdog} alt='food etiquette' />
                <p>
                    Now that you have an idea of the best foods to eat in the
                    office to increase your productivity levels, it's worth
                    exploring some best practices when it comes to food
                    etiquette in the office. After all, the way you positively
                    (or negatively!) impact your coworkers affects everyone's
                    productivity, and proper food etiquette in the office is
                    essential to keep any workspace running its best. Here are
                    some things to think about to be mindful of your food
                    choices and habits:
                    <ul>
                        <li>
                            <Bolded>Keep the smelly food home:</Bolded> Foods
                            with particularly strong odors, like fish, can
                            stench up the entire office, especially if heated up
                            in the microwave. Do your coworkers a favor and
                            avoid bringing these types of food in for lunch. But
                            if you must bring a particularly pungent type of
                            food to work, it's best to eat it cold, like in a
                            sandwich or a salad.
                        </li>
                        <li>
                            <Bolded>Clean up after yourself:</Bolded> The
                            kitchen and break room are shared spaces in the
                            office. Consequently, if you want to be a
                            considerate colleague, it's important to keep these
                            spaces tidy, especially if you left a mess yourself!
                            Be sure to wash any office dishes you use, and to
                            utilize any cleaning supplies the office has to
                            sweep or mop up a mess of yours.
                        </li>
                        <li>
                            <Bolded>
                                Don't leave food in the refrigerator for more
                                than a week:
                            </Bolded>{" "}
                            It's easy for food to be forgotten in the fridge,
                            destined to a fate of mold accumulation. And in the
                            office, the problem can be especially bad, as it can
                            get difficult to pinpoint who exactly abandoned the
                            unlabeled leftovers that have been sitting in the
                            fridge for a month. Avoiding this issue is easy:
                            simply implement an empty fridge policy every Friday
                            afternoon! That way, everyone is encouraged to eat
                            any food they store in the fridge by the end of the
                            week, and the office fridge remains free of any
                            moldy tubs of tupperware.
                        </li>
                        <li>
                            <Bolded>Keep the crunching to a minimum:</Bolded> If
                            you do bring a superfood snack of pecans or almonds
                            to work, it may be best to eat these in your office
                            break room. THis is especially relevant if you work
                            in an open office layout in close proximity to each
                            other - after all, it may be difficult for your
                            colleague to concentrate if all they hear is you
                            crunching noisily away on your favorite snack!
                        </li>
                    </ul>
                </p>
                <h2>FOOD FOR THOUGHT</h2>
                <BlogImg src={Salad} alt='salad' />
                <p>
                    In the end, food plays a critical role in our everyday
                    lives. And with a little forethought, picking the right food
                    to eat in the office place (and how you eat it!) can greatly
                    improve your productivity, performance, and well-being in
                    the office. And as mentioned before, the kitchen and break
                    room are important spaces for you to enjoy whatever food you
                    bring to work. Here at Facility Solutions Plus, we can help
                    you design your kitchen and break room into the perfect
                    place for you and your colleagues to enjoy whatever
                    delicious food you bring to work.
                </p>
                <p>
                    <Italics>
                        Facility Solutions Plus is a client-centered,
                        full-service commercial office furniture dealership and
                        service provider that specializes in meeting needs,
                        solving problems and enhancing the total office
                        environment through customized, cost-effective workspace
                        solutions...and more.
                    </Italics>
                </p>
                <PostsNavigator>
                    <NewPost>
                        <h4>Newer Post</h4>
                        <Link to='/resources/05042020'>
                            Creating Flow While Working From Home
                        </Link>
                    </NewPost>
                    <OldPost>
                        <h4>Older Post</h4>
                        <Link to='/resources/03052020'>
                            The Optimum Office Temperature
                        </Link>
                    </OldPost>
                </PostsNavigator>
            </MainContainer>
            <ContactUsHome />
        </>
    );
}
