import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Header from "../../../assets/img/pages/resources/blogs/blog01252020/header.jpeg";
import Stretching from "../../../assets/img/pages/resources/blogs/blog01252020/stretching.jpeg";
import Yoga from "../../../assets/img/pages/resources/blogs/blog01252020/yoga.jpeg";
import Relax from "../../../assets/img/pages/resources/blogs/blog01252020/relax.png";

import ContactUsHome from "../../../components/ContactUsHome/ContactUsHome";

const MainContainer = styled.div`
    width: 100%;
    padding: 0 30%;
    margin-top: 8rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-top: 8rem;
        padding: 0 2rem;
    }

    a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
    }

    p {
        margin-bottom: 1.5rem;
    }

    h1 {
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.5rem;
        margin-top: 6rem;
        text-transform: uppercase;
    }
`;

const BlogImg = styled.img`
    width: 100%;
    height: auto;
    margin: 4rem 0;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin: 2rem 0;
    }
`;

const Date = styled.p`
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.btnDisabled};
`;

//eslint-disable-next-line
const Bolded = styled.span`
    font-weight: 800;
`;

const Italics = styled.span`
    font-style: italic;
`;

const PostsNavigator = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10rem;
`;

const NewPost = styled.div`
    width: 50%;
    display: flex;
    flex-flow: column;
`;

const OldPost = styled(NewPost)`
    align-items: end;
    text-align: right;
`;

export default function Blog01252020() {
    return (
        <>
            <MainContainer>
                <Date>January 25th, 2020</Date>
                <h1>why you should take breaks in the office</h1>
                <BlogImg src={Header} alt='header' />
                <p>
                    We’ve all been there. We’re working on a major project with
                    a deadline right around the corner, but we keep running into
                    roadblock after roadblock, stress and anxiety and
                    frustration growing, mounting, looming right over our
                    shoulder with no relief in sight, not even after we clock
                    out and head home because all we can think of as we eat
                    dinner, go to bed, and sleep is work, work work, work, work,
                    work. And while these situations are hopefully few and far
                    between, times like these are when taking a break becomes a
                    necessity—not just for the sake of productivity, but for the
                    sake of your own personal wellbeing!
                </p>
                <h2>the benefit of taking breaks</h2>
                <BlogImg src={Stretching} alt='woman stretching at desk' />
                <p>
                    So why exactly should you take at least one short break
                    during your work day?{" "}
                    <a
                        href='https://ink.library.smu.edu.sg/cgi/viewcontent.cgi?article=6133&context=lkcsb_research'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Research
                    </a>{" "}
                    has found that some broad benefits of taking breaks during
                    the work day include increasing “employees’ experience of
                    being engaged at work.” Here are some specific benefits that
                    come about from taking short breaks:
                </p>
                <p>
                    <Italics>Reduced stress:</Italics>
                </p>
                <p>
                    It’s no secret that stress can hinder your ability to
                    perform well in the office. In fact, stress has been linked
                    to a{" "}
                    <a
                        href='https://www.cnbc.com/2017/12/14/the-harmful-effects-of-stress-at-work-and-what-you-can-do-to-beat-it.html'
                        rel='noreferrer'
                        target='_blank'
                    >
                        myriad of health problems
                    </a>
                    , including high blood pressure, heart problems, and
                    increased susceptibility to diabetes and cancer; mental
                    health problems include irritability, hopelessness, and even
                    memory problems.
                </p>
                <p>
                    Not to worry though! Taking breaks while at work has been
                    proven to reduce stress throughout the day. It’s{" "}
                    <a
                        href='https://www.verywellmind.com/why-you-should-take-a-break-3144576'
                        rel='noreferrer'
                        target='_blank'
                    >
                        suggested
                    </a>
                    that when you begin to feel overwhelmed with stress—classic
                    signs include increased frustration and decreased energy and
                    motivation—it is best to take a short break to give your
                    mind a rest from being exposed to the source of your stress.
                </p>
                <p>
                    <Italics>Boosted physical well-being:</Italics>
                </p>
                <p>
                    Outside of reducing stress, taking breaks during the workday
                    can also provide plenty of benefits to your physical health.
                    In the modern workforce, “sedentary jobs have increased 83%
                    since 1950,{" "}
                    <a
                        href='https://www.forbes.com/sites/nicolefisher/2019/03/06/americans-sit-more-than-anytime-in-history-and-its-literally-killing-us/#161a2270779d'
                        rel='noreferrer'
                        target='_blank'
                    >
                        according to the American Heart Association
                    </a>
                    .” Meanwhile, the average office employee sits an alarming
                    15 hours every day. Such an inactive lifestyle is
                    detrimental to our health, with the{" "}
                    <a
                        href='https://www.mayoclinic.org/healthy-lifestyle/adult-health/expert-answers/sitting/faq-20058005'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Mayo Clinic
                    </a>{" "}
                    finding that “those who sat for more than eight hours a day
                    with no physical activity had a risk of dying similar to the
                    risks of dying posed by obesity and smoking.” After all, our
                    species didn’t evolve to sit in front of a screen for hours
                    on end.
                </p>
                <p>
                    Taking breaks throughout the workday provides the perfect
                    opportunity to introduce more activity back into your life.
                    From a quick stretching session to a walk around your
                    building’s perimeter, the possibilities to get active during
                    a break are endless, all of which get you out of your seat
                    to do what your body has evolved to do—move! The Mayo Clinic
                    claims that even “leisurely movement” can have profound
                    impacts on our health.
                </p>
                <p>
                    <Italics>Increased motivation and productivity:</Italics>
                </p>
                <p>
                    <a
                        href='https://www.sciencedaily.com/releases/2011/02/110208131529.htm'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Research
                    </a>{" "}
                    suggests that working on a task for prolonged amounts of
                    time decreases your ability to perform well on the task.
                    This phenomenon may be linked to the process in which your
                    brain eventually ignores a stimulus after extended exposure
                    to it—eg. when you become accustomed to the smell of
                    something after being exposed to its scent for a long time.
                    Similar things may happen to actual thoughts: “if sustained
                    attention to a sensation makes that sensation vanish from
                    our awareness, sustained attention to a thought should also
                    lead to that thought's disappearance from our mind.” And if
                    a task eventually disappears from your mind, then your
                    motivation and productivity to work on it will also vanish!
                </p>
                <p>
                    Consequently, getting some space away from a project after
                    working on it for a while will give your brain some much
                    needed space. It could clear any roadblocks impeding your
                    progress, and enable you to bring a fresh perspective to the
                    task, rejuvenating your motivation and boosting your
                    productivity!
                </p>
                <h2>types of breaks</h2>
                <BlogImg src={Yoga} alt='woman doing yoga at office' />
                <p>
                    Clearly, the reasons why you should take at least a few
                    short breaks during your workday are plentiful. But what
                    exactly should you be doing on your break? Here are some
                    ideas:
                </p>
                <p>
                    <ul>
                        <li>
                            <Bolded>Exercise:</Bolded> As mentioned earlier,
                            breaks are the perfect time to get active and burn
                            some calories! Some other ideas include light
                            calisthenics (eg. body weight squats, push-ups,
                            jumping jacks) and even busting a move to your
                            favorite song (with some headphones on, of course)!
                        </li>
                        <li>
                            <Bolded>Take some deep breaths:</Bolded> The
                            benefits of deep breathing are bountiful. A
                            prominent positive effect is that deep breathing
                            helps induce the{" "}
                            <a
                                href='https://www.health.harvard.edu/mind-and-mood/relaxation-techniques-breath-control-helps-quell-errant-stress-response'
                                rel='noreferrer'
                                target='_blank'
                            >
                                relaxation response
                            </a>
                            , the process that helps us get out of the stress
                            response—the flight or fight response brought about
                            by many of our modern stressors.
                        </li>
                        <li>
                            <Bolded>Explore your creativity:</Bolded> There’s no
                            reason why your breaks shouldn’t fun, as well!
                            Getting creative on your break by drawing some
                            doodles or writing a few lines of poetry can be a
                            chance to let the more artistic side of your brain
                            take the reins, while the analytical side takes a
                            break. It might even spark some new ideas for
                            whatever project you were working on!
                        </li>
                        <li>
                            <Bolded>Check in with your surroundings:</Bolded>{" "}
                            Using your break to truly connect with the
                            environment around you could get you out of your
                            head and back in the present moment. Try to notice
                            something new about your office space that you never
                            noticed before, be it an interesting light fixture
                            or the buzz of the street outside.
                        </li>
                        <li>
                            <Bolded>Connect with your coworkers:</Bolded>{" "}
                            Getting social on your break is a great way to
                            connect with your coworkers (just make sure that
                            they are on break and want to chat as well)! Strong
                            rapport and camaraderie is essential to building an
                            office community, so using a break to discuss
                            current events or your weekend plans with your
                            colleagues could be an important step to
                            strengthening your team as a whole.
                        </li>
                        <li>
                            <Bolded>Go outside:</Bolded> It’s been theorized
                            that humans have an innate desire to connect with
                            nature, an idea known as the{" "}
                            <a
                                href='https://www.britannica.com/science/biophilia-hypothesis'
                                rel='noreferrer'
                                target='_blank'
                            >
                                biophilia hypothesis
                            </a>
                            . Using your break to step outside and reconnect
                            with the great outdoors has been proven to have many
                            benefits, including{" "}
                            <a
                                href='https://hbr.org/2017/06/why-you-should-tell-your-team-to-take-a-break-and-go-outside'
                                rel='noreferrer'
                                target='_blank'
                            >
                                enhanced physical and mental well-being.
                            </a>
                        </li>
                    </ul>
                </p>
                <h2>in conclusion</h2>
                <BlogImg src={Relax} alt='employee relaxing' />
                <p>
                    Ultimately, taking a few short breaks during your workday
                    yields a range of benefits, from improving your overall
                    well-being, to sparking some new approaches for whatever you
                    were working on in the first place! Your office’s break room
                    is an important space to give employees an area to relax.
                    And here at Facility Solutions Plus, we have a range of
                    products—from lounge chairs to state-of-the-art pods—to
                    ensure that your break room helps your employees rest and
                    recharge.
                </p>
                <p>
                    <Italics>
                        Facility Solutions Plus is a client-centered,
                        full-service commercial office furniture dealership and
                        service provider that specializes in meeting needs,
                        solving problems and enhancing the total office
                        environment through customized, cost-effective workspace
                        solutions...and more.
                    </Italics>
                </p>
                <PostsNavigator>
                    <NewPost>
                        <h4>Newer Post</h4>
                        <Link to='/resources/01272020'>
                            Cultivating Productivity in the Office
                        </Link>
                    </NewPost>
                    <OldPost>
                        <h4>Older Post</h4>
                        <Link to='/resources/01212020'>
                            Feng Shui in the Office
                        </Link>
                    </OldPost>
                </PostsNavigator>
            </MainContainer>
            <ContactUsHome />
        </>
    );
}
