import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import hero from "../../assets/img/pages/careers/hero-desktop.jpg";
import careerImg from "../../assets/img/pages/careers/careers.svg";
import ContactUsHome from "../../components/ContactUsHome/ContactUsHome";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.tertiary};
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        justify-content: center;
    }
    h1 {
        margin-bottom: 4rem;
    }

    h2 {
        margin-bottom: 4rem;
    }
`;

const HeroImg = styled.div`
    width: 100%;

    margin-bottom: 8rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        background: url(${hero});
        background-size: cover;
        background-position: 0px;
        background-repeat: no-repeat;
        background-color: #f4eee2;
        height: 50vh;
    }
`;

const Content = styled.div`
    width: 80%;
    display: flex;
    margin-bottom: 4rem;
    align-items: center;
    justify-content: center;
    h2 {
        margin-bottom: 2rem;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        flex-flow: column;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        flex-flow: column;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.XL}) {
        flex-flow: row;
    }
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    h2 {
        text-transform: none;
        text-align: center;
        font-weight: 400;
    }
    p {
        margin-top: 2rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.XL}) {
        margin-bottom: 8rem;
    }
`;

const CareerImg = styled.img`
    margin-top: 2rem;
    width: 100%;
    height: auto;
    margin-bottom: 8rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 90%;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        width: 60%;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.XL}) {
        width: 50%;
    }
`;

const JobLink = styled(Link)`
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.primary};
    margin-top: 2rem;
`;

const JobCard = styled(Content)`
    border: 1px solid ${({ theme }) => theme.colors.btnDisabled};
    border-radius: 10px;
    padding: 1.5rem;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 40rem;
        flex-flow: column;
        padding: 3rem;
        text-align: center;
    }
`;

export default function careers() {
    return (
        <Container>
            <HeroImg />
            <h1>Careers</h1>
            <Content>
                <Row>
                    <h2>Be yourself and join our family!</h2>
                    <p>
                        At Facility Solutions Plus, we bring our clients’ ideas
                        to life. Founded in 2018, Facility Solutions Plus
                        started with just six employees working hard to turn
                        their customers’ office furniture dreams into a reality.
                        At the end of the company’s first year, we managed to
                        surpassed our sales goal by more than 85%! Today, we
                        strive to accomplish even greater goals all while
                        maintaining our company philosophy that everyone is
                        family – from our employees to our clients to even our
                        vendors.
                    </p>
                    <p>
                        Facility Solutions Plus cultivates a culture of family
                        members who are passionate about providing the best
                        office furniture purchasing experience. We focus on
                        hiring top-notch talent while providing them with the
                        tools necessary to grow even more.
                    </p>
                    <p>
                        Our goal is to build a family with a diverse set of
                        skills, backgrounds, and perspectives. By striving to
                        maintain our company philosophy, we are committed to
                        creating an inclusive environment where every family
                        member can thrive.
                    </p>
                </Row>
                <CareerImg src={careerImg} alt='careers svg' />
            </Content>
            <h2>Job Openings</h2>
            <Row>
                <JobCard>
                    <h3>Account Manager</h3>
                    <p>
                        Responsible for all projects and daily work activities,
                        sales and full customer satisfaction for assigned
                        account.
                    </p>
                    <JobLink to='/careers/account-manager'>Learn more</JobLink>
                </JobCard>
            </Row>
            <ContactUsHome />
        </Container>
    );
}
