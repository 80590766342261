import React from "react";
import styled from "styled-components";
// import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";

import NotFoundImg from "../../assets/img/pages/404.svg";
import { AltColorBtn } from "../Button/Button";

const Container = styled.div`
    width: 100%;
    margin-top: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        margin-top: 6rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.XL}) {
        padding-top: 3rem;
    }
`;

const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
        flex-flow: column-reverse;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.XL}) {
        flex-flow: row-reverse;
        padding: 0 4rem;
    }
`;

const TextContainer = styled.div`
    h2 {
        color: #2d5188;
    }

    p {
        margin: 2rem 0;
        font-family: "Montserrat", sans-serif;
        color: ${({ theme }) => theme.colors.secondary};
    }

    text-align: left;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
        padding: 2rem;
        display: flex;
        flex-flow: column;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.XL}) {
        margin-top: 6rem;
        width: 40%;
    }
`;

const StyledImg = styled.img`
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-top: 5rem;
        width: 25rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        margin-top: 6rem;
        width: 35rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        width: 30rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.XL}) {
        width: 45rem;
        margin-right: 8rem;
    }
`;

export default function NotFound() {
    return (
        <Container>
            <ImgContainer>
                <TextContainer>
                    <h2>Don't know where you are?</h2>
                    <p>We really have no idea either...</p>
                    <Link to='/'>
                        <AltColorBtn color={`#2d5188`} hoverColor={`#5375a2`}>
                            BACK TO HOME PAGE
                        </AltColorBtn>
                    </Link>
                </TextContainer>
                <StyledImg src={NotFoundImg} alt='404 error image' />
            </ImgContainer>
        </Container>
    );
}
