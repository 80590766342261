import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import AboutBG from "../../assets/img/pages/home/about-bg.jpg";

const AboutSectionContainer = styled(ScrollAnimation)`
  width: 100%;
  background: url(${AboutBG});
  background-size: fill;
  padding: 10rem 0;
  margin-bottom: 10rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  h2,
  p {
    text-align: center;
  }

  h2 {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    background-size: cover;
  }
`;

const Content = styled.div`
  width: 100%;
  text-align: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 60%;
  }
`;

export default function HomeMarketRelationships() {
  return (
    <AboutSectionContainer animateIn="fadeIn" animateOnce="true">
      <Content>
        <h2>Manufacturer Relationships</h2>
        <p>
          At Facility Solutions Plus our business is built on strong
          relationships which start at the manufacturer level. We work hard to
          create and maintain the best connections with our manufacturers and
          the rep groups that are local to our offices. These close connections
          give our customers direct access to the best product showrooms,
          industry knowledge, and deeper discounts. Our goal is to create a
          lasting relationship that starts at the furniture source and ends with
          a happy customer.
        </p>
      </Content>
    </AboutSectionContainer>
  );
}
