import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import useWindowDimensions from "../../components/windowDimension/windowDimension";

import Button from "../Button/Button";

import corporate from "../../assets/img/pages/home/animatedSlider/corporate.jpeg";
import education from "../../assets/img/pages/home/animatedSlider/education.jpg";
import govt from "../../assets/img/pages/home/animatedSlider/govt-civic.jpg";
import healthcare from "../../assets/img/pages/home/animatedSlider/healthcare.jpg";
import hospitality from "../../assets/img/pages/home/animatedSlider/hospitality.jpeg";
import science from "../../assets/img/pages/home/animatedSlider/science-tech.jpg";

import corporateMobile from "../../assets/img/pages/home/animatedSlider/corporate-mobile.jpg";
import educationMobile from "../../assets/img/pages/home/animatedSlider/education-mobile.jpg";
import govtMobile from "../../assets/img/pages/home/animatedSlider/govt-civic-mobile.jpg";
import healthcareMobile from "../../assets/img/pages/home/animatedSlider/healthcare-mobile.jpg";
import hospitalityMobile from "../../assets/img/pages/home/animatedSlider/hospitality-mobile.jpg";
import scienceMobile from "../../assets/img/pages/home/animatedSlider/science-tech-mobile.jpg";

const mobileImgs = [
    {
        bgImg: corporateMobile,
        title: "Corporate",
        pTop: "Your office space,",
        pBottom: "Re-imagined",
    },
    { bgImg: educationMobile, title: "Education" },
    { bgImg: govtMobile, title: "Government & Civic" },
    { bgImg: healthcareMobile, title: "Healthcare" },
    { bgImg: hospitalityMobile, title: "Hospitality" },
    { bgImg: scienceMobile, title: "Science & Technology" },
];

const desktopImgs = [
    {
        bgImg: corporate,
        title: "Corporate",
        pTop: "Your office space,",
        pBottom: "Re-imagined",
    },
    { bgImg: education, title: "Education" },
    { bgImg: govt, title: "Government & Civic" },
    { bgImg: healthcare, title: "Healthcare" },
    { bgImg: hospitality, title: "Hospitality" },
    { bgImg: science, title: "Science & Technology" },
];

const delay = 5000;

const Slideshow = styled.div`
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
`;

const SlideshowSlider = styled.div`
    white-space: nowrap;
    -webkit-transition: opacity 3s ease-in-out;
    -moz-transition: opacity 3s ease-in-out;
    -ms-transition: opacity 3s ease-in-out;
    -o-transition: opacity 3s ease-in-out;
`;

const Slide = styled.div`
    display: inline-block;
    height: 70vh;
    width: 100%;
    background-size: contain;
    background-position: center;
`;

const Inner = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
`;

const Title = styled.div`
    h2 {
        color: white;
    }
    width: 100%;
    padding: 3rem;
    display: flex;
    justify-content: end;
    align-items: end;
    height: 10%;
`;

const Content = styled.div`
    height: 90%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    p {
        color: white;
    }

    p:nth-child(1) {
        font-size: 2rem;
        font-style: italic;
    }

    p:nth-child(2) {
        font-family: "Montserrat", sans-serif;
        font-size: 4rem;
        font-weight: 600;
        text-transform: uppercase;
        margin: 4rem 0;
    }
`;

export default function HomeHeroSlider() {
    const { width } = useWindowDimensions();

    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);

    const isMobileImg = width >= 900 ? desktopImgs : mobileImgs;

    const isButton =
        index === 0 ? (
            <Link to='/contact'>
                <Button>Schedule a Consultation</Button>
            </Link>
        ) : null;

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === isMobileImg.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );
        return () => {
            resetTimeout();
        };
    });

    return (
        <Slideshow>
            <SlideshowSlider
                style={{ transform: `translate3d(${-index * 100}%, 0,0)` }}
            >
                {isMobileImg.map((bg, index) => (
                    <Slide
                        key={index}
                        style={{
                            background: `url(${bg.bgImg})`,
                            backgroundSize: `cover`,
                            backgroundPosition: `center`,
                        }}
                    >
                        <Inner>
                            <Content>
                                <p>{bg.pTop}</p>
                                <p>{bg.pBottom}</p>
                                {isButton}
                            </Content>
                            <Title>
                                <h2>{bg.title}</h2>
                            </Title>
                        </Inner>
                    </Slide>
                ))}
            </SlideshowSlider>
        </Slideshow>
    );
}
