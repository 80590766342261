import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import { HashLink } from "react-router-hash-link";

import hero from "../../assets/img/pages/portfolio/USmap.jpg";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.tertiary};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    justify-content: center;
  }
`;

const HeroImg = styled.div`
  background: url(${hero});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f4eee2;
  width: 100%;
  height: 50vh;
  margin-bottom: 8rem;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin-bottom: 8rem;
  align-items: center;
  justify-content: center;
  h1 {
    margin-bottom: 4rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 60%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 60%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-flow: column;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
  }
`;

const Location = styled(ScrollAnimation)`
  display: flex;
  flex-flow: column;
  h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.secondary};
  }
  h4 {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.primary};
  }
  margin-bottom: 2rem;
`;

export default function portfolio() {
  return (
    <Container>
      <HeroImg />
      <Content>
        <h1>Portfolio</h1>
        <Row>
          <Location animateIn="fadeIn" animateOnce="true">
            <HashLink smooth to="/portfolio/tx">
              <h2>TEXAS</h2>
            </HashLink>
            <HashLink smooth to="/portfolio/tx#angleton">
              <h4>Angleton, TX</h4>
            </HashLink>
            <HashLink smooth to="/portfolio/tx#houston">
              <h4>Houston, TX</h4>
            </HashLink>
            <HashLink smooth to="/portfolio/tx#nederland">
              <h4>Nederland, TX</h4>
            </HashLink>
            <HashLink smooth to="/portfolio/tx#sugarland">
              <h4>Sugar Land, TX</h4>
            </HashLink>
            <HashLink smooth to="/portfolio/tx#laporte">
              <h4>La Porte, TX</h4>
            </HashLink>
          </Location>
          <Location animateIn="fadeIn" animateOnce="true">
            <HashLink smooth to="/portfolio/ga">
              <h2>Georgia</h2>
            </HashLink>
            <HashLink smooth to="/portfolio/ga#atlanta">
              <h4>Atlanta, GA</h4>
            </HashLink>
            <HashLink smooth to="/portfolio/ga#savannah">
              <h4>Savannah, GA</h4>
            </HashLink>
          </Location>
          <Location animateIn="fadeIn" animateOnce="true">
            <HashLink smooth to="/portfolio/al">
              <h2>Alabama</h2>
            </HashLink>
            <HashLink smooth to="/portfolio/al#birmingham">
              <h4>birmingham, AL</h4>
            </HashLink>
            <HashLink smooth to="/portfolio/al#decatur">
              <h4>Decatur, AL</h4>
            </HashLink>
          </Location>
          <Location animateIn="fadeIn" animateOnce="true">
            <HashLink smooth to="/portfolio/il">
              <h2>Illinois</h2>
            </HashLink>
            <HashLink smooth to="/portfolio/il#newlenox">
              <h4>New Lenox, IL</h4>
            </HashLink>
            <HashLink smooth to="/portfolio/il#waukagen">
              <h4>Waukagen, IL</h4>
            </HashLink>
          </Location>
        </Row>
        <Row>
          <Location animateIn="fadeIn" animateOnce="true">
            <HashLink smooth to="/portfolio/la">
              <h2>Louisiana</h2>
            </HashLink>
            <HashLink smooth to="/portfolio/la">
              <h4>Baton Rouge, LA</h4>
            </HashLink>
          </Location>
          <Location animateIn="fadeIn" animateOnce="true">
            <HashLink smooth to="/portfolio/wa">
              <h2>Washington</h2>
            </HashLink>
            <HashLink smooth to="/portfolio/wa">
              <h4>Seattle, WA</h4>
            </HashLink>
          </Location>
          <Location animateIn="fadeIn" animateOnce="true">
            <HashLink smooth to="/portfolio/fl">
              <h2>Florida</h2>
            </HashLink>
            <HashLink smooth to="/portfolio/fl">
              <h4>Longwood, FL</h4>
            </HashLink>
          </Location>
        </Row>
      </Content>
    </Container>
  );
}
