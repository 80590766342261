import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import nederland1 from "./Conference Room 3.jpg";
import nederland2 from "./Conference Room 5.jpg";
import nederland3 from "./Cubicles 4.jpg";
import nederland4 from "./Cubicles 5.jpg";

const imgs = [nederland1, nederland2, nederland3, nederland4];

const Container = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 4rem 0;
  h2 {
    margin-bottom: 4rem;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
`;

const FolioImg = styled.img`
  margin: 1rem 0;
  width: 20rem;
  height: auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 1rem 1rem;
    width: 25rem;
  }
`;

export default function NederlandTX() {
  let markup = imgs.map((img) => <FolioImg src={img} alt="test" />);
  return (
    <Container animateIn="fadeIn" animateOnce="true">
      <h2>Nederland, TX</h2>
      <Content>{markup}</Content>
      <span id="sugarland"></span>
    </Container>
  );
}
