import React from "react";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

import hero from "../../../assets/img/pages/portfolio/USmap.jpg";

import AtlantaGA from "./AtlantaGA/AtlantaGA";
import SavannahGA from "./SavannahGA/SavannahGA";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.tertiary};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    justify-content: center;
  }
`;

const CityNavbar = styled.div`
  background-color: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #000;
  p {
    list-style-type: none;
    color: #000;
  }
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 2rem;
  padding: 1rem 0;
  position: -webkit-sticky;
  position: sticky;
  top: 6.8rem;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 10px 20%;
    flex-flow: row;
    top: 6rem;
  }
  z-index: 100;
`;

const HeroImg = styled.div`
  background: url(${hero});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f4eee2;
  width: 100%;
  height: 50vh;
  margin-bottom: 8rem;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin-bottom: 8rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1 {
    margin-bottom: 4rem;
  }
`;

const CityLinks = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0.2rem 0;
  width: 100%;
  z-index: 200;
  color: #000;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
  }
`;

export default function TXportfolio() {
  return (
    <Container>
      <HeroImg />
      <h1 id="ga">Georgia</h1>
      <span id="atlanta"></span>
      <CityNavbar>
        <CityLinks>
          <HashLink smooth to="/portfolio/ga#atlanta">
            <p>Atlanta</p>
          </HashLink>
          <HashLink smooth to="/portfolio/ga#savannah">
            <p>Savannah</p>
          </HashLink>
        </CityLinks>
      </CityNavbar>

      <Content>
        <AtlantaGA />
        <SavannahGA />
      </Content>
    </Container>
  );
}
