import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import whtLogo from "../../../../assets/img/logo/logo-wht-orng.png";
import facebookLogo from "../../../../assets/img/icons/facebook.svg";
import instagramLogo from "../../../../assets/img/icons/instagram.svg";
import twitterLogo from "../../../../assets/img/icons/twitter.svg";

const Logo = styled.img`
    width: 10rem;
`;

const FooterContainer = styled.div`
    width: 100vw;
    background-color: ${({ theme }) => theme.colors.secondary};
    bottom: 0;
    padding: 2rem;
    margin-top: 10rem;
`;

const LogoContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
`;

const LinksContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0.5rem 0;
`;

const ContactContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 0.5rem 0;
    p {
        color: white;
        font-family: "Montserrat", sans-serif;
        font-size: 0.8rem;
        margin: 0.5rem;
    }
    h4 {
        color: white;
    }
`;

const SocialsContainer = styled.div`
    display: flex;
    width: 40%;
    justify-content: space-between;
    margin: 1rem 0;
`;

const Socials = styled.img`
    width: 2rem;
    height: auto;
    margin: 1 0.5rem 0 0.5rem;
`;

const SiteLink = styled(Link)`
    text-decoration: none;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 0.8rem;
    margin: 0.5rem;
`;

const CopyrightContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    flex-flow: column;

    p {
        color: white;
        font-family: "Montserrat", sans-serif;
        font-size: 0.8rem;
        margin: 0.5rem;
    }
`;

const PrivacyLink = styled(SiteLink)`
    text-transform: none;
`;

export default function MobileFooter() {
    return (
        <FooterContainer>
            <LogoContainer>
                <Logo src={whtLogo} alt='Logo' />
                <SocialsContainer>
                    <Socials src={facebookLogo} alt='Facebook Logo' />
                    <Socials src={instagramLogo} alt='Instagram Logo' />
                    <Socials src={twitterLogo} alt='Twitter Logo' />
                </SocialsContainer>
            </LogoContainer>
            <LinksContainer>
                <SiteLink to='/'>Home</SiteLink>
                <SiteLink to='/careers'>Careers</SiteLink>
                <SiteLink to='/products'>Products</SiteLink>
                <SiteLink to='/portfolio'>Portfolio</SiteLink>
                <SiteLink to='/vendors/texas'>TX Vendors</SiteLink>
                <SiteLink to='/vendors/georgia'>GA Vendors</SiteLink>
            </LinksContainer>
            <ContactContainer>
                <h4>CONNECT</h4>
                <p>info@facilitysolutionsplus.com</p>
                <p>P: 1(713) 497-4495</p>
            </ContactContainer>
            <CopyrightContainer>
                <p>&copy; All Rights Reserved 2021</p>
                <PrivacyLink to='/privacy-policy'>Privacy Policy</PrivacyLink>
            </CopyrightContainer>
        </FooterContainer>
    );
}
