import React from "react";
import styled from "styled-components";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

import georgiaVendorList from "./georgia-vendor-list-09282021.json";

const Container = styled.div`
  padding-top: 10rem;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.tertiary};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    justify-content: center;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  padding-top: 4rem;
  margin-bottom: 8rem;
  align-items: center;
  justify-content: center;
  h1 {
    margin-bottom: 4rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
    flex-wrap: wrap;
  }
`;

const Card = styled.table`
  width: 100%;
  padding: 1.5rem;
  h4 {
    text-transform: none;
  }
  a {
    color: ${({ theme }) => theme.colors.tertiary};
  }
  line-height: 2.5rem;
  td {
    border-bottom: 1px solid ${({ theme }) => theme.colors.btnDisabled};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 50%;
  }
`;

const VendorName = styled.span`
  color: ${({ theme }) => theme.colors.accent};
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
`;

export default function GAVendorList() {
  let vendorMarkup = georgiaVendorList.map((vendor) => (
    <tr>
      <td>
        <a href={vendor.url}>
          <VendorName>{vendor.vendorname}</VendorName>
        </a>
      </td>
      <td>{vendor.contents}</td>
    </tr>
  ));
  return (
    <Container>
      <h1>Georgia Vendor List</h1>
      <br />
      <Link to="/vendors/texas">
        <Button>View Texas Vendor List</Button>
      </Link>
      <Content>
        <Card>{vendorMarkup}</Card>
      </Content>
      <Link to="/vendors/texas">
        <Button>View Texas Vendor List</Button>
      </Link>
    </Container>
  );
}
