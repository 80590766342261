import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import QuoteBG from "../../assets/img/pages/home/client-quote-bg.jpg";

const Container = styled(ScrollAnimation)`
  width: 100%;
  background: url(${QuoteBG});
  background-size: cover;
  background-position: bottom;
  padding: 10rem 0;
  margin-bottom: 10rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  h2,
  p {
    text-align: center;
  }
`;

const Content = styled.div`
  width: 80%;
  text-align: center;
  p:nth-child(1) {
    margin-bottom: 4rem;
  }
  p:nth-child(2) {
    font-size: 1.5rem;
  }
  color: white;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 60%;
  }
`;

const Quote = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 2rem;

    line-height: 2.8rem;
  }
`;

export default function HomeClientQuote() {
  return (
    <Container animateIn="fadeIn" animateOnce="true">
      <Content>
        <Quote>
          "We were so lost on what to do with our existing cubicles. We
          contacted Chris at Facility Solutions Plus, the name should include
          "Creative" solutions, our 6x8's were converted all to 6x6 to add more
          employee's to a growing company in these challenging times. Completely
          magnificent/professional job. Great to find a team that takes pride in
          their job."
        </Quote>
        <p>- Jean Maciel</p>
      </Content>
    </Container>
  );
}
