import React from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";

import whtLogo from "../../../assets/img/logo/logo-wht-orng.png";

const DesktopNavContainer = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.secondary};
    position: fixed;
    top: 0;
    display: flex;
    padding: 1.5rem;
    justify-content: space-between;
    z-index: 1000;
`;

const LogoContainer = styled.div`
    width: 30%;
    display: flex;
    align-items: center;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 20%;
    }
`;

const LinksContainer = styled.div`
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 2rem;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 80%;
        margin-left: 0.5rem;
    }
`;

const Logo = styled.img`
    width: 12rem;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 9rem;
    }
`;

const NavLinks = styled(NavLink)`
    text-decoration: none;
    color: white;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    -moz-transition: all 0.1s ease-in;
    -o-transition: all 0.1s ease-in;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.2s ease-in;
    &:hover {
        color: #6a73ab;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        font-size: 0.95rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        font-size: 1rem;
    }
`;

const ContactButton = styled.button`
    text-decoration: none;
    color: white;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    border-radius: 3rem;
    height: 3rem;
    width: 7.25rem;
    background-color: ${({ theme }) => theme.colors.secondary};
    border: 2px solid white;
    -moz-transition: all 0.1s ease-in;
    -o-transition: all 0.1s ease-in;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.2s ease-in;
    &:hover {
        color: ${({ theme }) => theme.colors.secondary};
        background-color: white;
        font-weight: 500;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 10rem;
    }
`;

export default function DesktopNavbar() {
    return (
        <DesktopNavContainer>
            <Link exact to='/'>
                <LogoContainer>
                    <NavLink exact to='/'>
                        <Logo src={whtLogo} alt='Logo' />
                    </NavLink>
                </LogoContainer>
            </Link>
            <LinksContainer>
                <NavLinks
                    activeStyle={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                    }}
                    exact
                    to='/'
                >
                    Home
                </NavLinks>
                <NavLinks
                    activeStyle={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                    }}
                    to='/about'
                >
                    About Us
                </NavLinks>
                <NavLinks
                    activeStyle={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                    }}
                    to='/markets'
                >
                    Markets
                </NavLinks>
                <NavLinks
                    activeStyle={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                    }}
                    to='/services'
                >
                    Services
                </NavLinks>
                <NavLinks
                    activeStyle={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                    }}
                    to='/products'
                >
                    Products
                </NavLinks>
                <NavLinks
                    activeStyle={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        textUnderlineOffset: "5px",
                    }}
                    to='/portfolio'
                >
                    Portfolio
                </NavLinks>
                <NavLinks
                    activeStyle={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        textUnderlineOffset: "5px",
                    }}
                    to='/resources'
                >
                    Resources
                </NavLinks>
                <NavLinks
                    activeStyle={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        textUnderlineOffset: "5px",
                    }}
                    to='/contact'
                >
                    <ContactButton>Contact Us</ContactButton>
                </NavLinks>
            </LinksContainer>
        </DesktopNavContainer>
    );
}
