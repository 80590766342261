import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Header from "../../../assets/img/pages/resources/blogs/blog02062020/thinkingdiversity.jpeg";
import Chatbubbles from "../../../assets/img/pages/resources/blogs/blog02062020/chatbubbles.jpeg";
import Collaborative from "../../../assets/img/pages/resources/blogs/blog02062020/collaborative.png";
import Diversity from "../../../assets/img/pages/resources/blogs/blog02062020/diversity.jpeg";
import Cheer from "../../../assets/img/pages/resources/blogs/blog02062020/cheer.png";

import ContactUsHome from "../../../components/ContactUsHome/ContactUsHome";

const MainContainer = styled.div`
    width: 100%;
    padding: 0 30%;
    margin-top: 8rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-top: 8rem;
        padding: 0 2rem;
    }

    a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
    }

    p {
        margin-bottom: 1.5rem;
    }
    h1 {
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.5rem;
        margin-top: 6rem;
        text-transform: uppercase;
    }
`;

const BlogImg = styled.img`
    width: 100%;
    height: auto;
    margin: 4rem 0;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin: 2rem 0;
    }
`;

const Date = styled.p`
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.btnDisabled};
`;

//eslint-disable-next-line
const Bolded = styled.span`
    font-weight: 800;
`;

const Italics = styled.span`
    font-style: italic;
`;

const PostsNavigator = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10rem;
`;

const NewPost = styled.div`
    width: 50%;
    display: flex;
    flex-flow: column;
`;

const OldPost = styled(NewPost)`
    align-items: end;
    text-align: right;
`;

export default function Blog02062020() {
    return (
        <>
            <MainContainer>
                <Date>February 6th, 2020</Date>
                <h1>Cognitive diversity in the office</h1>
                <BlogImg src={Header} alt='header' />
                <p>
                    Picture this: you and your sales team have a monumental
                    pitch coming up, and your team has spent that past few weeks
                    researching, preparing, and rehearsing the perfect
                    presentation for the potential client. However, three days
                    before you all are set to travel to your client’s
                    headquarters to present, your team learns about a new market
                    innovation that completely changes the nature of your pitch.
                    How does your team respond? Do you scramble—and ultimately
                    fail—to adapt to the new information, or do you synthesize
                    and integrate the new findings into your original pitch to
                    create a more nuanced presentation that wins over the
                    client? Ideally, your team achieves the latter scenario in
                    this thought experiment—cognitive diversity may be the key
                    to doing so.
                </p>
                <h2>what is cognitive diversity?</h2>
                <BlogImg src={Chatbubbles} alt='multicolored chat bubbles' />
                <p>
                    Discussing diversity within the office space is nothing new.
                    The topic of increasing diversity within a range of
                    areas—including ethnicity, gender, and age—has been and
                    continues to be an important consideration in any hiring and
                    promotional endeavor. Newer to the conversation, however
                    (and slightly more complex), is the concept of cognitive
                    diversity.
                </p>
                <p>
                    Cognitive diversity, as defined by researchers Alison
                    Reynolds and David Lewis of the Harvard Business Review,{" "}
                    <a
                        href='https://hbr.org/2017/03/teams-solve-problems-faster-when-theyre-more-cognitively-diverse'
                        rel='noreferrer'
                        target='_blank'
                    >
                        refers
                    </a>{" "}
                    to the “differences in perspective or information processing
                    styles” present in any given group of people. Similarly,
                    leadership coach Janine Schindler{" "}
                    <a
                        href='https://www.forbes.com/sites/forbescoachescouncil/2018/11/26/the-benefits-of-cognitive-diversity/#feecae85f8bd'
                        rel='noreferrer'
                        target='_blank'
                    >
                        describes
                    </a>{" "}
                    cognitive diversity as “the inclusion of people who have
                    different ways of thinking, different viewpoints and
                    different skill sets in a team or business group.” In other
                    words, cognitive diversity refers to the range of ways
                    people think, especially in the context of a team or group.
                </p>
                <p>
                    Geil Browning, the CEO and Founder of Emergenetics
                    International (an organization that specializes in
                    cultivating cognitive diversity within the workspace),
                    <a
                        href='https://www.inc.com/geil-browning/your-brain-is-wired-for-leadership.html'
                        rel='noreferrer'
                        target='_blank'
                    >
                        breaks down
                    </a>{" "}
                    cognitive diversity in terms of seven brain attributes—four
                    ways of thinking and three ways of behaving:
                </p>
                <p>
                    <Italics>Thinking:</Italics>
                </p>
                <p>
                    <ol>
                        <li>
                            <Bolded>Analytical:</Bolded> Analytical thinking is
                            all about utilizing existing research and data to
                            make objective, concrete, and less-biased decisions.
                            It’s the frame of mind that enables you to examine
                            different options, allowing you to determine which
                            ones will actually work.
                        </li>
                        <li>
                            <Bolded>Structural:</Bolded> This way of thinking is
                            all about planning. The structural attribute helps
                            you create a plan or process that is methodical,
                            cost-effective, and—most importantly—doable.
                        </li>
                        <li>
                            <Bolded>Social:</Bolded> Social thinking enables you
                            to relate with others. It entails your ability to
                            listen, communicate, collaborate, and even inspire
                            your fellow team-members.
                        </li>
                        <li>
                            <Bolded>Conceptual:</Bolded> This last way of
                            thinking is all about big ideas. Conceptual thinking
                            is the attribute that gives you the visionary ideas
                            that lead to innovation and progress.
                        </li>
                    </ol>
                </p>
                <p>
                    <Italics>Behaving:</Italics>
                </p>
                <p>
                    <ol>
                        <li>
                            <Bolded>Expressiveness:</Bolded> If you have an
                            expressive behavior style, then you have no trouble
                            clearly communicating your thoughts and feelings
                            about any particular situation.
                        </li>
                        <li>
                            <Bolded>Assertiveness:</Bolded> Someone with an
                            assertive behavior style is able to voice their
                            opinions, put pen to paper, and turn any idea into a
                            reality.
                        </li>
                        <li>
                            <Bolded>Flexibility:</Bolded> If your behavior style
                            is characterized with flexibility, then you’re open
                            to multiple points of view—you can also adapt to
                            thrive in a variety of scenarios.
                        </li>
                    </ol>
                    These seven attributes are just one framework used to
                    discuss cognitive diversity. But regardless of lens or
                    method used, experts agree that cognitive diversity is
                    essential to a team’s success.
                </p>
                <h2>the benefits of cognitive diversity in the office</h2>
                <BlogImg
                    src={Collaborative}
                    alt='people working collaboratively'
                />
                <p>
                    In a{" "}
                    <a
                        href='https://hbr.org/2017/03/teams-solve-problems-faster-when-theyre-more-cognitively-diverse'
                        rel='noreferrer'
                        target='_blank'
                    >
                        study
                    </a>{" "}
                    conducted by the aforementioned researchers from the Harvard
                    Business Review, a variety of teams completed a strategic
                    execution exercise that “focused on managing new, uncertain,
                    and complex situations.” Within the exercise, each group was
                    “to formulate and execute a strategy to achieve a specified
                    outcome” within a designated time frame.
                </p>
                <p>
                    What the researchers found was that there was a “significant
                    correlation between high cognitive diversity and high
                    performance.” The teams that had more diverse perspectives
                    and ways of processing knowledge were able to complete the
                    exercise much faster than the teams that were more
                    cognitively homogenous.{" "}
                </p>
                <p>
                    The researchers go on to explain that “a high degree of
                    cognitive diversity could generate accelerated learning and
                    performance in the face of new, uncertain, and complex
                    situations,” meaning that teams with more cognitive
                    diversity are more likely to adapt to and overcome a variety
                    of scenarios. As{" "}
                    <a
                        href='https://www.psychologytoday.com/us/blog/you-according-them/201706/cognitive-diversity'
                        rel='noreferrer'
                        target='_blank'
                    >
                        put
                    </a>{" "}
                    by leadership development expert Sara Cannaday, “companies
                    produce the best results and are better able to innovate
                    when their team members don't all think, process information
                    or see the world in the same way . . . A culture that
                    encourages (explicitly or implicitly) conformity of thought
                    breeds stagnation and imperils a company.”{" "}
                </p>
                <p>
                    Interestingly enough, in the HBR study, other measures of
                    diversity—like gender, race, and age—had no correlation to
                    the team’s results. This may be because these more apparent
                    aspects of diversity are more important when it comes to
                    relating to and taking into consideration the wants and
                    needs of a variety of people—but not necessarily significant
                    when it comes to accomplishing specific tasks as a team.
                </p>
                <h2>cultivating cognitive diversity</h2>
                <BlogImg src={Diversity} alt='diverse people' />
                <p>
                    The benefits of building a team ripe with cognitive
                    diversity are clear; but how can a team better integrate
                    cognitive diversity into their work space? Here are some
                    <a
                        href='https://www.workforce.com/news/think-differently-3-ways-build-cognitive-diversity-workplace'
                        rel='noreferrer'
                        target='_blank'
                    >
                        useful tips
                    </a>{" "}
                    to keep in mind:
                </p>
                <p>
                    <Bolded>Recruit with cognitive diversity in mind:</Bolded>{" "}
                    When it comes to the hiring process, recruiting for
                    cognitive diversity goes beyond the traditional norms of
                    looking at an applicant’s degree or work experience. By
                    including carefully tailored questions meant to help you
                    discern an applicant’s way of thinking in the interview
                    process, you can have a better idea of where this
                    applicant’s frame of mind fits in relation to your current
                    team. Alternatively, you can utilize the tests provided by
                    companies like{" "}
                    <a
                        href='https://www.emergenetics.com/'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Emergenetics
                    </a>{" "}
                    to get an even better idea of how an applicant uses their
                    brain to approach a problem.
                </p>
                <p>
                    <Bolded>Encourage constructive differences:</Bolded> Perhaps
                    the biggest fault of an office that lacks cognitive
                    diversity is the fact that there will be no one who
                    challenges a charted course of action that was unanimously
                    agreed upon by a team. Such opposition oftentimes will raise
                    some important points that weren’t taken into consideration
                    by the like-minded team-members, resulting in some
                    significant changes in the proposed plan that are ultimately
                    beneficial. Creating a space that encourages team-members to
                    bring up criticisms or different angles to a project in a
                    constructive way prevents your team from becoming too
                    narrow-sighted, opening up the space for innovation.
                </p>
                <h2>in conclusion</h2>
                <BlogImg src={Cheer} alt='people cheering' />
                <p>
                    In the end, diversity of any kind is important in the office
                    space. But beyond the things that traditionally come to mind
                    when thinking about diversity, is the act of thinking
                    itself! Strengthening the cognitive diversity within your
                    office can play a vital role in ensuring the success of your
                    next big team project. But working in a team requires the
                    perfect space to do so; and here at Facility Solutions Plus,
                    we can help your employees put their brains together and
                    tackle the trickiest of tasks by designing the perfect
                    collaborative environment, from traditional conference rooms
                    to open, modular floor plans. Because teamwork of any kind
                    requires a place that primes your team for success—we’ll
                    help you create that very space.
                </p>
                <p>
                    <Italics>
                        Facility Solutions Plus is a client-centered,
                        full-service commercial office furniture dealership and
                        service provider that specializes in meeting needs,
                        solving problems and enhancing the total office
                        environment through customized, cost-effective workspace
                        solutions...and more.
                    </Italics>
                </p>
                <PostsNavigator>
                    <NewPost>
                        <h4>Newer Post</h4>
                        <Link to='/resources/02152020'>
                            Strengthening Teamwork in the Office
                        </Link>
                    </NewPost>
                    <OldPost>
                        <h4>Older Post</h4>
                        <Link to='/resources/01272020'>
                            Cultivating Productivity in the Office.
                        </Link>
                    </OldPost>
                </PostsNavigator>
            </MainContainer>
            <ContactUsHome />
        </>
    );
}
