import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import houston1 from "./Horne Houston 1.jpg";
import houston2 from "./Horne Houston 2.jpg";
import houston3 from "./Horne Houston 4.jpg";
import houston4 from "./Horne Houston 5.jpg";
import houston5 from "./Horne Houston 6.jpg";
import houston6 from "./Meeting Krug 1.jpg";
import houston7 from "./Meeting Krug 2.jpg";
import houston8 from "./Office 1 OFD.jpg";
import houston9 from "./Threeh 1.jpg";
import houston10 from "./Threeh 2.jpg";

const imgs = [
  houston1,
  houston2,
  houston3,
  houston4,
  houston5,
  houston6,
  houston7,
  houston8,
  houston9,
  houston10,
];

const Container = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 4rem 0;
  h2 {
    margin-bottom: 4rem;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-flow: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
`;

const FolioImg = styled.img`
  margin: 1rem 0;
  width: 20rem;
  height: auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 1rem 1rem;
    width: 25rem;
  }
`;
export default function HoustonTX() {
  let markup = imgs.map((img) => <FolioImg src={img} alt="test" />);
  return (
    <Container animateIn="fadeIn" animateOnce="true">
      <h2>Houston, TX</h2>
      <Content>{markup}</Content>
      <span id="laporte"></span>
    </Container>
  );
}
