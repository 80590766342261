import React from "react";

import styled from "styled-components";

import global from "../../assets/img/pages/home/vendorLogo/global.svg";
import agility from "../../assets/img/pages/home/vendorLogo/agility.svg";
import amcase from "../../assets/img/pages/home/vendorLogo/amcase.svg";
import darran from "../../assets/img/pages/home/vendorLogo/darran.jpeg";
import ki from "../../assets/img/pages/home/vendorLogo/ki.svg";
import mps from "../../assets/img/pages/home/vendorLogo/mps.svg";
import pallas from "../../assets/img/pages/home/vendorLogo/pallas.png";
import tenjam from "../../assets/img/pages/home/vendorLogo/tenjam.png";
import via from "../../assets/img/pages/home/vendorLogo/via.png";
import logo1 from "../../assets/img/pages/home/vendorLogo/1.svg";
// import logo2 from "../../assets/img/pages/home/vendorLogo/2.svg";
import logo3 from "../../assets/img/pages/home/vendorLogo/3.svg";
import logo4 from "../../assets/img/pages/home/vendorLogo/4.svg";
import logo5 from "../../assets/img/pages/home/vendorLogo/5.svg";
import logo6 from "../../assets/img/pages/home/vendorLogo/6.svg";
import logo7 from "../../assets/img/pages/home/vendorLogo/7.svg";
import logo8 from "../../assets/img/pages/home/vendorLogo/8.svg";
import logo9 from "../../assets/img/pages/home/vendorLogo/9.svg";
import logo10 from "../../assets/img/pages/home/vendorLogo/10.svg";
import logo11 from "../../assets/img/pages/home/vendorLogo/11.svg";
import logo12 from "../../assets/img/pages/home/vendorLogo/12.svg";
import logo13 from "../../assets/img/pages/home/vendorLogo/13.svg";
import logo14 from "../../assets/img/pages/home/vendorLogo/14.svg";
import logo15 from "../../assets/img/pages/home/vendorLogo/15.svg";
import logo16 from "../../assets/img/pages/home/vendorLogo/16.svg";
import logo17 from "../../assets/img/pages/home/vendorLogo/17.svg";
import logo18 from "../../assets/img/pages/home/vendorLogo/18.svg";
import logo19 from "../../assets/img/pages/home/vendorLogo/19.svg";
import logo20 from "../../assets/img/pages/home/vendorLogo/20.svg";
import logo21 from "../../assets/img/pages/home/vendorLogo/21.svg";
import logo22 from "../../assets/img/pages/home/vendorLogo/22.svg";

const logos = [
    global,
    agility,
    amcase,
    darran,
    ki,
    mps,
    pallas,
    tenjam,
    via,
    logo1,
    //   logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
    logo12,
    logo13,
    logo14,
    logo15,
    logo16,
    logo17,
    logo18,
    logo19,
    logo20,
    logo21,
    logo22,
];

const LogoSwiper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    margin-bottom: 10rem;
`;

const LogoContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;

const LogoImg = styled.img`
    width: 8rem;
    height: auto;
    margin: 1rem 0.5rem;
    filter: grayscale(100%);
    -moz-transition: all 0.1s ease-in;
    -o-transition: all 0.1s ease-in;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.2s ease-in;
    &:hover {
        filter: grayscale(0);
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        margin: 0 3rem 3rem 3rem;
    }
`;

export default function VendorLogoCarousel() {
    let markup = logos.map((logo) => <LogoImg src={logo} alt='vendor logos' />);
    return (
        <LogoSwiper>
            <LogoContent>{markup}</LogoContent>
        </LogoSwiper>
    );
}
